<template>
  <div>
    <div ref="editor"></div>
  </div>
</template>

<script setup>
import { defineProps, onMounted, ref, watch, getCurrentInstance } from 'vue';
import Quill from 'quill';
import MarkdownIt from 'markdown-it';

import 'quill/dist/quill.snow.css'; // Import Quill styles (delete it?)

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  editing: {
    type: Boolean
  }
})

const { emit } = getCurrentInstance();


const editor = ref(null);
const editable = ref(false);

editable.value = props.editing;
const shouldSave = ref(false);
let quill = null;

onMounted(() => {
  if (editor.value) {
    quill = new Quill(editor.value, {
      theme: 'bubble',
      modules: {
        toolbar: [],
      },
      readOnly: true
    });

    const markdown = new MarkdownIt({
      html: true
    });

    quill.on('text-change', (editor) => {
      // shouldSave.value = editor.ops.length > 0;
      if (editor.ops.length > 0 && editable.value) {
        const currentContent = quill.root.innerHTML
        emit('summary-changed', currentContent);
      }
    });

    const html = markdown.render(props.modelValue);
    const convertedHtml = quill.clipboard.convert(html);
    quill.setContents(convertedHtml);
  }
});

watch(() => props.modelValue, (newContent) => {
  if (quill && newContent !== quill.root.innerHTML) {
    quill.root.innerHTML = newContent;
  }

});

watch(() => props.editing, () => {
  editable.value = props.editing;
  quill.enable(editable.value);
  if (editable.value) {
    quill.focus()
  }

});

</script>

<style scoped>
.ql-editor {
  padding: 0 !important
}
</style>
