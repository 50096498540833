<template>
    <v-container>
        <v-progress-linear v-if="isPendingSave" indeterminate color="primary"></v-progress-linear>
        <div class="search-replace-wrapper">
            <v-card v-if="openSearch" class="search-replace-card" elevation="0">
                <v-text-field class=" ml-6" v-model="searchModel" label="Search" color="accent" variant="underlined"
                    density="compact" clearable flat></v-text-field>
                <v-text-field class="ml-6" v-model="replaceModel" label="Replace with" color="accent"
                    variant="underlined" density="compact" clearable flat></v-text-field>
                <template v-slot:actions>
                    <v-btn class="ml-2 mr-2" @click="searchAndReplace">Replace</v-btn>
                    <v-btn icon @click="closeSearch">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-card>
        </div>
        <div v-if="!disabledCopy" ref="quillEditor" class="quill-editor"></div>
        <div @click="quillClick" v-else class="non-selectable" v-html="session.summary"></div>
    </v-container>

    <v-snackbar v-model="snackbarCopy" :timeout="timeout" color="gray">
        {{ textCopy }}
        <template v-slot:actions>
            <v-btn icon @click="snackbarCopy = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
    <v-snackbar v-model="isPendingSave" :timeout="2000" color="gray">
        Autosaving changes
        <template v-slot:actions>
            <v-btn icon @click="isPendingSave = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import Quill from 'quill';
import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import getDocument from '@/composables/getDocument';
import useDocument from '@/composables/useDocument';
import { debounce } from 'lodash';
import { trackEvent, NOTES_SEARCH_AND_REPLACE } from '@/utilities/analyticsService';
import { getUserActionState, UserActions } from '../composables/getUserActionState';
import { props } from 'vue3-timepicker';

export default {
    props: ['patientId', 'id', 'copyTrigger', 'searching', 'userId', 'userEmail', 'sessionId', 'disabledCopy'],
    data: () => ({
        timeout: 2000,
        timeoutSave: 6000,
        searchModel: '',
        replaceModel: ''
    }),
    methods: {
        searchAndReplace() {
            trackEvent(NOTES_SEARCH_AND_REPLACE, { userId: this.userId, userEmail: this.userEmail, search: this.searchModel, replace: this.replaceModel, sessionId: this.sessionId });
            const regex = new RegExp('\\b' + this.searchModel + '\\b', 'g');
            this.session.summary = this.quill.root.innerHTML.replace(regex, this.replaceModel);
            this.quill.setContents(this.quill.clipboard.convert(this.session.summary));
            this.saveChanges();
        },
        closeSearch() {
            this.$emit('close-search');
            this.openSearch = false;
        },
        quillClick() {
            if (this.$props.disabledCopy) {
                this.$emit('show-price-banner');
            }
        }
    },
    setup(props, { emit }) {
        const quillEditor = ref(null);
        const { error, document: session } = getDocument('sessions', props.id);
        const { updateDocument } = useDocument('sessions', props.id);
        const quill = ref(null);
        const snackbar = ref(false);
        const snackbarCopy = ref(false);
        const cursorPosition = ref(0);
        const text = ref('');
        const textCopy = ref('');
        const counter = ref(0);
        const isPendingSave = ref(false);
        const openSearch = ref(false);
        const shouldSave = ref(false);
        openSearch.value = props.searching;

        function copyToClipboard() {
            // Use Quill's API to get the plain text
            const editorContent = quill.value.getText();
            // Create a temporary textarea element to copy the text
            const tempTextArea = document.createElement('textarea');
            tempTextArea.value = editorContent; // Assign plain text content to the textarea
            document.body.appendChild(tempTextArea); // Append the textarea to the body
            tempTextArea.select(); // Select the content of the textarea
            document.execCommand('copy'); // Execute the copy command
            document.body.removeChild(tempTextArea); // Remove the temporary textarea

            // Optionally, show a message that content has been copied
            snackbarCopy.value = true;
            textCopy.value = 'Content copied to clipboard';
        }

        function saveChanges() {
            if (shouldSave.value) {
                snackbar.value = true;
                isPendingSave.value = true;
                const currentContent = quill.value.root.innerHTML;
                updateDocument({ summary: currentContent });
                setTimeout(() => {
                    isPendingSave.value = false;
                    shouldSave.value = false;
                }, 1000);
            }
        }

        watch(() => props.searching, (newVal) => {
            openSearch.value = newVal;
        });

        watch(() => props.copyTrigger, (newVal) => {
            copyToClipboard();
        });

        // Watch for session data changes and update Quill editor
        watch(session, (newSession) => {
            quill.value.root.innerHTML = 'Generating new clinical note...';

            if (quill.value && newSession && newSession.summary) {
                quill.value.enable(getUserActionState(UserActions.SummaryEdit, newSession.state));
                quill.value.root.innerHTML = newSession.summary;
            }
        });

        onMounted(() => {
            quill.value = new Quill(quillEditor.value, {
                theme: 'bubble',
            });

            quill.value.root.addEventListener('blur', debounce(saveChanges, 500)); // delay due to slow tick from Quills
            quill.value.on('text-change', (editor) => shouldSave.value = editor.ops.length > 0);
            // Load session data if available
            if (session.value && session.value.summary) {
                quill.value.setContents(quill.value.clipboard.convert(session.value.summary));
                if (cursorPosition.value !== 0) {
                    quill.value.setSelection(cursorPosition.value);
                }
            }

            // Add copy event listener to Quill editor
            quill.value.root.addEventListener('copy', handleCopy);

            // quill.value.container.classList.add('non-selectable');
            quill.value.enable(false)


        });

        onBeforeUnmount(() => {
            // Remove copy event listener from Quill editor
            if (quill.value) {
                quill.value.root.removeEventListener('copy', handleCopy);
            }
        });

        function handleCopy(event) {
            // Get the selection
            const selection = window.getSelection();
            // Check if the selection is within the Quill editor
            if (selection && quillEditor.value.contains(selection.anchorNode)) {
                // Get the selected range
                const range = quill.value.getSelection();
                if (range) {
                    // Get plain text within the selected range
                    let selectedText = quill.value.getText(range.index, range.length);
                    // Replace multiple line breaks with a single line break, but preserve single line breaks
                    selectedText = selectedText.replace(/\n{3,}/g, '\n\n').replace(/\n{2}/g, '\n\n');
                    console.log('selectedText: ', selectedText);
                    // Set clipboard data to plain text
                    event.clipboardData.setData('text/plain', selectedText);
                    event.preventDefault();
                }
            }
        }

        return { openSearch, shouldSave, saveChanges, isPendingSave, quillEditor, session, quill, snackbar, snackbarCopy, copyToClipboard, text, textCopy, counter };
    }
};
</script>


<style scoped>
.quill-editor {
    border: none !important;
}

.search-replace-wrapper {
    display: flex;
    justify-content: end;

}

.search-replace-card {
    display: flex;
    flex-direction: row;
    width: 60%;
    align-items: self-end;
    font-size: 0.9rem;
}


@media (max-width: 1200px) {
    .search-replace-card {
        width: 100%;
    }
}

.non-selectable {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: not-allowed;
    text-align: left;
}

::v-deep .non-selectable p {
    margin-bottom: 0px important;
}
</style>