<template>
  <div :class="!showOnLeft ? 'feedback-button-container' : 'feedback-button-container-left'">
    <v-btn :class="{ 'feedback-btn-zoom': isZoomApp, 'feedback-btn': !isZoomApp }" class="text-white"
      color="light-blue-lighten-3" elevation="8" @click="showDrawer = true" v-if="showFeedbackBtn">
      <p :class="{ 'feedback-button-text-zoom': isZoomApp, 'feedback-button-text': !isZoomApp }">Contact Us</p>
    </v-btn>
  </div>
  <div class="pa-5" v-if="!showFeedbackBtn">

    <div class="d-flex justify-space-between align-center pl-4 pt-4 mt-4 pr-4">
      <div>
        <div class="text-h6 mb-1 text-left">
          Get in touch with us
        </div>
        <div class="text-left">We will answer to your email within a few hours, but no later than 24
          hours.</div>
      </div>

    </div>

    <v-card-text>
      <div class="mt-1">
        <v-checkbox v-model="idea" label="New feature request / Idea" value="feature_request"
          density="compact"></v-checkbox>
        <v-checkbox v-model="bug" label="Bug/Issue" value="bug_issue" density="compact"></v-checkbox>
        <v-checkbox v-model="compliment" label="Compliment" value="compliment" density="compact"></v-checkbox>
      </div>

      <v-textarea class="mt-3" label="Feedback" variant="outlined" flat solo placeholder="Feedback" rows="8"
        row-height="15" no-resize v-model="feedback" />
    </v-card-text>
    <v-card-actions class="d-flex align-end justify-end">
      <v-btn @click="addFeedback">Send</v-btn>
    </v-card-actions>
  </div>
  <v-navigation-drawer v-model="showDrawer" location="right" :width="800" temporary>
    <div class="pa-5">

      <div class="d-flex justify-space-between align-center pl-4 pt-4 mt-4 pr-4">
        <div>
          <div class="text-h6 mb-1 text-left">
            Get in touch with us
          </div>
          <div class="text-left">We will answer to your email within a few hours, but no later than 24
            hours.</div>
        </div>

        <v-btn icon @click="showDrawer = false" variant="plain">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <div class="mt-1">
          <v-checkbox v-model="idea" density="compact" label="New feature request / Idea"
            value="feature_request"></v-checkbox>
          <v-checkbox v-model="bug" label="Bug/Issue" value="bug_issue" density="compact"></v-checkbox>
          <v-checkbox v-model="compliment" label="Compliment" value="compliment" density="compact"></v-checkbox>
        </div>

        <v-textarea class="mt-3" label="Feedback" variant="outlined" flat solo placeholder="Feedback" rows="8"
          row-height="15" no-resize v-model="feedback" />
      </v-card-text>
      <v-card-actions class="d-flex align-end justify-end">
        <v-btn @click="addFeedback">Send</v-btn>
      </v-card-actions>
    </div>
  </v-navigation-drawer>
  <v-snackbar v-model="snackbar" color="gray" elevation="24">
    Feedback submitted

    <template v-slot:actions>
      <v-btn color="pink" variant="text" @click="snackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>
import { onMounted, ref, defineProps } from 'vue'
import { trackEvent, NEW_FEEDBACK_SUBMITTED } from '@/utilities/analyticsService';
import getUser from '@/composables/getUser'
import { getIsZoomApp } from '@/composables/configureZoom'
import { useRouter } from 'vue-router'

const props = defineProps({
  showOnLeft: Boolean
})

const showDrawer = ref(false)
const feedback = ref("")
const idea = ref("")
const bug = ref("")
const compliment = ref("")
const { user } = getUser();
const { isZoomApp } = getIsZoomApp();
const router = useRouter()
const showFeedbackBtn = ref(true);
const snackbar = ref(false);

const addFeedback = async () => {
  let feedbackType = [];
  if (idea.value) feedbackType.push(':bulb: Idea');
  if (bug.value) feedbackType.push(':alert: Bug');
  if (compliment.value) feedbackType.push(':heart: Love');
  let feedbackTypeString = feedbackType.length > 0 ? feedbackType.join('/') : ':point_down: General feedback';
  console.log("Feedback submitted", feedback.value, " feedback type: ", feedbackTypeString);
  trackEvent(NEW_FEEDBACK_SUBMITTED, { userId: user.value.uid, userEmail: user.value.email, feedback: feedback.value, feedbackType: feedbackTypeString })
  showDrawer.value = false
  snackbar.value = true;
  idea.value = "";
  bug.value = "";
  compliment.value = "";
  feedback.value = "";
}

onMounted(() => {
  if (router.currentRoute.value.fullPath.includes('fullPage')) {
    showFeedbackBtn.value = false
  }
})

</script>

<style scoped>
.feedback-button-container {
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: flex;
  margin-right: 50px;
}

.feedback-button-container-left {
  position: fixed;
  left: 20px;
  bottom: 20px;
  display: flex;
  margin-left: 50px;
}

.feedback-btn {
  background-color: var(--electric-blue) !important;
  border-radius: 30px !important;
  color: var(--rich-black) !important;
  flex-grow: 0;
  height: 50px !important;
  width: 200px;
}

.feedback-btn-zoom {
  background-color: var(--electric-blue) !important;
  border-radius: 30px !important;
  color: var(--rich-black) !important;
  flex-grow: 0;
  height: 25px !important;
  width: 100px;
}

.feedback-btn:hover {
  background-color: var(--electric-blue-hover) !important;
  border-radius: 30px !important;
  height: 50px !important;
  width: 200px;
}

.feedback-button-text {
  color: var(--rich-black);
  margin-bottom: 0px !important;
  font-weight: 600;
}

.feedback-button-text-zoom {
  color: var(--rich-black);
  margin-bottom: 0px !important;
  font-weight: 600;
  font-size: 0.7rem;
}
</style>
