import { ref } from 'vue'
import { getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";
import { projectAuth } from '../firebase/config'
import posthog from 'posthog-js'
import { result } from 'lodash';

const errorMs = ref(null)
const isPendingMs = ref(false)
const provider = new OAuthProvider('microsoft.com');
const auth = getAuth();


const signupMicrosoft = async () => {
  errorMs.value = null
  isPendingMs.value = true
  try {
    
    result = await signInWithPopup(auth, provider)
    // const credential = OAuthProvider.credentialFromResult(res);
    // const accessToken = credential.accessToken;
    // const idToken = credential.idToken;

    // if (!res) {
    //   throw new Error('Could not complete signup')
    // }
    
    errorMs.value = null
    isPendingMs.value = false
    
    return result
  }
  catch(err) {
    posthog.capture("Error in MS signup", { message: err.message });
    console.log('Problem in MS Signup message: ', err.message, "\n Object: ", err)
    errorMs.value = err.message
    isPendingMs.value = false
  }
}

const useSignupMicrosoft = () => {
  return { errorGoogle: errorMs, signupMicrosoft: signupMicrosoft, isPendingGoogle: isPendingMs }
}

export default useSignupMicrosoft