export const UserActions = {
    ReTriggerAudio: "re_trigger_audio",
    DeleteAudio: "delete_audio",
    SummaryEdit: "summary_edit",
    NoteTemplateSettings: "note_template_settings",
    SessionDeletion: "session_deletion"
}

export const SessionState = {
    Created: "created",
    InProgressTranscript: "in_progress_transcript",
    InProgressSummary: "in_progress_summary",
    Completed: "completed",
    FailureTranscript: "failure_transcript",
    FailureSummary: "failure_summary",
    AbortAudioTooShort: "audio_too_short",
    AbortNonMedicalConversation: "non_medical_conversation"
}

export function getUserActionState(action, state) {
    switch (action) {
        case UserActions.ReTriggerAudio:
            switch (state) {
                case SessionState.Completed:
                case SessionState.FailureTranscript:
                case SessionState.FailureSummary:
                case SessionState.AbortNonMedicalConversation:
                    return true;
                case SessionState.Created:
                case SessionState.InProgressTranscript:
                case SessionState.InProgressSummary:
                case SessionState.AbortAudioTooShort:
                    return false;
                default:
                    return true;
            }
        case UserActions.DeleteAudio:
            switch (state) {
                case SessionState.InProgressSummary:
                case SessionState.Completed:
                case SessionState.FailureTranscript:
                case SessionState.FailureSummary:
                case SessionState.AbortAudioTooShort:
                case SessionState.AbortNonMedicalConversation:
                    return true;
                case SessionState.Created:
                case SessionState.InProgressTranscript:
                    return false;
                default:
                    return true;
            }
        case UserActions.SummaryEdit:
            switch (state) {
                case SessionState.Completed:
                    return true;
                case SessionState.Created:
                case SessionState.InProgressTranscript:
                case SessionState.InProgressSummary:
                case SessionState.FailureTranscript:
                case SessionState.FailureSummary:
                case SessionState.AbortAudioTooShort:
                case SessionState.AbortNonMedicalConversation:
                    return false;
                default:
                    return true;
            }
        case UserActions.NoteTemplateSettings:
            switch (state) {
                case SessionState.Completed:
                case SessionState.FailureSummary:
                case SessionState.AbortNonMedicalConversation:
                    return true;
                case SessionState.Created:
                case SessionState.InProgressTranscript:
                case SessionState.InProgressSummary:
                case SessionState.FailureTranscript:
                case SessionState.AbortAudioTooShort:
                    return false;
                default:
                    return true;
            }
        case UserActions.SessionDeletion:
            switch (state) {
                case SessionState.Completed:
                case SessionState.FailureTranscript:
                case SessionState.FailureSummary:
                case SessionState.AbortAudioTooShort:
                case SessionState.AbortNonMedicalConversation:
                case SessionState.Created:
                    return true;
                case SessionState.InProgressTranscript:
                case SessionState.InProgressSummary:
                    return false;
                default:
                    return true;
            }
        default:
            return true;
    }
}