<template>
    <div>
        <div v-if="isLoading" style="padding-top: 24px;">
            <h6 class="mt-auto" style="color: gray; padding-bottom: 12px;">Loading.....</h6>
            <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
        </div>
        <div class="d-flex" v-else>
            <SubscriptionProductCard v-for="product in products" @isLoading="handleLoadingState" :product="product"
                :userProduct="userProducts.find((p) => p.productId === product.id)" :isLoading="isLoadingChild" />
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import SubscriptionProductCard from "@/components/available-subscription-products/SubscriptionProductCard.vue";
import { getAvailableProducts } from '../../composables/useProducts';

const products = ref([]);
const userProducts = ref([]);
const isLoadingChild = ref(false);
const isLoading = ref(false);

onMounted(async () => {
    isLoading.value = true;
    const availableProducts = await getAvailableProducts();
    products.value = availableProducts?.activeProducts;
    userProducts.value = availableProducts?.userProducts;
    isLoading.value = false;
})

const handleLoadingState = (newValue) => {
    isLoadingChild.value = newValue;
}

</script>