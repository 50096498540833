<template>
    <div v-if="isLoading">Loading account information...</div>
    <SubscribedAccount class="rounded-border" v-else :subscription="subscription" :userObject="userObject" />
</template>


<script>

import SubscribedAccount from "@/components/SubscribedAccount";
import { useSubscription } from '@/composables/useSubscription';
import { onMounted } from "vue";

export default {
    props: ["userObject"],
    components: {
        SubscribedAccount
    },
    setup(props) {
        const { isLoading, subscription, fetchSubscription } = useSubscription(props.userObject.uid);
        onMounted(async () => {
            await fetchSubscription()
        })
        return { isLoading, subscription }

    }
};
</script>

<style scoped>
.mdhub-card {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

}
</style>