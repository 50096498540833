<template>

    <div class="session-details-section">
        <div class="text-h6 ml-5 mt-4">Session details</div>
        <!-- Display session information here -->
        <div class="d-flex flex-row justify-space-between ml-3 mr-3">
            <v-text-field class="ml-4 mt-6 mr-4" label="Name" color="accent" variant="underlined" density="compact"
                type="text" v-if="patient" v-model="patient.patientName" readonly></v-text-field>
        </div>
        <div class="d-flex flex-row justify-space-between ml-3 mr-3">
            <v-text-field class="ml-4 mr-4" v-model="formattedDate" @input="saveDate" color="accent" label="Date"
                variant="underlined" density="compact" type="date"></v-text-field>

            <v-text-field class="mr-4" label="Duration" color="accent" variant="underlined" density="compact"
                type="text" v-model="sessionLengthMinutes" readonly></v-text-field>
        </div>

        <div class="d-flex flex-row justify-space-between ml-3 mr-3">
            <v-text-field class="ml-4 mr-2" color="accent" label="Session Start" variant="underlined" density="compact"
                type="time" v-model="startSession" @input="onTimeChangedStart"></v-text-field>

            <v-text-field class="mr-4" label="Session End" color="accent" variant="underlined" density="compact"
                v-model="endSession" type="time" @input="onTimeChangedEnd"></v-text-field>


        </div>

        <div class="autocomplete-wrapper ml-5 mr-3 mb-4 ">
            <vue-google-autocomplete v-if="!location" ref="address" id="map" class="custom-autocomplete"
                placeholder="Location" v-on:placechanged="getAddressData" types="(cities)">
            </vue-google-autocomplete>

            <v-text-field v-else color="accent" class="mr-8" clearable v-model="location" variant="underlined"
                density="compact"></v-text-field>

        </div>

        <p class="toggle-label text-subtitle-2 ml-4 mb-4">Progress</p>
        <v-btn-toggle class="toggle-box d-flex justify-center mb-2 mb-0" v-model="progress" rounded="l"
            color="grey-lighten-5" group>
            <v-btn @click="saveProgress('Worsening')" value="Worsening">
                Worsening
            </v-btn>

            <v-btn @click="saveProgress('Stable')" value="Stable">
                Stable
            </v-btn>

            <v-btn @click="saveProgress('Improving')" value="Improving">
                Improving
            </v-btn>
        </v-btn-toggle>

    </div>
    <br>
    <div class="tabs-container">
        <v-tabs v-model="activeTab" centered class="tabs-style" slider-color="var(--bittersweet)">
            <v-tab>Transcript</v-tab>
            <v-tab>Additional Notes</v-tab>
        </v-tabs>

        <v-window v-model="activeTab">
            <v-window-item>
                <!-- Transcript Content -->
                <v-textarea v-if="session" variant="solo" :model-value="session.transcript" :readonly="true" rows="12"
                    row-height="30" shaped bg-color="white" hide-details></v-textarea>
                <AudioComponent v-if="session && session.audioPath" :audioPath="session.audioPath"
                    :sessionId="session.id" />
            </v-window-item>
            <v-window-item>
                <div>
                    <v-progress-linear v-if="isPendingSave" indeterminate color="primary"></v-progress-linear>
                </div>
                <v-textarea v-if="session" variant="solo" v-model="additionalNotesValue" rows="12" row-height="30"
                    shaped bg-color="white" hide-details @blur="handleSaveOnBlur"
                    @update:model-value="additionalNotesUpdated = true"></v-textarea>
            </v-window-item>
        </v-window>
    </div>
    <v-snackbar v-model="isPendingSave" :timeout="1000" color="gray">
        Autosaving changes...
        <template v-slot:actions>
            <v-btn icon @click="isPendingSave = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>


<script>
import AudioComponent from '@/components/AudioComponent.vue'
import getUser from '@/composables/getUser'
import getDocument from '@/composables/getDocument'
import { ref, watch } from 'vue'
import useDocument from '@/composables/useDocument'
import { timestampClass } from '@/firebase/config'
import VueGoogleAutocomplete from "vue-google-autocomplete";
import moment from 'moment';
import { Timestamp } from "@firebase/firestore";

export default {
    props: ['patientId', 'id'],
    components: { AudioComponent, VueGoogleAutocomplete },
    computed: {
        sessionLengthMinutes() {
            if (this.session && this.session.startSession && this.session.endSession) {
                const start = moment(this.session.startSession.toDate());
                const end = moment(this.session.endSession.toDate());
                const duration = moment.duration(end.diff(start));
                // Calculate hours and minutes from duration
                const hours = Math.floor(duration.asHours());
                const minutes = duration.minutes();
                // Format the hours and minutes into a hh:mm string
                const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
                return formattedDuration;
            }
            return "00:00";
        },
        additionalNotesValue: {
            get() {
                return this.session.additionalNotes;
            },
            set(newValue) {
                this.additionalNotesUpdated = true
                this.session.additionalNotes = newValue;
            }
        },
    },
    methods: {
        handleSaveOnBlur() {
            this.handleSaveAdditionalNotes()

        },
        saveDate() {
            const { updateDocument } = useDocument('sessions', this.id)
            const sessionDate = new Date(this.formattedDate);
            const dateNow = Timestamp.now().toDate();

            sessionDate.setUTCHours(dateNow.getUTCHours());
            sessionDate.setUTCMinutes(dateNow.getUTCMinutes());
            sessionDate.setUTCSeconds(dateNow.getUTCSeconds());

            const actualSessionDate = Timestamp.fromDate(sessionDate);
            this.updatedDate = sessionDate;
            updateDocument({
                date: actualSessionDate
            })

            // need to update start/end session date time too otherwise it will be inconsistent 
            this.onTimeChangedStart();
            this.onTimeChangedEnd();
            this.isPendingSave = true;
        },
        saveProgress(progress) {
            const { updateDocument } = useDocument('sessions', this.id)
            updateDocument({
                progress: progress
            })

            this.isPendingSave = true;
        },

        getAddressData(addressData, placeResultData, id) {
            this.address = placeResultData.formatted_address;
            const { updateDocument } = useDocument('sessions', this.sessionId);
            updateDocument({
                location: this.address
            });
        },

        changeAddressData() {
            const { updateDocument } = useDocument('sessions', this.sessionId);
            updateDocument({
                location: this.location
            });
        },
        onTimeChangedStart() {
            // Assuming this.startSession is in "HH:MM" format
            const timeParts = this.startSession.split(':');
            const hours = parseInt(timeParts[0], 10);
            const minutes = parseInt(timeParts[1], 10);

            // Use the current date but replace hours and minutes with the ones from startSession

            // here we need to get the date from sessionDate

            const sessionDate = new Date(this.updatedDate.getFullYear(), this.updatedDate.getMonth(), this.updatedDate.getDate(), hours, minutes);


            // Convert to Firebase Timestamp
            const firebaseTimestamp = timestampClass.fromDate(sessionDate);

            // Update the document with the Firebase Timestamp
            const { updateDocument } = useDocument('sessions', this.id);
            updateDocument({
                startSession: firebaseTimestamp
            });
            this.isPendingSave = true;
        },

        onTimeChangedEnd() {
            const timeParts = this.endSession.split(':');
            const hours = parseInt(timeParts[0], 10);
            const minutes = parseInt(timeParts[1], 10);

            // Use the date from the session but replace hours and minutes with the ones from endSession
            const sessionDate = new Date(this.updatedDate.getFullYear(), this.updatedDate.getMonth(), this.updatedDate.getDate(), hours, minutes);

            // Convert to Firebase Timestamp
            const firebaseTimestamp = timestampClass.fromDate(sessionDate);

            // Update the document with the Firebase Timestamp

            const { updateDocument } = useDocument('sessions', this.id)
            updateDocument({
                endSession: firebaseTimestamp
            })
            this.isPendingSave = true;
        },
    },
    setup(props) {
        const activeTab = ref(0);
        const additionalNotes = ref(""); // Holds the text for personal notes

        const { user } = getUser();
        const { error, document: session } = getDocument('sessions', props.id);
        const { errorPatient, document: patient } = getDocument('patients', props.patientId);
        const { updateDocument } = useDocument('sessions', props.id)
        const startSession = ref('12:00')
        const endSession = ref('')
        const location = ref('');
        const formattedDate = ref('')
        const progress = ref('')
        const isPendingSave = ref(false)
        const additionalNotesUpdated = ref(false);
        const updatedDate = ref(new Date());
        const updateComputedValues = () => { };


        function getFormattedDate(date) {

            const localeDate = new Date(date.seconds * 1000)
                .toLocaleDateString("en-US", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",

                });

            // the v-model expects a YYYY-MM-DD format in order to display the date correctly in type date input
            const splitDate = localeDate.split('/');
            const supportedDate = splitDate[2] + '-' + splitDate[0] + '-' + splitDate[1];
            return supportedDate
        }

        const handleSaveAdditionalNotes = async () => {
            if (additionalNotesUpdated.value) {
                isPendingSave.value = true;
                additionalNotesUpdated.value = false;

                if (session) {

                    const additionalNotes = session.value.additionalNotes;
                    await updateDocument({
                        additionalNotes
                    })
                }

                setTimeout(() => {
                    isPendingSave.value = false;
                }, 1000);
            }
        }

        watch(session, (newSession) => {

            if (newSession.location) {
                location.value = newSession.location
            }
            if (newSession.startSession) {
                const startDateTime = newSession.startSession.toDate().toLocaleString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false
                });
                startSession.value = startDateTime;
            } else {
                startSession.value = '';
            }

            if (newSession.endSession) {
                const endDateTime = newSession.endSession.toDate().toLocaleString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false
                });
                endSession.value = endDateTime;
            } else {
                endSession.value = '';
            }

            if (newSession) {
                formattedDate.value = getFormattedDate(newSession.date);
                updatedDate.value = new Date(formattedDate.value);
            }

            if (newSession.progress) {
                progress.value = newSession.progress;
            } else {
                progress.value = 0; // Provide a default value if progress is not present
            }

            updateComputedValues(); // ToDo do I need this?
        });

        return { updatedDate, handleSaveAdditionalNotes, additionalNotesUpdated, isPendingSave, session, user, activeTab, additionalNotes, startSession, endSession, location, formattedDate, getFormattedDate, progress, patient }
    }
}


</script>

<style scoped>
.session-details-section {
    font-size: 1rem;
    text-align: left;
    position: relative;
    background-color: white;
    border-radius: 14px;
    font-family: inherit;
    font-size: inherit;
    line-height: 1.5;
    overflow: auto;
    overflow-y: scroll;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.textarea-container {
    font-size: 1rem;
    text-align: left;
    position: relative;
    background-color: white;
    border-radius: 14px;
    font-family: inherit;
    font-size: inherit;
    line-height: 1.5;
    overflow: auto;
    overflow-y: scroll;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.textarea-container .highlighted {
    background-color: #F1A499;
    color: white;
}

.custom-autocomplete {
    border: none;
    border-bottom: 1px solid #bcbdc0;
    padding: 6px 0 7px;
    box-sizing: border-box;
    background-color: transparent;
    font-size: 16px;
    width: 92%;
    margin-left: 8px !important
}

.v-btn-group {
    margin-bottom: 0px !important;
}

.tabs-container {
    border-radius: 10px;
    border: 1px solid rgba(224, 224, 224, 0.5);
    background-color: white;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}

.tabs-style ::v-deep .v-tab--selected span {
    color: var(--bittersweet);
    font-size: medium;
}
</style>
