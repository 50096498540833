<template>
    <div class="d-flex flex-column align-start pa-5">
        <h4>Select questionnaire type</h4>
        <p class="text-h9">
            Choose the type of questionnaire you want to create.
        </p>
        <h6 class="section-header mt-4">Selected Questionnaire type</h6>
        <v-expansion-panels class=" panel" v-model="questionnaireTypePanel">
            <v-expansion-panel elevation="0" :value="true">
                <v-expansion-panel-title class="text-h6">
                    {{ selectedQuestionnaireType ? ` ${selectedQuestionnaireType.title}` :
                        `Select
                    type of questionnaire` }}
                </v-expansion-panel-title>
                <v-expansion-panel-text class="panel-text-no-padding text-left">
                    <v-list>
                        <v-list-item v-for="(type, i) in questionnaireTypes" :key="i"
                            @click="selectedQuestionnaireType = type; questionnaireTypePanel = false;">
                            <v-list-item-title>
                                {{ type.title }}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
        <h4 class="mt-5">Include pre-made sections in your questionnaire</h4>
        <p class="text-h9 text-start">
            Select any of the sections below to be included in the new questionnaire.
            Then, you will be able to edit or add further to your questionnaire after you click 'Create'
        </p>
        <v-expansion-panels class="mt-4 panel" v-model="panel">
            <v-expansion-panel elevation="0" :value="true">
                <v-expansion-panel-title class="text-h6">Sections ({{ selectedSchemas?.length }} selected)
                </v-expansion-panel-title>
                <v-expansion-panel-text class="panel-text-no-padding">
                    <v-list select-strategy="clasic">
                        <span v-for="section in questionnaireSections" :key="section.id">
                            <v-list-item v-if="section.availableFor.includes(selectedQuestionnaireType.value)">
                                <div class="d-flex align-center">
                                    <v-checkbox v-model="selectedSchemas" :label="section.title"
                                        :disabled="loading || !section.availableFor.includes(selectedQuestionnaireType.value) || section?.requiredFor?.includes(selectedQuestionnaireType.value)"
                                        :value="section">
                                    </v-checkbox>
                                    <v-tooltip text="Preview section">
                                        <template v-slot:activator="{ props }">
                                            <v-icon v-bind="props"
                                                @click.stop="hoveredItem = section; hover = true">mdi-eye</v-icon>
                                        </template>
                                    </v-tooltip>
                                </div>
                            </v-list-item>
                        </span>
                        <p v-if="noSectionsAvailable" class="text-left ml-3 mb-0">No sections available for <span
                                class="font-weight-bold	">{{
                                    selectedQuestionnaireType.title }}</span></p>
                    </v-list>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-btn class="mdhub-btn mt-4 ml-auto" elevation="0" @click="create" :loading="loading">Create</v-btn>

        <v-dialog width="600" v-model="hover">
            <v-card elevation="1">
                <v-card-text>
                    <h2>Preview of section</h2>
                    <v-divider></v-divider>
                    <ItemPreview :overlay="false" v-for="(item, i) in hoveredItem.schema" :key="i" :item="item" />
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { getFunctions, httpsCallable } from "firebase/functions";
import { useRouter } from 'vue-router';
import ItemPreview from '@/components/questionnaire-builder/ItemPreview.vue';
import { trackEvent, QUESTIONNAIRE_CREATED, QUESTIONNAIRE_CREATED_FAILED } from '@/utilities/analyticsService';
import getUser from '@/composables/getUser'

const { user } = getUser()
const router = useRouter()
const functions = getFunctions();

const questionnaireTypes = [
    { title: 'Intake Questionnaire', value: 'questionnaire_intake' },
    { title: 'Follow-up Questionnaire', value: 'questionnaire_follow_up' },
    { title: 'Screening Questionnaire', value: 'questionnaire_screening' }
]

const loading = ref(false);
const questionnaireSections = ref(null);
const selectedSchemas = ref([]);
const panel = ref(true);
const questionnaireTypePanel = ref(true);
const hover = ref(false);
const hoveredItem = ref(null);
const selectedQuestionnaireType = ref(questionnaireTypes[0]);
const noSectionsAvailable = ref(false);


onMounted(() => {
    loading.value = true;
    const getQuestionnaireSections = httpsCallable(functions, 'getQuestionnaireSections');

    getQuestionnaireSections()
        .then(result => {
            console.log(result.data);
            questionnaireSections.value = result.data.sections

            filterSections()


        })
        .catch(error => {
            console.error(error);
        })
        .finally(() => {
            loading.value = false;
        })
});

watch(selectedQuestionnaireType, (type) => {
    filterSections()
}, { deep: true })

const create = async () => {
    loading.value = true;

    const createQuestionnaire = httpsCallable(functions, 'createQuestionnaire');

    createQuestionnaire({
        type: selectedQuestionnaireType.value.value,
        shared: false,
        title: 'My Questionnaire',
        description: 'This is a description of my questionnaire',
        schema: selectedSchemas.value.map(item => {
            // save schema items as non editable if they are required for the selected questionnaire type
            if (item.requiredFor === selectedQuestionnaireType.value.value) {
                return item.schema.map(schemaItem => {
                    return {
                        ...schemaItem,
                        cannotEdit: true
                    }
                })
            }
            return item.schema
        }).flat()
    })
        .then(result => {
            trackEvent(QUESTIONNAIRE_CREATED, {
                userId: user.value.uid,
                userEmail: user.value.email,
                id: result.data.id,
                beforeSchema: selectedSchemas.value,
                afterSchema: result.data.schema
            })
            router.push({ name: 'QuestionnaireBuilder', params: { id: result.data.id } });
        })
        .catch(error => {
            trackEvent(QUESTIONNAIRE_CREATED_FAILED, {
                userId: user.value.uid,
                userEmail: user.value.email,
                error: error
            })
            console.error(error);
        })
        .finally(() => {
            loading.value = false;
        })
}

const filterSections = () => {
    if (selectedQuestionnaireType.value.value === 'questionnaire_intake') {
        selectedSchemas.value = questionnaireSections.value.filter(
            section => section.requiredFor === 'questionnaire_intake'
        )
    } else if (selectedQuestionnaireType.value.value === 'questionnaire_follow_up' || selectedQuestionnaireType.value.value === 'questionnaire_screening') {
        selectedSchemas.value = questionnaireSections.value.filter(
            section => section.requiredFor === 'questionnaire_follow_up'
        )

    }

    noSectionsAvailable.value = questionnaireSections?.value?.filter(section => section.availableFor?.includes(selectedQuestionnaireType?.value?.value)).length === 0

    // console.log(noSectionsAvailable.value.)
}

</script>

<style scoped>
.panel {
    background-color: white;
    border-radius: 4px;
    padding: 4px;
    font-family: inherit;
    font-size: inherit;
    line-height: 1.5;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.v-messages {
    flex: 0 !important;
}
</style>