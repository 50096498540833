<template>
  <form @submit.prevent="handleSubmit">
    <h3>Log In</h3>
    <div v-show="!isZoom">
      <v-btn class="google-btn google-btn-text mt-8" @click="handleGoogleSignUp" :loading="isPendingGoogle"
        elevation="0" block>
        <v-icon left>
          <img src="@/assets/google_icon.svg" alt="Google Icon" />
        </v-icon>
        Sign in with Google
      </v-btn>
      <v-btn class="google-btn google-btn-text mt-8" @click="handleMicrosoftSignUp" :loading="isPendingGoogle"
        elevation="0" block>
        <v-icon left>
          <img src="@/assets/ms-logo.svg" alt="Microsoft Icon" />
        </v-icon>
        Sign in with Microsoft
      </v-btn>
      <div class="divider-container ml-4 mr-4">
        <v-divider class="custom-divider" />
        <span class="divider-text">or</span>
        <v-divider class="custom-divider" />
      </div>
    </div>
    <v-text-field type="email" placeholder="Email" v-model="email" class="ml-4 mr-4" color="accent" variant="underlined"
      density="compact" required />
    <v-text-field placeholder="Password" v-model="password" class="ml-4 mr-4" color="accent" variant="underlined"
      density="compact" required :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show1 = !show1"
      :type="show1 ? 'text' : 'password'" />
    <div v-if="error" class="error">{{ error }}</div>
    <br>
    <router-link :to="{ name: 'ForgotPassword' }" class="hover:underline">Forgot password?</router-link>
    <p class="mt-4">
      <span>By pressing Login, you agree with the previously accepted <a href="https://mdhub.ai/terms-of-service"
          target="_blank">Terms of Service</a> and acknowledge the <a href="https://mdhub.ai/privacy-policy"
          target="_blank">Privacy Policy.</a></span>
    </p>
    <v-btn class="mdhub-btn" elevation="0" :loading="isPending" type="submit"> Login</v-btn>
    <p class="mt-4">Don't have an account? <a href="#" @click.prevent="navigateToSignup">Sign up</a></p>
  </form>
</template>

<script>
import useLogin from '@/composables/useLogin'
import { startAvailableFeaturesListener } from '@/composables/getAvailableFeatures'
import { getWhitelistedFeaturesForUser } from '@/composables/isWhitelistedAccount';
import useCollection from '@/composables/useCollection'
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import useSignupGoogle from '@/composables/useSignupGoogle'
import useSignupMicrosoft from '@/composables/useSignupMicrosoft'
import { checkUserExists } from '@/composables/userService';
import posthog from 'posthog-js'
import getUser from '@/composables/getUser'
import { getIsZoomApp } from '@/composables/configureZoom'
import { timestamp } from '@/firebase/config'
import { trackEvent, LOGIN_ERROR } from '@/utilities/analyticsService';

export default {
  mounted() {
    this.setMetaTags();
  },
  data() {
    return {
      show1: false,
      show2: true,
      password: 'Password',
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
      siteKey: '0x4AAAAAAAdZZbmGeRAH8EVR',
    }
  },
  methods: {
    setMetaTags() {
      document.title = "mdhub Login - Access the AI Scribe for Behavioral Health Clinicians";
      let metaDescription = document.querySelector('meta[name="description"]');
      if (!metaDescription) {
        metaDescription = document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        document.getElementsByTagName('head')[0].appendChild(metaDescription);
      }
      metaDescription.setAttribute('content', 'Log in to mdhub, the #1 AI assistant for mental health care clinicians. Experience seamless, secure, and efficient AI assistant that you can fully focus on patient care');
    },
    navigateToSignup() {
      this.$router.push({ name: 'Signup' });
    },
  },

  setup() {
    const { err, addDoc } = useCollection('user')
    const { error, login, isPending } = useLogin()
    const { errorGoogle, signupGoogle, isPendingGoogle } = useSignupGoogle();
    const { errorMicrosoft, signupMicrosoft, isPendingMs } = useSignupMicrosoft();
    const router = useRouter()
    const email = ref('')
    const password = ref('')
    const { user } = getUser();
    const { isZoomApp } = getIsZoomApp();
    const isZoom = isZoomApp.value;

    const handleSubmit = async () => {
      try {
        const res = await login(email.value, password.value);
        if (!error.value) {
          createUserAndNavigate(res);
        }
      } catch (error) {
        trackEvent(LOGIN_ERROR, { userEmail: email.value, error: error })
      }
    }

    const handleGoogleSignUp = async () => {
      const res = await signupGoogle();
      if (!error.value) {
        createUserAndNavigate(res)
      }
    };

    const handleMicrosoftSignUp = async () => {
      const res = await signupMicrosoft();
      if (!errorMicrosoft) {
        createUserAndNavigate(res)
      }
    };

    function navigateToPatientList(name, email, userId) {
      posthog.identify(userId, { email: email, name: name });
      const redirectPath = router.currentRoute.value.query.redirect;
      document.title = 'Powering healthcare with AI.'
      if (redirectPath) {
        const route = router.resolve({ path: redirectPath });
        router.push({ name: route.name });
      } else {
        router.push({ name: 'UserPatients' });
      }
    }

    onMounted(() => {
      if (user.value !== null) {
        navigateToPatientList(user.value.displayName, user.value.email, user.value.uid);
      }
    });

    async function createUserAndNavigate(res) {
      const userExists = await checkUserExists(res.user.uid);
      if (!userExists) {
        await addDoc({
          email: res.user.email,
          name: res.user.displayName,
          userId: res.user.uid,
          speciality: "",
          promoCode: "",
          createdAt: timestamp()
        })
      }

      if (!error.value) {
        const asyncOperations = [startAvailableFeaturesListener(), getWhitelistedFeaturesForUser()];
        await Promise.all(asyncOperations);
        navigateToPatientList(res.user.displayName, res.user.email, res.user.uid)
      }
    }

    return {
      isZoom, email, password, handleSubmit, error, isPending, handleGoogleSignUp, isPendingGoogle, handleMicrosoftSignUp,
    }
  }
}
</script>

<style lang="scss" scoped></style>