<template>
  <div>
    <!-- Trigger Button to Show Dialog -->

    <!-- Dialog Component -->
    <v-dialog v-model="hasNewFeatures" persistent width="auto" content-class="custom-dialog">
      <v-card>
        <div class="pt-2 pr-2 pl-2">
          <div class="popup-upper-section">
            <v-card-title>Whats new!</v-card-title>
            <v-card-actions>
              <v-btn icon @click="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-actions>
          </div>



          <div>
            <v-list lines="one">
              <v-list-item v-for="item in newFeatures" :key="item.title" :title="item.title"
                :subtitle="item.description"></v-list-item>
            </v-list>

          </div>
          <v-card-actions class="mt-3">
            <v-spacer></v-spacer>
            <v-btn color="#FF7260" width="auto" @click="navigate" variant="outlined" dark class="mdhub-btn mb-2 mr-2"
              v-bind="props">

              Discover more
              <v-icon style="color: white;">mdi-arrow-top-right</v-icon>
            </v-btn>

          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { onMounted } from 'vue';
import { onBeforeMount } from 'vue';
import { ref, onRenderTracked } from 'vue'
import { onBeforeRouteUpdate } from 'vue-router';
import getUser from '@/composables/getUser'
import { trackEvent, NEW_FEATURES } from '@/utilities/analyticsService';

export default {

  setup() {
    const hasNewFeatures = ref(false);
    const newFeatures = ref([]);
    const { user } = getUser()
    const userId = user.value.uid;
    const userEmail = user.value.email
    // check version and feature updates
    const close = () => {
      localStorage.setItem('hasNewFeatures', false);
      hasNewFeatures.value = false;

    }
    const navigate = () => {
      localStorage.setItem('hasNewFeatures', false);
      hasNewFeatures.value = true;
      trackEvent(NEW_FEATURES, { userId, userEmail })

      window.open('https://mdhub.notion.site/Feature-Updates-c1e0b8679a3c4db6b6330eb36cc8eaeb', '_blank')
    }
    onBeforeMount(() => {
      hasNewFeatures.value = localStorage.getItem('hasNewFeatures') == 'true';
      newFeatures.value = JSON.parse(localStorage.getItem('newFeatures'));
    })

    return { hasNewFeatures, newFeatures, navigate, close }
  }
};
</script>

<style>
.custom-dialog {
  position: absolute;
  bottom: 0;
  left: 0;
}

.popup-upper-section {
  justify-content: space-between;
  display: flex;
  margin: 0
}
</style>
