<template>
    <v-card elevation="0" class="pa-0 mt-2 rounded-border" width="100%" height="100%">
        <v-card-title class="headline mt-5 ml-5 left-align">Sessions: #{{ numberOfSessions }}</v-card-title>
        <div class="counter-container">

            <div class="time-box">
                <span class="time">{{ timeSaved.days }}</span>
                <span class="unit">DAYS</span>
            </div>
            <div class="time-box">
                <span class="time">{{ timeSaved.hours }}</span>
                <span class="unit">HOURS</span>
            </div>
            <div class="time-box">
                <span class="time">{{ timeSaved.minutes }}</span>
                <span class="unit">MINUTES</span>
            </div>
        </div>
        <div class="time-saved">Time saved 🎉</div>
    </v-card>
</template>

<script>
import { ref, watch, computed } from 'vue';

export default {
    name: 'TimeCounter',
    props: {
        numberOfSessions: {
            type: Number,
            required: true
        }
    },
    setup(props) {
        const totalMinutesSaved = computed(() => props.numberOfSessions * 12);
        const timeSaved = ref({
            days: '00',
            hours: '00',
            minutes: '00'
        });

        const calculateTimeSaved = (totalMinutes) => {
            const minutes = totalMinutes % 60;
            const totalHours = (totalMinutes - minutes) / 60;
            const hours = totalHours % 24;
            const days = (totalHours - hours) / 24;

            timeSaved.value.days = String(days).padStart(2, '0');
            timeSaved.value.hours = String(hours).padStart(2, '0');
            timeSaved.value.minutes = String(minutes).padStart(2, '0');
        };

        // Recalculate time saved whenever the number of sessions changes
        watch(() => props.numberOfSessions, (newValue) => {
            calculateTimeSaved(totalMinutesSaved.value);
        }, { immediate: true });

        return { timeSaved };
    }
};
</script>

<style scoped>
.counter-container {
    display: flex;
    justify-content: center;
}

.time-box {
    background-color: #f1f1f1;
    margin: 0.5rem;
    padding: 1rem;
    border-radius: 10px;
    text-align: center;
}

.time {
    font-size: 2rem;
    color: #333;
}

.unit {
    font-size: 1rem;
    color: #777;
    text-transform: uppercase;
    display: block;
}

.session-info {
    font-size: 1.2rem;
    color: #333;
    padding: 0.5rem;
    text-align: center;
}

.time-saved {
    font-size: 1rem;
    color: #777;
    padding: 0.5rem;
    text-align: center;
    margin-bottom: 1rem;
}

.left-align {
    text-align: left;
}
</style>