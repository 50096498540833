<template>
    <form @submit.prevent="handleSubmit">
        <h3>Forgot Password ?</h3>

        <v-text-field type="email" label="Email" v-model="email" class="ml-4 mr-4 mt-10" color="accent"
            variant="underlined" :rules="[rules.required, rules.email]" required clearable />
        <v-btn class="mdhub-btn mt-6" elevation="0" :loading="emailSending" type="submit" @click="sendEmail"> Send reset
            link </v-btn>
        <v-snackbar v-model="snackbar" color="gray" elevation="24">
            {{ text }}

            <template v-slot:actions>
                <v-btn color="pink" variant="text" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>

    </form>
</template>


<script>

import { projectAuth } from '../firebase/config'
import { trackEvent, FORGOT_PASSWORD } from '@/utilities/analyticsService';

export default {
    data() {
        return {
            email: "",
            error: null,
            emailSending: false,
            snackbar: false,
            text: `We just sent you an email to reset your password.`,
            rules: {
                required: value => !!value || 'Required.',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
            },
        };
    },
    methods: {
        sendEmail() {
            if (!this.email) {
                this.error = "Please type in a valid email address.";
                return;
            }
            trackEvent(FORGOT_PASSWORD, { userEmail: this.email })
            this.error = null;
            this.emailSending = true;
            projectAuth
                .sendPasswordResetEmail(this.email)
                .then(() => {
                    this.emailSending = false;
                    this.snackbar = true
                })
                .catch(error => {
                    this.emailSending = false;
                    this.error = error.message;
                });
        },
    },
};
</script>