<template>
    <div v-if="isModalOpen" class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" maxlength="15">
                Template Title
                <input type="text" v-model="templateHeader" placeholder="e.g. SOAP" />
                Summary Items

                <!-- Scrollable container for input fields -->
                <div class="input-container" ref="inputContainer">
                    <div v-for="(value, index) in inputValues" :key="index">
                        <input type="text" v-model="inputValues[index]" maxlength="100" :placeholder="`Item ${index}`" />
                    </div>
                </div>

                <!-- Plus button to add new input field -->
                <!-- <button class="btn-solid-sm" @click="addInputField">+</button> -->
                <span class="plus-btn" @click="addInputField"> Add Item</span>


                <div class="button-container">
                    <button class="btn-solid-sm" v-if="!isPending" @click="handleClick()">Save</button>
                    <button class="btn-solid-sm" v-else disabled>Saving...</button>
                    <button class="btn-solid-sm" @click="closeModal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</template>
  
  
  
<script>
import useCollection from '@/composables/useCollection'
import getUser from '@/composables/getUser'
import { timestamp } from '@/firebase/config'
import { ref } from 'vue'

export default {
    props: ['isModalOpen'],
    methods: {
        closeModal() {
            this.$emit('close-modal');
        },
        addInputField() {
            this.inputValues.push('');
            // Scroll to the last input field after adding a new one
            this.$nextTick(() => {
                const inputContainer = this.$refs.inputContainer;
                if (inputContainer) {
                    const inputFields = inputContainer.getElementsByTagName('input');
                    if (inputFields && inputFields.length > 0) {
                        inputFields[inputFields.length - 1].scrollIntoView({
                            behavior: 'smooth',
                            block: 'end',
                            inline: 'nearest',
                        });
                    }
                }
            });
        },
    },
    setup(props, context) {
        const { user } = getUser()
        const { err, addDoc } = useCollection('summary-templates')
        const isPending = ref(false)
        const templateHeader = ref('');
        const inputValues = ref(['', '', '', '', '', '', '']); // Initialize the array with 7 empty strings

        const handleClick = async () => {
            isPending.value = true

            let customTemplate = inputValues.value
                .map(value => value.trim())
                .filter(value => value !== '') // Remove empty values
                .join('/');

            console.log(customTemplate)

            const res = await addDoc({
                template: customTemplate,
                templateHeader: templateHeader.value,
                userId: user.value.uid,
                userName: user.value.displayName,
                createdAt: timestamp()
            })
            context.emit('close-modal');
            isPending.value = false
        }

        return { handleClick, isPending, inputValues, templateHeader }
    }
};
</script>


  
<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    width: 375px;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.modal-container input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.button-container {
    display: flex;
    justify-content: center;
    /* Align buttons horizontally in the center */
    margin-top: 20px;
    /* Add some spacing between inputs and buttons */
}

.button-container button {
    margin: 0 5px;
    /* Add some spacing between buttons */
}

.input-container {
    max-height: 400px;
    /* Set the maximum height to adjust based on your requirements */
    overflow-y: auto;
}

.plus-btn {
    display: inline-flex;
    /* Use flexbox for alignment */
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    width: 100%;
    height: 40px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    background-color: #fff;
}

/* Optional: Hover effect */
.plus-btn:hover {
    background-color: #f5f5f5;
}
</style>
