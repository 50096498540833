import getUser from "./getUser";
import { createToken } from './useToken';

const getReusableUrl = async (context) => {
    const { user } = getUser();
    const userId = user.value.uid;
    const userName = user.value.displayName;
    const getToken = await createToken({ ...context, userId, userName, isReusable: true });
    const { url, token } = getToken;
    return { url, token };
}

export default getReusableUrl;