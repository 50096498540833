import { getFunctions, httpsCallable } from "firebase/functions";
import { ref, watchEffect, watch } from "vue";
import getUser from "./getUser";

const userDetailsObject = ref(null);
const { user } = getUser();

const getUserDetails = async (userId) => {
    const functions = getFunctions();
    const getUserById = httpsCallable(functions, 'getUserById');
    const { data } = await getUserById({ userId });
    return data;
};

const fetchUserDetails = async () => {
    if (user.value) {
        try {
            userDetailsObject.value = await getUserDetails(user.value.uid);
        } catch (error) {
            console.error("Error fetching user details:", error);
            userDetailsObject.value = null;
        }
    } else {
        userDetailsObject.value = null;
    }

    return userDetailsObject.value;
};


export const getUserObject = async () => await fetchUserDetails();;