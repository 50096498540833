import { ref, watchEffect } from 'vue';
import { projectFirestore } from '../firebase/config';

const getCollectionPatient = async (collection, query, userId, clinicId) => {
  const documents = ref([]);
  const error = ref(null);

  // Register the Firestore collection references
  let userCollectionRef
  try {
    userCollectionRef = projectFirestore.collection(collection).where('userId', '==', userId).orderBy('createdAt');  
  } catch (error) {
    console.log("No user id")
  }
  
  let clinicCollectionRef;
  
  if (clinicId) {
    try {
      clinicCollectionRef = projectFirestore.collection(collection).where('clinicId', '==', clinicId).orderBy('createdAt');  
    } catch (error) {
      console.log("No clinic id")
    }
  }

  const fetchDocuments = async (collectionRef) => {
    const querySnapshot = await collectionRef.get();
    return querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  };
  try {
    let userDocs
    try {
      userDocs = await fetchDocuments(userCollectionRef);  
    } catch (error) {
      
    }
    
    let clinicDocs = [];

    try {
      if (clinicCollectionRef) {
        clinicDocs = await fetchDocuments(clinicCollectionRef);
      }  
    } catch (error) {
      
    }
    // Merge results and remove duplicates
    const allDocs = [...userDocs, ...clinicDocs];
    const uniqueDocs = allDocs.reduce((acc, doc) => {
      if (!acc.some(existingDoc => existingDoc.id === doc.id)) {
        acc.push(doc);
      }
      return acc;
    }, []);

    documents.value = uniqueDocs;
    error.value = null;
  } catch (err) {
    console.error("Firestore error: ", err);
    documents.value = [];
    error.value = 'could not fetch the data';
  }

  return { documents, error };
};

export default getCollectionPatient;