<template>
    <div class="zoom-documentation">
        <h2>MDHUB FOR ZOOM</h2>
        <h4>Installation</h4>
        <p>
            You can install the MdHub for Zoom directly from your Zoom application.
            Simply do the following:
            <li>Navigate to the Apps menu item</li>
            <li>Select Add Apps</li>
            <li>On the left bar, search for MdHub</li>
            <li>On the results, Click on the Get button.</li>


            From there, MdHub’s app for Zoom will appear in your Zoom Apps under My Apps.
        </p>
        <h4>Usage</h4>
        <p>
            To start using MdHub's app for Zoom, open the Zoom client, begin a meeting, select a patient, and control
            the recording (start/stop).
            After the Zoom meeting concludes, AI insights will be generated for you, accessible from the MdHub’s
            dashboard as usual.
        </p>
        <p>
            Zoom then needs time to process the recording, which might take around 30 to 60 minutes, depending on the
            length of the session.
            You can still hold a new session while your previous session processes.
        </p>

        <h4>Uninstalling MdHub’s app for Zoom</h4>
        <p>
            <li>Login to the Zoom App Marketplace and follow installed applications link <a
                    href="https://marketplace.zoom.us/user/installed">here</a>.</li>
            <li>Click the MdHub for Zoom.</li>
            <li>Click the Uninstall button.</li>
        </p>
        <h4>Support for MdHub’s app for Zoom</h4>
        <p>Contact us directly via that <a href="https://www.mdhub.ai/contact"></a> form or at support@mdhub.ai.</p>

    </div>
</template>

<script>
export default {
    name: 'ZoomDocumentation',
    mounted() {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }
};
</script>

<style scoped>
.zoom-documentation {
    max-width: 800px;
    margin: auto;
    width: 50%;
    padding: 10px;
    text-align: left;

}

h1 {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: left;
}

p {
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: justify;
}
</style>