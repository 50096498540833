<template>
  <v-card class="medication-info-section mt-3" elevation="0">
    <div class="mb-2 mt-3">
      <div class="d-flex justify-space-between history-title">
        <div class="d-flex align-items-center ml-5">
          <div class="text-h6">Medication History</div>
          <!-- <span v-bind="props" class="beta-text" style="cursor: pointer;">(Beta)</span> -->
        </div>
        <v-btn class="white--text text--lighten-1" @click="handleDialog" variant="text">
          <v-icon dense class="add-meds-btn">mdi-plus</v-icon>
          <span class="white--text add-meds-btn"> Add Medication </span>
        </v-btn>

      </div>

      <v-expansion-panels class="pa-2 mt-4 " @update:modelValue="handlePanel" v-if="!isLoading">
        <v-expansion-panel elevation="0">
          <v-expansion-panel-text>
            <div>
              <!-- Display session information here -->
              <v-row>

                <v-col class="mt-0 mr-5 pr-7 pl-2">

                  <v-data-table :headers="headers" :items="sortedPatientMedication" v-if="sortedPatientMedication"
                    items-per-page="-1">
                    <template v-slot:item="{ item, index }">
                      <tr>
                        <td align="center" width="30px">
                          <v-icon v-if="item.currentMedication" color="green" size="12">mdi-circle</v-icon>

                          <v-icon v-else color="rgb(255, 114, 96)" size="12">mdi-circle-outline</v-icon>

                        </td>
                        <td width="150px" align="center">{{ getFormattedDate(item.date) }}</td>
                        <td align="center">{{ item.medication }}</td>
                        <td align="center">{{ item.dose }}</td>
                        <td align="center">{{ item.reason }}</td>
                        <td align="center">{{ item.comment || "-" }}</td>

                        <td>
                          <v-icon class="me-2 hover-icon" @click.stop="handleClick(index)">
                            mdi-square-edit-outline
                          </v-icon>
                          <v-icon class="" @click.stop="handleDelete(index)">
                            mdi-trash-can-outline
                          </v-icon>
                        </td>
                      </tr>

                    </template>
                    <template #bottom></template>
                  </v-data-table>
                  <div v-else>No medication yet</div>
                </v-col>

              </v-row>
            </div>
          </v-expansion-panel-text>
          <v-expansion-panel-title disable-icon-rotate>
            <template v-slot="{ expanded }" v-if="!expanded && getCurrentMedication.length === 0">
              <v-row no-gutters>
                <v-col class="d-flex justify-start" cols="3"> No current
                  medication </v-col>
              </v-row>

            </template>
            <div v-if="!isOpen && getCurrentMedication.length > 0" class="expandable-title">
              <!-- Display session information here -->
              <v-row>

                <v-col class="mt-0 mr-5 pr-7 pl-2">
                  <v-data-table :headers="headersCurrent" :items="getCurrentMedication">
                    <template v-slot:item="{ item, index }">
                      <tr>
                        <td align="center" width="30px">
                          <v-icon v-if="item.currentMedication" color="green" size="12">mdi-circle</v-icon>

                          <v-icon v-else color="rgb(255, 114, 96)" size="12">mdi-circle-outline</v-icon>

                        </td>
                        <td width="150px" align="center">{{ getFormattedDate(item.date) }}
                        </td>
                        <td align="center">{{ item.medication }}</td>
                        <td align="center">{{ item.dose }}</td>
                        <td align="center">{{ item.reason }}</td>
                        <td align="center">{{ item.comment || "-" }}</td>
                      </tr>

                    </template>
                    <template #bottom></template>
                  </v-data-table>
                </v-col>
              </v-row>
            </div>
          </v-expansion-panel-title>
        </v-expansion-panel>
      </v-expansion-panels>
      <template v-else>
        <v-row no-gutters>
          <v-col class="d-flex justify-start ml-5" cols="12"> Gathering medication from the patient's sessions,
            please wait... </v-col>
        </v-row>

      </template>
      <!-- <LikeDislike :userId="user.uid" :userEmail="user.email" :feature="feature"></LikeDislike> -->
    </div>
    <v-dialog v-model="showDialog" max-width="500" persistent>

      <v-card class="pa-5">
        <v-card-title class="mb-4">
          <span class="headline">{{ addMedication ? 'Add' : 'Update' }} medication</span>
        </v-card-title>
        <v-card-text>
          <v-text-field variant="underlined" v-model="editedItem.medication" label="Medication"></v-text-field>
          <v-text-field variant="underlined" v-model="editedItem.dose" label="Dosage"></v-text-field>
          <v-text-field variant="underlined" v-model="editedItem.reason" label="Diagnosis"></v-text-field>
          <v-text-field variant="underlined" v-model="editedItem.comment" label="Comment"></v-text-field>
          <v-checkbox v-model="editedItem.currentMedication" label="Currently Taking"></v-checkbox>




        </v-card-text>
        <v-card-actions class="d-flex align-end justify-end">
          <v-btn color="primary" @click="closeDialog">Cancel</v-btn>
          <v-btn class="mdhub-btn" @click="handleSaveDebounced" width="auto" :loading="isUpdating">{{
          addMedication ? 'Add' : 'Update' }}</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">

      <v-card>
        <v-card-title class="mt-6 d-flex justify-center">
          Are you sure you want to delete this row?
        </v-card-title>
        <v-card-actions class="mb-2">
          <v-spacer></v-spacer>
          <v-btn class="mdhub-btn" variant="text" @click="closeDialog">Cancel</v-btn>
          <v-btn color="blue-darken-1" variant="text" @click="handleClick(index, true)">Delete</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>


</template>

<script>

import { ref, watch, getCurrentInstance } from 'vue'
import { getFunctions, httpsCallable } from "firebase/functions";
import useDocument from '@/composables/useDocument'
import { useRoute } from 'vue-router'
import { debounce } from 'lodash';
import getUser from '@/composables/getUser'
import getCollection from '@/composables/getCollection'
import { SessionState } from '@/composables/getUserActionState';
import {
  trackEvent, DELETING_PATIENT_MEDICATION, SHOULD_VECTORIZE_MEDICATION, UPDATING_PATIENT_MEDICATION, RETRIEVED_PATIENT_MEDICATION, RETRIEVED_RESPONSE_FROM_RAG, MAPPED_RESPONSE_FROM_RAG, SESSIONS_WITHOUT_MEDICATION, PATIENT_MEDICATIONS_UPDATE_FAILED, PATIENT_MEDICATIONS_UPDATED, PATIENT_MEDICATIONS_SORTED_BY_DATE, ADDING_PATIENT_MEDICATION
} from '@/utilities/analyticsService';
import LikeDislike from '@/components/shared/LikeDislike.vue'



export default {
  props: ['patientId', 'patientSessions', 'patientMedicalHistory'],
  name: 'PatientMedication',
  data() {
    return {
      handleSaveDebounced: null,
      showDialog: false,
      isOpen: false,
      medicationUpdate: '',
      dosageUpdate: '',
      reasonUpdate: '',
      comment: '',
      currentMedication: false,
      currentSessionId: '',
      deleted: false,
      dialogDelete: false,
      itemToDelete: null,
      medicationId: null,
      isUpdating: false,
      addMedication: false,
      indexItem: -1,
      defaultItem: {
        sessionId: '',
        currentMedication: false,
        date: '',
        deleted: false,
        dose: '',
        manuallyCreated: false,
        medication: '',
        medicationId: '',
        reason: '',
        updated: false
      },
      headersCurrent: [
        {
          title: 'Currently Taking', value: 'currentMedication', align: 'center',
        },
        {
          title: 'Date', value: 'date', align: 'center',
        },
        {
          title: 'Medication', value: 'medication', align: 'center',
        },
        {
          title: 'Dosage', value: 'dose', align: 'center',
        },
        {
          title: 'Diagnosis', value: 'reason', align: 'center',
        },
        {
          title: 'Comment', value: 'comment', align: 'center',
        },
      ],
      headers: [
        {
          title: 'Currently Taking', value: 'currentMedication', align: 'center',
        },
        {
          title: 'Date', value: 'date', align: 'center',
        },
        {
          title: 'Medication', value: 'medication', align: 'center',
        },
        {
          title: 'Dosage', value: 'dose', align: 'center',
        },
        {
          title: 'Diagnosis', value: 'reason', align: 'center',
        },
        {
          title: 'Comment', value: 'comment', align: 'center',
        },
        {
          title: 'Actions', value: 'actions', align: 'center',
          sortable: false
        },

      ]
    };
  },
  components: {
    LikeDislike,
  },
  created() {
    this.handleSaveDebounced = debounce(this.updatePatientMedication, 1000); // 1 second delay        
  },
  computed: {
    getCurrentMedication() {
      const currentMedication = this.sortedPatientMedication?.filter(medication => medication.currentMedication);
      return currentMedication;
    },
  },
  methods: {
    handleDialog() {
      this.addMedication = this.indexItem === -1
      this.showDialog = true;

    },
    closeDialog() {
      this.showDialog = false;
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.indexItem = -1
        this.isUpdating = false;
        this.addMedication = false;
      })
    },
    getCurrentDate() {
      const today = new Date();
      return today.toISOString().split('T')[0];
    },
    handlePanel($event) { this.isOpen = $event === 0 },
    handleDelete(index) {
      this.dialogDelete = true
      this.indexItem = index
      this.editedItem = Object.assign({}, this.sortedPatientMedication[index]);
    },
    getFormattedDate(date) {
      const formattedDate = new Date(date)
        .toLocaleDateString("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric"
        });
      return formattedDate
    },
    async handleClick(index, isDeleting) {
      this.indexItem = index
      this.addMedication = false;
      this.deleted = isDeleting || false;
      if (!isDeleting) {
        this.showDialog = true;
        this.editedItem = Object.assign({}, this.sortedPatientMedication[index])
      } else {
        // deleting
        trackEvent(DELETING_PATIENT_MEDICATION, { userId: this.user.uid, userEmail: this.user.email, patientId: this.patientId, source: "Web" })
        await this.updatePatientMedication()
      }


    },

    async updatePatientMedication() {
      if (!this.deleted) {
        trackEvent(UPDATING_PATIENT_MEDICATION, { userId: this.user.uid, userEmail: this.user.email, patientId: this.patientId, updatedPatientMedication: this.editedItem, source: "Web" })
      }
      if (this.addMedication) {
        trackEvent(ADDING_PATIENT_MEDICATION, { userId: this.user.uid, userEmail: this.user.email, patientId: this.patientId, data: this.editedItem, source: "Web" })
      }

      this.isUpdating = true;
      const updatedMedicalInformation = {
        ...this.editedItem,
        updated: !this.deleted,
        deleted: this.deleted,
        medicationId: this.addMedication ? Date.now() : this.editedItem.medicationId,
        manuallyCreated: this.editedItem.manuallyCreated || this.addMedication,
      }

      const updatedPatientMedication = !this.addMedication ? this.sortedPatientMedication.map(item => {
        if (item.medicationId && (item.medicationId === this.editedItem.medicationId)) {
          return { ...item, ...updatedMedicalInformation }; // Merge the original object with the updated/new values
        }
        return item; // Return the original object unchanged
      }) : [...this.sortedPatientMedication, { ...updatedMedicalInformation, date: this.getCurrentDate() }];

      await this.updateDocument({
        patientMedicalHistory: updatedPatientMedication
      });
      this.sortedPatientMedication = this.sortByDate(updatedPatientMedication.filter(med => !med.deleted));
      this.closeDialog()
    },
  },
  setup(props) {
    const { updateDocument } = useDocument('patients', props.patientId);
    const patientId = props.patientId;
    const { user } = getUser();
    const patientMedication = ref([]);
    const isDev = ref(false);
    const patientSessions = ref(null)
    const isLoading = ref(false);
    const route = useRoute()
    const instance = getCurrentInstance();
    const feature = ref(instance.type.name);
    patientSessions.value = props.patientSessions;
    const { documents: userData } = getCollection(
      'user',
      ['userId', '==', user.value.uid])

    const sortedPatientMedication = ref([]);
    const editedItem = ref({
      sessionId: '',
      currentMedication: false,
      date: '',
      deleted: false,
      dose: '',
      manuallyCreated: false,
      medication: '',
      medicationId: '',
      reason: '',
      updated: false
    })

    const getPatientMedication = async () => {

      try {
        patientMedication.value = props.patientMedicalHistory?.filter(med => !med.deleted) || [];
        const patientMedicationSessionIds = [...new Set(patientMedication.value.map(session => session.sessionId))];

        const diff = getDiff(patientMedicationSessionIds);
        const shouldVectorize = diff.length > 0
        isLoading.value = shouldVectorize;
        trackEvent(SHOULD_VECTORIZE_MEDICATION, { userId: user.value.uid, userEmail: user.value.email, patientId: patientId, shouldVectorize, diff, source: "Web" })
        let newSessionsWithMedication = [];
        if (shouldVectorize) {
          const functions = getFunctions();
          const onProcessPatientSession = httpsCallable(functions, 'on_retrieve_patient_medications');
          const response = await onProcessPatientSession({ patient_id: patientId });
          trackEvent(RETRIEVED_RESPONSE_FROM_RAG, { userId: user.value.uid, userEmail: user.value.email, patientId: patientId, data: response?.data, source: "Web" })

          if (response && response.data) {

            trackEvent(RETRIEVED_PATIENT_MEDICATION, { userId: user.value.uid, userEmail: user.value.email, patientId: patientId, source: "Web" })

            newSessionsWithMedication = response.data.map((mdObj) => ({
              sessionId: mdObj.session_id,
              date: mdObj.session_date_created,
              medication: mdObj.medication,
              dose: mdObj.doses,
              reason: mdObj.reason_of_medication,
              deleted: false,
              updated: false,
              medicationId: mdObj.medication_id
            })).filter(data => !patientMedicationSessionIds.includes(data.sessionId));
            trackEvent(MAPPED_RESPONSE_FROM_RAG, { userId: user.value.uid, userEmail: user.value.email, patientId: patientId, data: newSessionsWithMedication, source: "Web" })

            patientMedication.value = [
              ...newSessionsWithMedication,
              ...patientMedication.value
            ]; // append the new sessions dont update anything    

            //map the updated sessions and get the difference again 
            //store the conversations that dont include medication as well in order to avoid running the RAG again and again due to the constant diff
            const updatedPatientMedicationIds = patientMedication.value.map(session => session.sessionId);
            const sessionsWithoutMedications = getDiff(updatedPatientMedicationIds).map(sessionId => ({
              sessionId,
              medication: null,
              date: null,
              deleted: false,
            }));

            trackEvent(SESSIONS_WITHOUT_MEDICATION, { userId: user.value.uid, userEmail: user.value.email, patientId: patientId, data: sessionsWithoutMedications, source: "Web" })
            const updateMedications = [
              ...patientMedication.value,
              ...sessionsWithoutMedications
            ];

            const res = await updateDocument({
              patientMedicalHistory: updateMedications
            });
            trackEvent(PATIENT_MEDICATIONS_UPDATED, { userId: user.value.uid, userEmail: user.value.email, patientId: patientId, data: updateMedications, source: "Web" })

          }
        }

      }
      catch (error) {
        trackEvent(PATIENT_MEDICATIONS_UPDATE_FAILED, { userId: user.value.uid, userEmail: user.value.email, patientId: patientId, error, source: "Web" })
        console.error("Error sending medication request:", error);
      }

      sortedPatientMedication.value = sortByDate(patientMedication.value);
      trackEvent(PATIENT_MEDICATIONS_SORTED_BY_DATE, { userId: user.value.uid, userEmail: user.value.email, patientId: patientId, data: sortedPatientMedication.value, source: "Web" })
      isLoading.value = false
    }

    const sortByDate = (list) => list.filter(medication => medication.date).sort((a, b) => {
      if (a.date > b.date) return -1
    })


    const getDiff = (patientMedicationSessionIds) => {
      const patientSessionIds = patientSessions.value.filter(patientSession => SessionState.Completed === patientSession.state).map(session => session.id);
      // check if new sessions have been created - no need to check the date
      const patientMedicationSessionIdSet = new Set(patientMedicationSessionIds);
      const diff = patientSessionIds.filter(item => !patientMedicationSessionIdSet.has(item));
      return diff;
    }
    watch(() => route.value?.name === "PatientOverview", getPatientMedication, { immediate: true })

    return { feature, sortedPatientMedication, isDev, patientId, updateDocument, isLoading, user, editedItem, sortByDate };
  },
}
</script>

<style scoped>
.occupy-all-space {
  grid-column: 1 / -1;
  /* Span from column 1 to the last column */
}

.patient-info-container {
  grid-column: 2;
  /* Place patient-info-container in the second column */
  display: flex;
  flex-wrap: wrap;

}

.add-button {
  grid-column: 3;
  /* Place add-button in the third column */
}

.beta-text {
  color: var(--bittersweet);
}

.medication-info-section {
  background-color: white;
  border-radius: 10px;
  border: 1px solid rgba(224, 224, 224, 0.5);
  padding: 4px;
  font-family: inherit;
  font-size: inherit;
  line-height: 1.5;
}

.no-meds {
  text-align: left;
}

.white--text {
  color: white;
  text-transform: none;
}

.expandable-title {
  width: 100%
}

.add-meds-btn {
  background-color: transparent !important;
  color: var(--bittersweet);
  text-transform: none !important;
  width: auto;
}

@media (max-width: 768px) {
  .d-flex.history-title {
    flex-direction: column;
  }
}

.justify-space-between {
  justify-content: space-between !important;

}
</style>
