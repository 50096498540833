
import { getFunctions, httpsCallable } from "firebase/functions";
import { trackEvent, OUTDATED_VERSION } from '@/utilities/analyticsService';
import { getIsZoomApp } from '@/composables/configureZoom'
import getUser from "./getUser";

const { user } = getUser()
const getNewVersion = async () => {
  // check version and refresh if needed 
  const { isZoomApp } = getIsZoomApp();
  const isMdhubZoom = isZoomApp.value;

  const localAppVersion = localStorage.getItem('appVersion');
  console.log('localAppVersion', localAppVersion)
  const functions = getFunctions();
  const getVersion = httpsCallable(functions, 'getVersion');
  const { data: appVersion } = await getVersion();
  let version = '';
  let newFeatures = [];

  if (appVersion.value) {
    version = appVersion.value
  } else {
    version = appVersion.version;
    newFeatures = appVersion.newFeatures;
  }

  if (localAppVersion != version) {
    trackEvent(OUTDATED_VERSION, { userId: user.value.uid, source: isMdhubZoom ? "Zoom" : "Web" })
    localStorage.setItem('appVersion', version);
    localStorage.setItem('hasNewFeatures', newFeatures.length > 0);
    localStorage.setItem('newFeatures', JSON.stringify(newFeatures));
    location.reload();
  }
}

export default getNewVersion