<template>
    <v-navigation-drawer v-if="user" v-model="drawer" :rail="rail" permanent @click="rail = false"
        @mouseenter="expandDrawer" @mouseleave="collapseDrawer">
        <v-divider></v-divider>
        <v-list density="compact" nav class="left-align-text">

            <v-list-subheader v-if="!rail">User Details</v-list-subheader>
            <v-list-item :class="{ 'no-underline-on-hover': true }" link prepend-icon="mdi-cog-outline" title="Settings"
                :to="{ name: 'UserDetails' }" value="settings"
                :subtitle="userObject ? userObject.email : 'email'"></v-list-item>
            <template v-if="!isZoomApp">
                <v-list-item v-if="isAdmin" :class="{ 'no-underline-on-hover': true }" link
                    prepend-icon="mdi-account-supervisor-circle-outline" title="Admin" :to="{ name: 'AdminPanel' }"
                    value="admin"></v-list-item>
            </template>
            <v-divider style="background-color: #9d9da1;"></v-divider>
            <v-list-subheader v-if="!rail">Management</v-list-subheader>
            <v-list-item :class="{ 'no-underline-on-hover': true }" link prepend-icon="mdi-account-group-outline"
                title="Patients" :to="{ name: 'UserPatients' }" value="patients"></v-list-item>
            <v-list-item :class="{ 'no-underline-on-hover': true }" link title="Inbox" :to="{ name: 'Inbox' }"
                value="inbox">
                <template v-slot:prepend>
                    <v-badge v-if="unreviewedQuestionnairesCount > 0" :content="unreviewedQuestionnairesCount"
                        color="var(--greenland-green)">
                        <v-icon>mdi-inbox-arrow-down-outline</v-icon>
                    </v-badge>
                    <v-icon v-else>mdi-inbox-arrow-down-outline</v-icon>

                </template>
            </v-list-item>
            <!-- <v-list-item :class="{ 'no-underline-on-hover': true }" link prepend-icon="mdi-chat-plus-outline"
                title="Questionnaire Builder" :to="{ name: 'Questionnaires' }" value="questionnaires"
                hidden></v-list-item> -->

            <!-- <v-list-item :class="{ 'no-underline-on-hover': true }" link prepend-icon="mdi-text-box-edit-outline"
                title="Questionnaires " :to="{ name: 'AllQuestionnaires' }" value="all-questionnaires" hidden
                v-if="!isZoomApp"></v-list-item> -->

            <v-list-item :class="{ 'no-underline-on-hover': true }" link title="Questionnaires"
                prepend-icon="mdi-text-box-edit-outline" :to="{ name: 'Questionnaires' }" value="questionnaires"
                v-if="!isZoomApp">

            </v-list-item>


            <template v-if="!isZoomApp">
                <v-list-item :class="{ 'no-underline-on-hover': true }" link prepend-icon="mdi-file-edit-outline"
                    title="Templates" :to="{ name: 'Templates' }" value="templates"></v-list-item>
                <v-list-item :class="{ 'no-underline-on-hover': true }" link prepend-icon="mdi-storefront-outline"
                    title="Community" :to="{ name: 'Community' }" value="community"></v-list-item>
                <v-list-subheader v-if="!rail">Share</v-list-subheader>
                <v-list-item :class="{ 'no-underline-on-hover': true }" link prepend-icon="mdi-email-outline"
                    title="Contact Us" :to="{ name: 'ContactUs' }" value="contact-us/fullPage"></v-list-item>

                <v-list-item :class="{ 'no-underline-on-hover': true }" link prepend-icon="mdi-email-fast-outline"
                    title="Refer Colleagues" :to="{ name: 'Referral' }" value="referral"></v-list-item>
                <v-list-item :class="{ 'no-underline-on-hover': true }" link prepend-icon="mdi-new-box"
                    title="New features" target="_blank"
                    href="https://mdhub.notion.site/mdhub-Product-Updates-c1e0b8679a3c4db6b6330eb36cc8eaeb"></v-list-item>
            </template>

        </v-list>

        <template v-slot:append v-if="!rail">
            <div class="pa-2 version">
                App Version: {{ appVersion }}
            </div>
        </template>
    </v-navigation-drawer>
</template>

<script>

import getUser from '../composables/getUser'
import { ref, watchEffect } from 'vue';
import useDocument from '@/composables/useDocument'
import { getIsZoomApp } from '@/composables/configureZoom'
import { useUnreviewedQuestionnairesCount } from '@/composables/useUnreviewedQuestionnairesCount';
import { getUserObject } from '@/composables/getUserObject'
// import { isWhiteListedAccount } from '@/composables/isWhitelistedAccount'
export default {
    data() {
        return {
            drawer: true,
            rail: true,
        }
    },
    methods: {
        expandDrawer() {
            const userAgent = navigator.userAgent;
            const isMobile = /Mobile|Android|iOS/i.test(userAgent);
            if (isMobile) return
            this.rail = false;
        },
        collapseDrawer() {
            this.rail = true;
        }
    },
    setup() {
        const { unreviewedQuestionnairesCount } = useUnreviewedQuestionnairesCount();

        const { user } = getUser()
        const userObject = ref({});
        const documentHelpers = ref(null);
        const isAdmin = ref(false);
        const appVersion = localStorage.getItem('appVersion');
        const { isZoomApp } = getIsZoomApp();
        // const canViewQuestionnaires = ref(false);
        // canViewQuestionnaires.value = isWhiteListedAccount();

        watchEffect(async () => {
            userObject.value = await getUserObject();
            if (userObject.value) {
                documentHelpers.value = useDocument('user', userObject.value.id)
                if (userObject.value.isAdmin) {
                    isAdmin.value = true
                }
            } else {
                userObject.value = {}; // Clear user object on logout
            }
        });


        return { user, userObject, isAdmin, appVersion, isZoomApp, unreviewedQuestionnairesCount }
    },

}
</script>

<style scoped>
.v-navigation-drawer {
    z-index: 0 !important;
}

.no-underline-on-hover:hover {
    text-decoration: none !important;
}


.left-align-text {
    text-align: left;
}

.version {
    font-size: 0.8125rem;
}
</style>