<template>
    <v-btn icon class="absolute-top-right ml-auto mr-4" @click="text = ''; $emit('close-dialog')" elevation="0">
        <v-icon>mdi-close</v-icon>
    </v-btn>
    <div class="d-flex flex-column border-0 w-100" style="box-shadow: none; height: 100vh;">

        <div class="d-flex flex-column w-100 flex-grow-1 pr-4 pl-4">
            <h4 class="drawer-title text-start w-100">
                Input patient history
            </h4>
            <div class="text-h9 mt-1 text-start w-100">
                Please paste the previous patient notes to generate a clinical note based on the past patient history.
            </div>

            <v-expansion-panels class="followup-section mt-2" v-model="templatePanel">
                <v-expansion-panel elevation="0" :value="true">
                    <v-expansion-panel-title class="recorder-title py-4 text-start">
                        {{ selectedTemplate?.title ? `Template: ${selectedTemplate.title}` : `Templates` }}
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <v-list>
                            <v-list-item v-for="(template, i) in userTemplates" :key="i"
                                @click="selectTemplate(template)" class="text-start">
                                <v-list-item-content>
                                    <v-list-item-title class="d-flex align-center">
                                        <v-icon left class="mr-4">mdi-file-document-outline</v-icon>
                                        {{ template?.title }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="userTemplates.length === 0">
                                <v-list-item-content>
                                    <v-list-item-title class="d-flex align-center">
                                        Loading templates...
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>

            <v-textarea v-model="text" :error-messages="v$?.text?.$errors?.map(e => e.$message)" class="mt-4"
                label="Clinical notes" auto-grow variant="outlined" clearable rows="18" row-height="24"
                style="width: 100%;">
            </v-textarea>
        </div>

        <v-card-actions class="d-flex justify-center sticky-actions stack-buttons">
            <v-btn color="red" text @click="submit" stacked :loading="loading">
                <v-icon class="mb-2" prepend-icon style="color: #071022;" dense>{{ 'mdi-pencil' }}</v-icon>
                <span>Generate note</span>
            </v-btn>
        </v-card-actions>
    </div>
</template>

<script setup>
import getUser from '@/composables/getUser'
import { getUserCollection } from '@/composables/userService';
import { ref, defineProps, getCurrentInstance, onMounted } from 'vue';
import { getFunctions, httpsCallable } from "firebase/functions";
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { trackEvent, IMPORTED_HISTORY, IMPORTED_HISTORY_FAILED } from '@/utilities/analyticsService';
import { template } from 'lodash';

const { user } = getUser()
const userObject = ref({});

const props = defineProps({
    patientId: String
})
const text = ref('')
const loading = ref(false)
const userTemplates = ref([])
const templatePanel = ref(true)
const selectedTemplate = ref(null)
const { emit } = getCurrentInstance();
const functions = getFunctions();

onMounted(async () => {
    getUserCollection(user.value.uid).then((res) => {
        userObject.value = res;
    });

    loading.value = true;
    const getTemplates = httpsCallable(functions, 'getTemplates');
    const result = await getTemplates({ userId: user.value.uid, history: true });
    userTemplates.value = result.data.templates;
    if (result.data.lastTemplateId) {
        selectedTemplate.value = userTemplates.value.find(t => t.id === result.data.lastTemplateId);
        templatePanel.value = false;
    }
    loading.value = false;
})

const submit = async () => {
    v$.value.$touch()
    if (!v$.value.$invalid) {
        loading.value = true;
        const uploadPatientHistory = httpsCallable(functions, 'uploadPatientHistory');

        try {
            await uploadPatientHistory({
                patientId: props.patientId,
                text: text.value,
                source: 'web',
                templateId: selectedTemplate.value?.id
            });
            text.value = ''
            v$.value.$reset()
            emit('close-dialog')

            trackEvent(IMPORTED_HISTORY, { userId: user.value.uid, userEmail: userObject.value.email, patientId: props.patientId });

        } catch (error) {
            trackEvent(IMPORTED_HISTORY_FAILED, { userId: user.value.uid, userEmail: userObject.value.email, patientId: props.patientId });

            console.error(error);
        }

        loading.value = false;
    }
}

const selectTemplate = async (template) => {
    templatePanel.value = false;
    selectedTemplate.value = template;
}

const rules = {
    text: { required }
}

const v$ = useVuelidate(rules, { text })
</script>

<style scoped>
.drawer-title {
    font-size: 18px;
}

.stack-buttons {
    flex-direction: row;
}

.sticky-actions {
    position: sticky;
    bottom: 0;
    background-color: white;
    z-index: 10;
    width: 100%;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.followup-section {
    background-color: white;
    border-radius: 4px;
    padding: 4px;
    font-family: inherit;
    font-size: inherit;
    line-height: 1.5;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
</style>
