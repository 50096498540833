import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
import { getFirestore, Timestamp } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig =
  process.env.NODE_ENV === "production"
    ? {
      apiKey: process.env.VUE_APP_FIREBASE_KEY,
      authDomain: process.env.VUE_APP_AUTH_DOMAIN,
      projectId: process.env.VUE_APP_PROJECT_ID,
      storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
      appId: process.env.VUE_APP_APP_ID,
      deepgramApiKey: process.env.VUE_APP_DEEPGRAM_API_KEY,
      functionApiKey: process.env.VUE_APP_FUNCTION_API_KEY,
      firebaseFunctionsBaseUrl:
        process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL,
    }
    : {
      apiKey: process.env.VUE_APP_FIREBASE_KEY_DEV,
      authDomain: process.env.VUE_APP_AUTH_DOMAIN_DEV,
      projectId: process.env.VUE_APP_PROJECT_ID_DEV,
      storageBucket: process.env.VUE_APP_STORAGE_BUCKET_DEV,
      messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID_DEV,
      appId: process.env.VUE_APP_APP_ID,
      deepgramApiKey: process.env.VUE_APP_DEEPGRAM_API_KEY || "6b9e7a0dd76ec05efc74d938349a0a7744301026",
      functionApiKey: process.env.VUE_APP_FUNCTION_API_KEY,
      firebaseFunctionsBaseUrl:
        process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL,
    };

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app);

// Init service
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();
const projectStorage = firebase.storage();

// Timestamp

const timestamp = firebase.firestore.FieldValue.serverTimestamp;

const timestampClass = Timestamp;

const db = getFirestore();

export {
  projectFirestore,
  projectAuth,
  projectStorage,
  timestamp,
  timestampClass,
  db,
  analytics,
  firebaseConfig,
};
