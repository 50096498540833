<template>
  <form @submit.prevent="handleSubmit" v-if="!isSignedUp">
    <h3>Sign Up</h3>
    <div v-if="!isZoom">
      <v-btn class="google-btn google-btn-text mt-8" @click="handleClick" :loading="isPendingGoogle" elevation="0"
        block>
        <v-icon left>
          <img src="@/assets/google_icon.svg" alt="Google Icon" />
        </v-icon>
        Sign in with Google
      </v-btn>

      <v-btn class="google-btn google-btn-text mt-8" @click="handleMicrosoftSignUp" :loading="isPendingGoogle"
        elevation="0" block>
        <v-icon left>
          <img src="@/assets/ms-logo.svg" alt="Microsoft Icon" />
        </v-icon>
        Sign in with Microsoft
      </v-btn>

      <div class="divider-container ml-4 mr-4">
        <v-divider class="custom-divider" />
        <span class="divider-text">or</span>
        <v-divider class="custom-divider" />
      </div>
    </div>

    <v-text-field type="text" placeholder="Display Name" v-model="displayName" class="ml-4 mr-4 mt-4" color="accent"
      variant="underlined" density="compact" required />
    <v-text-field type="email" placeholder="Email" v-model="email" class="ml-4 mr-4" color="accent" variant="underlined"
      density="compact" required />
    <v-text-field :type="show1 ? 'text' : 'password'" placeholder="Password" v-model="password" class="ml-4 mr-4"
      color="accent" variant="underlined" density="compact" required :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append="toggleShowPassword" />

    <v-text-field v-if="showPromoField" type="text" placeholder="Promo code" v-model="promoCode" class="ml-4 mr-4 mt-4"
      color="accent" variant="underlined" density="compact" append-inner-icon="mdi-close"
      @click:append-inner="showPromoField = false" />

    <ul v-if="showRules" class="rules-list ml-3">
      <li :class="{ 'rule-met': hasLetter, 'rule-not-met': !hasLetter && highlightRules }">Contains a letter</li>
      <li :class="{ 'rule-met': hasCapital, 'rule-not-met': !hasCapital && highlightRules }">Contains a capital letter
      </li>
      <li :class="{ 'rule-met': hasNumber, 'rule-not-met': !hasNumber && highlightRules }">Contains a number</li>
      <li :class="{ 'rule-met': hasSpecial, 'rule-not-met': !hasSpecial && highlightRules }">Contains a special
        character</li>
      <li :class="{ 'rule-met': isMinLength, 'rule-not-met': !isMinLength && highlightRules }">Is at least 12 characters
        long</li>
    </ul>

    <div v-if="error" class="rule-not-met">{{ error }}</div>

    <v-checkbox v-model="checkbox" class="agree-check">
      <template v-slot:label>
        <div>
          I agree to mdhub's

          <a href="https://mdhub.ai/terms-of-service" target="_blank" v-bind="props" @click.stop>
            Terms of Service,
          </a>
          <a href="https://mdhub.ai/business-associate-agreement" target="_blank" v-bind="props" @click.stop>
            Business Associate Agreement
          </a> and acknowledge the
          <a href="https://mdhub.ai/privacy-policy" target="_blank" v-bind="props" @click.stop>
            Privacy Policy
          </a>

        </div>
      </template>
    </v-checkbox>
    <v-tooltip v-if="!showPromoField" text="Don't have a promo code? No worries, you can enter it later!"
      location="end">
      <template v-slot:activator="{ props }">
        <button v-if="!showPromoField" @click="showPromoField = true" v-bind="props" style="color: grey;">
          Do you have a promo code?
        </button>
      </template>
    </v-tooltip>

    <v-btn class="mdhub-btn mt-4" elevation="0" :loading="isPending" type="submit" :disabled="!checkbox"> Agree and
      continue</v-btn>

    <p class="mt-4">Already have an account? <a href="#" @click.prevent="navigateToLogin">Login</a></p>
  </form>
  <div v-else class="d-flex justify-center">
    <SavingSpinner label="Preparing your account please wait" style="position:relative;" />
  </div>
</template>

<script>

import useSignup from '@/composables/useSignup'
import useSignupGoogle from '@/composables/useSignupGoogle'
import useSignupMicrosoft from '@/composables/useSignupMicrosoft'
import useCollection from '@/composables/useCollection'
import { ref, computed, getCurrentInstance, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { sendEmailVerification } from 'firebase/auth';
import { timestamp } from '@/firebase/config'
import { checkUserExists } from '@/composables/userService';
import { trackEvent, NEW_USER_SIGNUP, NEW_USER_SIGNUP_FAILED } from '@/utilities/analyticsService';
import { getIsZoomApp } from '@/composables/configureZoom'
import { getFunctions, httpsCallable } from "firebase/functions";
import SavingSpinner from '@/components/SavingSpinner.vue'

export default {
  components: { SavingSpinner },
  mounted() {
    this.setMetaTags();
  },
  methods: {
    setMetaTags() {
      document.title = "mdhub Signup - Access the AI Scribe for Behavioral Health Clinicians";
      let metaDescription = document.querySelector('meta[name="description"]');
      if (!metaDescription) {
        metaDescription = document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        document.getElementsByTagName('head')[0].appendChild(metaDescription);
      }
      metaDescription.setAttribute('content', 'Signup to mdhub, the #1 AI assistant for mental health care clinicians. Experience seamless, secure, and efficient AI assistant that you can fully focus on patient care');
    },
    toggleShowPassword() {
      this.show1 = !this.show1;
    },
    navigateToLogin() {
      this.$router.push({ name: 'Login' });
    }
  },
  setup() {
    const { err, addDoc } = useCollection('user')
    const { error, signup, isPending } = useSignup();
    const { errorGoogle, signupGoogle, isPendingGoogle } = useSignupGoogle();
    const { errorMicrosoft, signupMicrosoft, isPendingMs } = useSignupMicrosoft();
    const router = useRouter();
    const email = ref("");
    const password = ref("");
    const displayName = ref("");
    const instance = getCurrentInstance();
    const promoCode = ref("");
    const showPromoField = ref(false)
    const highlightRules = ref(false);
    const show1 = ref(false);
    const agreeCheck = ref()
    const checkbox = ref(false);
    const { isZoomApp } = getIsZoomApp();
    const isZoom = isZoomApp.value;

    const isSignedUp = ref(false);

    const referralCode = router.currentRoute.value.query.code;
    instance.appContext.config.globalProperties.$gtm.push({ event: 'signup ' });

    if (referralCode) {
      showPromoField.value = true
      promoCode.value = referralCode
    }

    const hasLetter = computed(() => /[a-z]/.test(password.value));
    const hasCapital = computed(() => /[A-Z]/.test(password.value));
    const hasNumber = computed(() => /[0-9]/.test(password.value));
    const hasSpecial = computed(() => /[\W_]/.test(password.value));
    const isMinLength = computed(() => password.value.length >= 12);

    const showRules = computed(() => password.value.length > 0);

    const passwordStrength = computed(() => {
      let score = 0;
      if (isMinLength.value) score++;
      if (hasLetter.value) score++;
      if (hasCapital.value) score++;
      if (hasNumber.value) score++;
      if (hasSpecial.value) score++;
      return score;
    });

    const handleSubmit = async () => {
      highlightRules.value = true; // Highlight the rules that are not met
      isPending.value = true;

      const strength = passwordStrength.value;
      if (strength < 5) {
        error.value = "Please meet the requirements from above."
        isPending.value = false;
      } else {
        try {
          const res = await signup(email.value, password.value, displayName.value);
          await sendEmailVerification(res.user);
          await addDoc({
            email: email.value,
            name: displayName.value,
            userId: res.user.uid,
            promoCode: promoCode.value,
            createdAt: timestamp()
          })

          if (!error.value) {
            isSignedUp.value = true;
            trackEvent(NEW_USER_SIGNUP, { userId: res.user.uid, userEmail: email.value, method: "Email/Pass" })
            await addClinicIdToNewUser(res.user.email);
            instance.appContext.config.globalProperties.$gtm.push({ event: 'newUser ' });
            isPending.value = false;
            redirectToPatientsPage();
          }
        } catch (error) {
          isPending.value = false;
          isSignedUp.value = false;
          trackEvent(NEW_USER_SIGNUP_FAILED, { userId: '-', userEmail: email.value, error, method: "Email/Pass" })
        }
      }
    };

    const redirectToPatientsPage = () => {
      router.push({
        name: "UserPatients", query: { newUser: 'true' }
      });;
    }

    const handleMicrosoftSignUp = async () => {
      const res = await signupMicrosoft();
      await createUserAndNavigate(res)
    };

    const handleClick = async () => {
      const res = await signupGoogle();
      await createUserAndNavigate(res)
    };

    const addClinicIdToNewUser = async (email) => {
      try {
        const functions = getFunctions();
        const addToClinic = httpsCallable(functions, 'addClinicToUser');
        await addToClinic({ email });
      } catch (error) {
        console.error('Error adding clinic to user:', error);
      }

    }

    async function createUserAndNavigate(res) {
      const userExists = await checkUserExists(res?.user.uid);
      if (!userExists) {
        await addDoc({
          email: res.user.email,
          name: res.user.displayName,
          userId: res.user.uid,
          speciality: "",
          promoCode: promoCode.value,
          createdAt: timestamp()
        })
      }

      if (!error.value) {
        trackEvent(NEW_USER_SIGNUP, { userId: res.user.uid, userEmail: res.user.email, method: "Google" })
        // assuming that the user has been created 
        await addClinicIdToNewUser(res.user.email);
        router.push({ name: "UserPatients", query: { newUser: 'true' } });
      }
    }

    return {
      isZoom,
      email, password, displayName, handleSubmit,
      handleMicrosoftSignUp,
      error, isPending, errorGoogle, isPendingGoogle, handleClick, promoCode, showPromoField,
      hasLetter, hasCapital, hasNumber, hasSpecial, isMinLength, highlightRules, showRules, show1, checkbox,
      isSignedUp
    };
  }
}
</script>

<style scoped>
.strength-indicator {
  display: inline-block;
  width: 60px;
  height: 10px;
  margin-right: 5px;
  border-radius: 30px;
}

.red {
  background-color: #ff6861;
}

.orange {
  background-color: #ffb347;
}

.yellow {
  background-color: #fdfd96;
}

.green {
  background-color: #76dd77;
}

.grey {
  background-color: #afc6cf;
}

.rule-met {
  color: green;
}

.rule-not-met {
  color: #ff6861;
}

.rules-list {
  text-align: left;
  padding-left: 20px;
}

.agree-check {
  text-align: left;
}
</style>
