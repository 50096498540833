import { getFunctions, httpsCallable } from "firebase/functions";

async function logEvent(level, message, additionalInfo = {}) {
    const logEntry = {
      level, // e.g., 'info', 'error'
      message,
      additionalInfo, // Any additional data you want to log
      timestamp: new Date().toISOString(),
    };
  
    const functions = getFunctions();
    const logFrontendEvent = httpsCallable(functions, 'logFrontendEvent');
    try {
        const result = await logFrontendEvent(logEntry)
    } catch (error) {
        console.log(`Error deleting Session and Audio ${error}`)
    }
  }
  
  export { logEvent };
  