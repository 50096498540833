import { ref } from "vue";
import zoomSdk from "@zoom/appssdk";
const axios = require("axios");
const isZoomApp = ref(false);
const zoomVerifier = ref("");
const zoomUser = ref({
  token: "",
  createdAt: 0,
});
const zoomConfigured = ref(false);

async function configureSdk() {
  // to account for the 2 hour timeout for config
  const configTimer = setTimeout(() => {
    setCounter(counter + 1);
  }, 120 * 60 * 1000);

  const apis = [
    {
      name: "getRecordingContext",
    },
    {
      buttonName: "cloudRecording (start)",
      name: "cloudRecording",
      options: { action: "start" },
    },
    {
      buttonName: "cloudRecording (stop)",
      name: "cloudRecording",
      options: { action: "stop" },
    },
    {
      buttonName: "cloudRecording (pause)",
      name: "cloudRecording",
      options: { action: "pause" },
    },
    {
      buttonName: "cloudRecording (resume)",
      name: "cloudRecording",
      options: { action: "resume" },
    },
  ];

  try {
    // Configure the JS SDK, required to call JS APIs in the Zoom App
    // These items must be selected in the Features -> Zoom App SDK -> Add APIs tool in Marketplace
    const configResponse = await zoomSdk.config({
      capabilities: [
        // apis demoed in the buttons
        ...apis.map((api) => api.name), // IMPORTANT

        // demo events
        "onSendAppInvitation",
        "onShareApp",
        "onActiveSpeakerChange",
        "onMeeting",

        // connect api and event
        "connect",
        "onConnect",
        "postMessage",
        "onMessage",

        // in-client api and event
        "authorize",
        "onAuthorized",
        "promptAuthorize",
        "getUserContext",
        "getMeetingContext",
        "getMeetingUUID",
        "onMyUserContextChange",
        "sendAppInvitationToAllParticipants",
        "sendAppInvitation",
      ],
      version: "0.16.0",
    });
    zoomConfigured.value = true;
    console.log("App configured", configResponse);
    // The config method returns the running context of the Zoom App

    zoomSdk.onSendAppInvitation((data) => {
      console.log(data);
    });
    zoomSdk.onShareApp((data) => {
      console.log(data);
    });
    zoomSdk.onConnect((data) => {
      console.log("onconnect", data);
    });
    zoomSdk.onCloudRecording((data) => {
      console.log("onrecord", data);
    });
  } catch (error) {
    console.log(error);
    // setError("There was an error configuring the JS SDK");
  }
}

async function getZoomMeetingID() {
  const api = {
    name: "getMeetingContext",
  };
  const { name, buttonName = "", options = null } = api;
  const zoomAppsSdkApi = zoomSdk[name].bind(zoomSdk);
  try {
    const { meetingID } = await zoomAppsSdkApi(options);

    return meetingID;
  } catch (error) {
    return null;
  }
}

async function getZoomMeetingUUID() {
  const api = {
    name: "getMeetingUUID"
  };

  const { name, buttonName = "", options = null } = api;
  const zoomAppsSdkApi = zoomSdk[name].bind(zoomSdk);
  try {
    const { meetingUUID } = await zoomAppsSdkApi(options);

    return meetingUUID;
  } catch (error) {
    return null
  }

  //meetingUUID
}

const getZoomConfigured = () => {
  return { zoomConfigured };
};

const setIsZoomApp = (_value) => {
  isZoomApp.value = _value;
};

const getIsZoomApp = () => {
  return { isZoomApp };
};

const setZoomVerifier = (_value) => {
  zoomVerifier.value = _value;
};

const getZoomVerifier = () => {
  return { zoomVerifier };
};

const setZoomUser = (_value) => {
  zoomUser.value.token = _value;
  zoomUser.value.createdAt = Date.now();
};

const getZoomUser = () => {
  return { zoomUser };
};

export {
  configureSdk,
  setIsZoomApp,
  getIsZoomApp,
  setZoomVerifier,
  getZoomVerifier,
  setZoomUser,
  getZoomUser,
  getZoomMeetingID,
  getZoomConfigured,
  getZoomMeetingUUID
};
