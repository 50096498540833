<template>
    <div class="privacy-policy">
        <h2>MDHUB PROVIDER TERMS OF SERVICE</h2>
        <h6>Last Updated: May 26, 2023</h6>
        <p>
            These Provider Terms of Service (these “Terms”) of mdhub, Inc. (“mdhub,” “we,” “our,” or “us”) describe
            your rights and responsibilities when accessing and/or using our website, including <a
                href="https://www.mdhub.ai">www.mdhub.ai</a> and
            any successor URL(s), and any and all related software, documentation, and online, mobile-enabled,
            and/or digital services provided by us (collectively, the “Service”). The Service provides administrative
            and technology support to licensed health care providers in the United States that have signed up for an
            account on the Service (“Providers”), including by facilitating the transcription of patient interactions and
            the preparation of clinical notes.
        </p>
        <p>
            These Terms are a contract between you and mdhub. You must read and agree to these Terms
            before using the Service. If you do not agree, you may not use the Service.
        </p>
        <p>
            You may use the Service only if you are a licensed health care provider in the United States and can form
            a legally binding contract with us, and only in compliance with these Terms and all applicable local, state,
            national and international laws, rules and regulations (“Applicable Law”). The Service is not available to
            you if you have previously been removed from the Service by us.
        </p>
        <p>
            By accessing or using the Service, or by clicking a button or checking a box marked “I Agree” (or
            something similar), you signify that (a) you have read, understood, and agree to be bound by these
            Terms, (b) you have read, understood, and agree to be bound by the <a
                href="https://www.mdhub.ai/business-associate-agreement">Business Associate Agreement</a>,
            which is incorporated by reference into these Terms, and (c) you have read and understood our <a
                href="https://www.mdhub.ai/privacy-policy">Privacy Notice</a> (as it may be updated from time to time, our
            “Privacy Notice”). We reserve the right to modify
            these Terms and will provide notice of material changes as described below.
        </p>
        <p>
            PLEASE READ THESE TERMS CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH
            PROVISION. THESE TERMS CONTAIN A MANDATORY INDIVIDUAL ARBITRATION PROVISION IN
            SECTION 12.2 (THE “ARBITRATION AGREEMENT”) AND CLASS ACTION/JURY TRIAL WAIVER
            PROVISION IN SECTION 12.3 (THE “CLASS ACTION/JURY TRIAL WAIVER”) THAT REQUIRE,
            UNLESS YOU OPT OUT PURSUANT TO THE INSTRUCTIONS IN SECTION 12.2, THE EXCLUSIVE
            USE OF FINAL AND BINDING ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES
            BETWEEN YOU AND US, INCLUDING ANY CLAIMS THAT AROSE OR WERE ASSERTED BEFORE
            YOU AGREED TO THESE TERMS. TO THE FULLEST EXTENT PERMITTED BY LAW, YOU
            EXPRESSLY WAIVE YOUR RIGHT TO SEEK RELIEF IN A COURT OF LAW AND TO HAVE A JURY
            TRIAL ON YOUR CLAIMS, AS WELL AS YOUR RIGHT TO PARTICIPATE AS A PLAINTIFF OR CLASS
            MEMBER IN ANY CLASS, COLLECTIVE, PRIVATE ATTORNEY GENERAL OR REPRESENTATIVE
            ACTION OR PROCEEDING.
        </p>
        <h1>1. Our Service</h1>
        <h4>1.1 No Medical or Professional Advice</h4>
        <p> We do not directly or indirectly offer medical advice or other professional advice. Any information on or
            which may be discovered through the Service is intended to be general information in regard to the
            subject matter covered, and any such information should not be considered complete, nor should it be
            relied on to suggest a basis for diagnosis or course of treatment.
        </p>
        <h4>1.2 Limited License</h4>
        <p> Subject to these Terms, you are hereby granted a non-exclusive, limited, non-transferable, and freely
            revocable license to use the Service for your personal, non-commercial use only, strictly as permitted by
            the features of the Service. We may terminate this license at any time for any reason or no reason. We
            reserve all rights not expressly granted herein in the Service and the mdhub Content (as defined in
            Section 3.1 below).
        </p>
        <h4>1.3 Your Account</h4>
        <p> Your account on the Service gives you access to certain services and functionalities that we may
            establish and maintain as part of the Service from time to time, in our sole discretion. We may maintain
            different types of accounts for different types of users. If you open an account on behalf of a company,
            organization, or other entity, then (a) “you” includes you and that entity, and (b) you represent and warrant
            that you are an authorized representative of that entity with the authority to bind that entity to these Terms,
            and that you agree to these Terms on the entity’s behalf. By connecting to the Service with a third-party
            service, you give us permission to access and use your information from that service as permitted by that
            service, and to store your log-in credentials for that service.
        </p>
        <p>
            You may never use another user’s account without permission. When creating your account, you must
            provide accurate and complete information, and you must keep this information up to date. You are solely
            responsible for the activity that occurs on your account, and you must keep your account password
            secure. You must notify us immediately of any breach of security or unauthorized use of your account. We
            will not be liable for, and expressly disclaim any responsibility for, any losses caused by any unauthorized
            use of your account.
        </p>
        <h4>1.4 Consent to Electronic Communications</h4>
        <p> Unsecured email, mobile phone text messages, or other unsecure electronic methods of communication
            are not confidential means of communication and there is a possibility that a third party could intercept
            and see these messages. By providing us your email address, you acknowledge these risks and consent
            to us using that email address number to send you Service-related notices, including any notices required
            by Applicable Law, in lieu of communication by postal mail. We may also use your email address to send
            you other messages, such as messages notifying you of changes to features of the Service and special
            offers. If you do not want to receive such email messages, you may opt out of receiving such email
            messages by clicking on the “unsubscribe” link in the email message received and/or change your
            preferences in your settings page. Opting out may prevent you from receiving email messages regarding
            updates, improvements, or offers.
        </p>
        <h4>1.5 Text Messages and Calls</h4>
        <p> By providing your phone number to mdhub, you expressly consent and agree that we may contact you
            using written, electronic, and/or verbal means, including by manual dialing, leaving prerecorded/artificial
            voice messages, and/or using an automatic telephone dialing system to call or send SMS/text messages
            to your phone number as necessary to (a) help keep your account secure through the use of two-factor
            authentication; (b) help you access your account when you’ve forgotten your password; and (c) as
            otherwise necessary to service your account, complete transactions requested by you, or enforce these
            Terms, our policies, Applicable Law, or any other agreement we may have with you, even if the provided
            phone number is registered on any federal or state Do-Not-Call registry. For purposes of clarity, the
            communications described in this paragraph are transactional in nature, and not promotional.
        </p>
        <p>
            Additionally, we offer you the chance to enroll to receive recurring SMS/text messages from us about
            account-related news and alerts and/or marketing and promotional offers for mdhub products and
            services (collectively, “Promotional Text Messages”). By enrolling, you agree to receive Promotional
            Text Messages from us to the phone number you provide and that such Promotional Text Messages may
            be sent using an automatic telephone dialing system.
        </p>
        <p>
            You may opt-out from Promotional Text Messages at any time. To unsubscribe from Promotional
            Text Messages, text STOP, QUIT, END, CANCEL, or UNSUBSCRIBE to +16289467255 or reply
            STOP, QUIT, END, CANCEL, or UNSUBSCRIBE to any Promotional Text Message you receive from
            us. You consent that following such a request to unsubscribe, you may receive one final text
            message from us confirming your request. For help, text HELP to +16289467255 or contact us at
            legal@mdhub.ai.
        </p>
        <p>
            You certify that any phone number(s) you provide to us are true and accurate and that you are authorized
            to enroll such phone number(s) to receive calls and/or SMS/text messages as described herein. Message
            and data rates apply. Consent is not required as a condition of purchase. We are not responsible for any
            delays upon sending or receiving text messages.
        </p>
        <h4>1.6 Service Rules; Use Restrictions</h4>
        <p>
            You agree not to engage in any of the following prohibited activities: (a) providing any false or fraudulent
            information or documentation or providing information or documentation for any other improper purpose,
            whether for yourself, your business, or a third party; (b) impersonating another person or otherwise
            misrepresenting your affiliation with a person or entity, conducting fraud, or hiding or attempting to hide
            your identity; (c) copying, distributing, making available, providing access to, or disclosing any part of the
            Service in any medium, including, without limitation, by any automated or non-automated “scraping”; (d)
            using any automated system, including, without limitation, “robots,” “spiders,” “offline readers,” etc., to
            access the Service in a manner that sends more request messages to the servers running the Service
            than a human can reasonably produce in the same period of time by using a conventional online web
            browser (except that we grant the operators of public search engines revocable permission to use spiders
            to copy publicly available materials from the Service for the sole purpose of, and solely to the extent
            necessary for, creating publicly available searchable indices of the materials, but not caches or archives
            of such materials); (e) copying, modifying, adapting, translating, preparing derivative works from, reverse
            engineering, disassembling, or decompiling the Service or otherwise attempting to discover any
            proprietary source code or trade secrets related to the Service; (f) using the trademarks, trade names,
            service marks, logos, domain names and other distinctive brand features or any copyright or other
            proprietary rights associated with mdhub or the Service for any purpose without our express written
            consent; (g) transmitting spam, chain letters, or other unsolicited email; (h) attempting to interfere with,
            compromise the system integrity or security of, or decipher any transmissions to or from the servers
            running the Service; (i) taking any action that imposes, or may impose (as determined by us, in our sole
            discretion), an unreasonable or disproportionately large load on our infrastructure; (j) uploading invalid
            data, viruses, worms, or other software agents through the Service; (k) collecting or harvesting any
            personal information from the Service; (l) interfering with the proper working of the Service; or (m)
            bypassing the measures we may use to prevent or restrict access to the Service, including, without
            limitation, features that prevent or restrict use or copying of any content or enforce limitations on the use
            of the Service or the content therein.
        </p>
        <h4>1.7 Changes to the Service</h4>
        <p>
            We may, with or without prior notice, change the Service; stop providing the Service or features of the
            Service, to you or to users generally; or create usage limits for the Service. We may permanently or
            temporarily terminate or suspend your access to your account and/or the Service with or without notice
            and without liability, for any reason, including if, in our sole determination, you violate any provision of
            these Terms, or for no reason. Upon their termination for any reason or no reason, you continue to be
            bound by these Terms.
        </p>

        <h1>2. Your Content</h1>
        <p>
            As between us and you, you (or your licensors) will own any and all information, data, and other content,
            in any form or medium, that is collected, downloaded, or otherwise received, directly or indirectly, from
            you (or on your behalf) by or through the Service (“Your Content”).
        </p>
        <p>
            We have the right (but not the obligation) in our sole discretion to remove any of Your Content that is
            shared via the Service.
        </p>
        <p>
            You hereby grant, and you represent and warrant that you have all rights necessary to grant, to us an
            irrevocable, perpetual, transferable, sublicensable (through multiple tiers), fully paid, royalty-free, and
            worldwide right and license to use, copy, store, modify, distribute and display Your Content: (a) to maintain
            and provide the Service to you; (b) solely in de-identified form, to improve our products and services and
            for our other business purposes, such as data analysis, customer research, developing new products or
            features, and identifying usage trends (and we will own such de-identified data); and (c) to perform such
            other actions as described in our Privacy Notice or as authorized by you in connection with your use of
            the Service.
        </p>
        <p>
            In connection with Your Content, you affirm, represent and warrant the following:
            <li> you have the written consent of each and every identifiable natural person referred to or
                mentioned in Your Content, if any, and each such person has released you from any liability that
                may arise in relation to such use;</li>
            <li> you have obtained and are solely responsible for obtaining all consents as may be required by
                Applicable Law to provide any of Your Content that relates to third parties;</li>
            <li> Your Content and mdhub’s use thereof as contemplated by these Terms and the Service will not
                violate any Applicable Law or infringe any rights of any third party, including, but not limited to,
                any intellectual property rights and privacy rights; and</li>
            <li> to the best of your knowledge, all of Your Content and all other information that you provide to us
                is truthful and accurate.</li>
        </p>


        <p>
            WE TAKE NO RESPONSIBILITY AND ASSUME NO LIABILITY FOR ANY OF YOUR CONTENT.
            YOU SHALL BE SOLELY RESPONSIBLE FOR YOUR CONTENT AND THE CONSEQUENCES OF
            POSTING, PUBLISHING IT, SHARING IT, OR OTHERWISE MAKING IT AVAILABLE ON THE
            SERVICE. YOU UNDERSTAND AND AGREE THAT ON THE SERVICE YOU MAY BE EXPOSED
            TO CONTENT THAT IS INACCURATE, OBJECTIONABLE, INAPPROPRIATE, OR OTHERWISE
            UNSUITED TO YOUR PURPOSE, AND YOU AGREE THAT WE SHALL NOT BE LIABLE FOR ANY
            DAMAGES YOU ALLEGE TO INCUR AS A RESULT OF OR RELATING TO ANY CONTENT
            ACCESSED ON OR THROUGH THE SERVICE.
        </p>
        <h1>3. Our Proprietary Rights</h1>
        <h4>3.1 mdhub Content.</h4>
        <p>
            Except for Your Content, the Service and all materials therein or
            transferred thereby, including, without limitation, any software, images, text, graphics, illustrations, logos,
            patents, trademarks, service marks, copyrights, photographs, audio, videos, music (the “mdhub
            Content”), and all intellectual property rights related thereto, are the exclusive property of mdhub and its
            licensors, providers, distributors, and suppliers (collectively, “Suppliers”). Except as explicitly provided
            herein, nothing in these Terms will be deemed to create a license in or under any such intellectual
            property rights, and you will not sell, license, rent, modify, distribute, copy, reproduce, transmit, publicly
            display, publicly perform, publish, adapt, edit, or create derivative works of any mdhub Content. Use of the
            mdhub Content for any purpose not expressly permitted by these Terms is strictly prohibited.

        </p>
        <h4>3.2 Usage Data.</h4>
        <p>
            We may collect, maintain, process, and use, or you may provide to us,
            diagnostic, technical, usage, and related information, including information about your computers, mobile
            devices, systems, and software (collectively, “Usage Data”). You agree that all Usage Data is owned
            solely and exclusively by us, and, to the extent any ownership rights in or to the Usage Data vest in you,
            you hereby assign to us all rights, title, and interest in and to the same. Accordingly, we may use the
            Usage Data or any portion thereof for any lawful purpose, including, without limitation: (a) to provide and
            maintain the Service; (b) to develop and improve the Service; (c) to monitor your usage of the Service; (d)
            for research and analytics and for our other business purposes; and (e) to share analytics and other
            derived Usage Data with third parties, solely in deidentified or aggregated form. The Service may contain
            technological measures designed to prevent unauthorized or illegal use of the Service. You acknowledge
            and agree that we may use these and other lawful measures to verify your compliance with the terms of
            these Terms and to enforce our rights, including all intellectual property rights, in and to the Service.

        </p>
        <h4>3.3 Feedback.</h4>
        <p>
            To the extent you provide any suggestions, recommendations, or other
            feedback relating to the Service or any other mdhub products or services, (collectively, “Feedback”), such
            Feedback is non-confidential, and you hereby grant, and you represent and warrant that you have all
            rights necessary to grant, to us a non-exclusive, perpetual, irrevocable, transferable, royalty-free, and
            worldwide license, with the right to grant and authorize sublicenses, to implement, use, modify, and
            otherwise exploit, in any way without restriction, the Feedback, without any fees, attribution, or other
            obligations to you or any third party.
        </p>

        <h1>4. Payments, Billing and Subscription Plans.</h1>
        <h4>4.1 How We Bill for Our Service.</h4>
        <p>
            Certain aspects of the Service may be provided for free, while certain other aspects of the Service may be
            provided for a fee or other charge. If you elect to use paid aspects of the Service, you agree to our
            payment terms set forth herein and as made available to you through the Service, as we may update
            them from time to time (“Pricing and Payment Terms”). We may add new services for additional
            fees and charges, add or amend fees and charges for existing services, at any time in our sole
            discretion.
        </p>
        <h4>4.2 Billing and Cancellation.</h4>
        <p>
            To use the Service, you may be required to provide one or more current, valid and accepted payment
            methods, as may be updated from time to time and which may include payment through your account
            with a third party (such method, a “Payment Method”). By providing a Payment Method, you agree
            that we or our Payment Processor (defined below) are authorized to periodically charge the
            applicable fees in addition to any applicable taxes and service fees on a recurring basis until you
            cancel your subscription or other such recurring fees. The fees for the Service and any other charges
            you may incur in connection with your use of the Service, such as Taxes and possible transaction
            fees, will be charged to your Payment Method on the specific payment date indicated on your
            account. The length of your billing cycle will depend on the type of subscription that you choose when
            you signed up for the Service. Fees are fully earned upon payment. In some cases, your payment
            date may change, for example, if your Payment Method has not successfully settled, when you
            change your subscription plan or if your paid subscription began on a date not contained in a given
            month. We may authorize your Payment Method in anticipation of service-related charges through
            various methods, including authorizing it up to one month of service as soon as you register.
        </p>
        <h4>4.3 Payment Information.</h4>
        <p>
            We use a third-party payment processor (“Payment Processor”) to process payments you make in
            connection with the Service. We or our Payment Processor will attempt to verify the Payment Method
            you provide, and may do so by processing an authorization hold, which is standard practice. Please
            see Payment Processor’s privacy statement available on its website for information on how our
            Payment Processor collects and uses personal information. You acknowledge and agree that we are
            not responsible for any breaches of credit card or other Payment Method security or privacy. Our
            Payment Processor is currently Stripe, and by using the Service you agree to be bound by Stripe’s
            Services Agreement, available at <a href="https://stripe.com/us/legal">https://stripe.com/us/legal</a> .
        </p>
        <p>
            You represent and warrant that: (a) the account, order, and Payment Method information you supply to us
            or our Payment Processor, as applicable, is true, correct, and complete; (b) you are duly authorized to
            use such Payment Method; (c) you will pay any charges that you incur in connection with the Service,
            including any applicable taxes; (d) charges incurred by you will be honored by your Payment Method
            company; (e) you will pay all charges incurred by you at the posted prices, including all applicable
            taxes, if any; (f) you will not allow anyone else to use your subscription; (g) you will not transfer your
            subscription or password to anyone else; and (h) you will report to us any unauthorized or prohibited
            access or use of your subscription or password.
        </p>
        <p>
            If any of your account, order, or Payment Method information changes, you agree to promptly update this
            information, so that we or Payment Processor may complete your transactions and contact you as
            needed. We are not liable for any unauthorized use of your credit card, debit card, or other Payment
            Method by a third-party in connection with your use of the Service or your subscription.
        </p>
        <h4>4.4 Subscription Plans.</h4>
        <h4>(a) Subscription Products; Subscription Fees.</h4>
        <p>
            We may make certain portions of
            the Service available on an automatically renewing subscription basis (the “Subscription Services”) for
            recurring fees (“Subscription Fees”). For the most current information about our Subscription Fees,
            please review our Pricing and Payment Terms, which are incorporated by reference herein. We may
            make the Service available on a subscription basis, discontinue subscriptions for Subscription Services,
            or add or amend the Subscription Fees at our sole discretion. When we add or amend the Subscription
            Fees, we will update our Pricing and Payment Terms. Any change to our Pricing and Payment Terms shall
            become effective in the Subscription Term (as defined below) following notice of such change to you as
            provided in these Terms; provided however, that if we have offered a specific duration and Subscription
            Fees for your use of the Subscription Services, we agree that the Subscription Fees will remain in force
            for that duration. YOUR SUBSCRIPTION TO THE SUBSCRIPTION SERVICES WILL AUTOMATICALLY
            RENEW AT THE END OF THE SUBSCRIPTION TERM IDENTIFIED IN YOUR ORDER FOR
            SUBSEQUENT TERMS EQUAL IN LENGTH TO THE INITIAL SUBSCRIPTION TERM (EACH A

            “SUBSCRIPTION TERM”) UNLESS AND UNTIL YOU CANCEL THE SUBSCRIPTION SERVICES IN
            ACCORDANCE WITH THE CANCELLATION PROCEDURES IDENTIFIED BELOW.
        </p>
        <h4>(b) Automatic Billing and Policies.</h4>
        <p>
            When you purchase Subscription Services, you
            expressly acknowledge and agree that: (a) we and/or our Payment Processor are authorized to charge
            you at the beginning of each Subscription Term the Subscription Fees, any applicable taxes, and any
            other charges you may incur in connection with your purchase and use of the Subscription Services,
            subject to adjustment in accordance with these Terms, for as long as your subscription continues; and (b)
            your subscription is continuous until you cancel it or the Subscription Services or your subscription to the
            Subscription Services is suspended, discontinued or terminated in accordance with these Terms. You
            acknowledge and agree that the amount billed may vary due to promotional offers, changes in the
            Subscription Fees for Subscription Services as described above, and changes in applicable taxes, and
            you authorize us to charge your Payment Method for the changed amounts.
        </p>
        <h4>(c) Cancellation Procedure.</h4>
        <p>
            To cancel your subscription to the Subscription
            Services, you must notify us at least three (3) days before the start of the next Subscription Term using
            the appropriate functionalities of the Service or by contacting us at receipts@mdhub.ai. You will continue
            to have access to the Subscription Services through the end of your billing period. YOU UNDERSTAND
            THAT UNLESS AND UNTIL YOU NOTIFY US OF YOUR CANCELLATION, YOUR SUBSCRIPTION AND
            ALL CORRESPONDING FEES WILL AUTOMATICALLY RENEW, AND YOU AUTHORIZE US OR OUR
            PAYMENT PROCESSOR (WITHOUT NOTICE TO YOU, UNLESS REQUIRED BY APPLICABLE LAW)
            TO CHARGE YOU THE APPLICABLE SUBSCRIPTION FEES AND ANY TAXES, USING ANY ELIGIBLE
            PAYMENT METHOD WE HAVE ON RECORD FOR YOU.
        </p>
        <h4>(d) Cancellation and Missed Payments Penalty.</h4>
        <p>
            You may cancel your subscription
            to the Subscription Services at your sole discretion; however, we grant refunds for cancellation at our sole
            discretion and you acknowledge and agree that you may not be refunded any Subscription Fees for your
            cancellation of your subscription to the Subscription Services, including without limitation, for any unused
            time with your Subscription Services. IN THE EVENT THAT: (I) WE SUSPEND OR TERMINATE YOUR
            SUBSCRIPTION TO THE SUBSCRIPTION SERVICES OR YOUR USER ACCOUNT FOR YOUR
            BREACH OF THESE TERMS; OR (II) YOU CANCEL YOUR SUBSCRIPTION TO THE SUBSCRIPTION
            SERVICES, YOU UNDERSTAND AND AGREE THAT YOU SHALL RECEIVE NO REFUND FOR ANY
            UNUSED TIME ON YOUR SUBSCRIPTION OR ANY PRE-PAYMENTS MADE FOR THE
            SUBSCRIPTION SERVICES.
        </p>
        <h4>(e) Refunds.</h4>
        <p>
            EXCEPT AS OTHERWISE EXPRESSLY SET FORTH IN THESE TERMS, UNLESS REQUIRED BY
            APPLICABLE LAW, WE DO NOT PROVIDE REFUNDS FOR ANY SUBSCRIPTION; NOR DO WE
            PROVIDE CREDIT, REFUNDS, OR PRORATED BILLING FOR SUBSCRIPTIONS THAT ARE
            CANCELLED.
        </p>
        <h4>4.5 Promotional Offers.</h4>
        <p>
            We may from time to time offer special promotional offers, plans or memberships (“Promotional Offers”).
            Promotional Offer eligibility is determined by us in our sole discretion and we reserve the right to
            revoke a Promotional Offer and put your account on hold in the event that we determine you are not
            eligible. We may use information such as device ID, method of payment or an account email address
            used with an existing or recent subscription to determine eligibility. The eligibility requirements and
            other limitations and conditions will be disclosed when you sign-up for the Promotional Offer or in
            other communications made available to you. You acknowledge and agree that any offers made
            available through the Service, including without limitation offers for the Subscription Services, are
            subject to change at any time and from time to time.
        </p>
        <h1>5. Privacy.</h1>
        <p>
            We care about the privacy of our users. By using the Service, you understand and acknowledge that your
            personal information will be collected, used, and disclosed as set forth in our <a
                href="https://www.mdhub.ai/privacy-policy">Privacy Notice</a>.
        </p>
        <h1>6. Security.</h1>
        <p>
            We care about the integrity and security of your personal information. However, we cannot guarantee that
            unauthorized third parties will never be able to defeat our security measures or use your personal
            information for improper purposes. You acknowledge that you provide your personal information at your
            own risk.
        </p>
        <h1>7. Provider Responsibilities.</h1>
        <p>
            If Provider chooses to access and use the Service, Provider does so on its own initiative and is solely
            responsible for compliance with all applicable laws, including but not limited to monitoring, recording and
            wiretapping laws. Provider is solely responsible for advising all participants in a recorded conversation
            that the conversation is being recorded and obtaining their consent, as required by applicable law.
        </p>
        <h1>8. Third-Party Links, Information, and Services.</h1>
        <p>
            The Service may contain links to third-party materials and services that are not owned or controlled by
            mdhub. Further, to use the Service, you may be required to register for third-party services and/or to
            otherwise enable various third-party services that may directly integrate with the Service (collectively,
            “Third-Party Services”). By enabling a Third-Party Service within the Service, you allow mdhub to pass
            your log-in credentials and/or other information (e.g., tokenized authentication information) to such
            Third-Party Service. mdhub does not endorse or assume any responsibility for any third-party sites, nor
            for any information, materials, products, or services (including, without limitation, Third-Party Services)
            available on or through third-party sites. If you access a third-party website or service (including, without
            limitation, a Third-Party Service) from the Service or share Your Content on or through any third-party
            website or service (including, without limitation, a Third-Party Service), you do so at your own risk, and
            you understand that these Terms and our Privacy Notice do not apply to your access to or use of such
            sites or services. You acknowledge that a Third-Party Service may revoke your right to access and/or
            authenticate to such Third-Party Service at any time. As such, mdhub is not liable for any damage or loss
            caused or alleged to be caused by or in connection with your use of or reliance on any Third-Party
            Service. mdhub may enable Third-Party Services merely as a convenience to users, and the inclusion of
            Third-Party Services does not imply an endorsement or recommendation of them. You expressly relieve
            mdhub from any and all liability arising from your access to and/or use of any third-party website, service,
            or content, including, without limitation, content that may be submitted by other users.
        </p>
        <p>
            Additionally, your dealings with or participation in promotions of advertisers that may be found on the
            Service, including payment and delivery of goods, and any other terms (such as warranties) are solely
            between you and any such advertisers or similar parties. You agree that mdhub will not be responsible for
            any loss or damage of any sort relating to your dealings with any such advertisers or similar parties.
        </p>
        <h1>9. Cooperation.</h1>
        <p>
            If requested, you agree to diligently assist mdhub in responding to requests and inquiries with respect to
            your account, Your Content, and/or material or transactions associated with you or your account,
            including, without limitation, by providing us with all information and assistance we may reasonably
            require, and/or responding promptly and accurately to any such requests and inquiries, should we
            connect between you and the inquirer.
        </p>
        <h1>10. Release and Indemnity.</h1>
        <p>
            You hereby release mdhub from all damages (whether direct, indirect, incidental, consequential, or
            otherwise), losses, liabilities, costs, and expenses of every kind and nature, known and unknown, arising
            out of a dispute between you and a third-party (including any other user) in connection with the Service. In
            addition, you waive any applicable law or statute, which says, in substance: “A GENERAL RELEASE
            DOES NOT EXTEND TO CLAIMS WHICH THE RELEASING PARTY DOES NOT KNOW OR SUSPECT
            TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN
            BY HIM OR HER WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE
            RELEASED PARTY.”
        </p>
        <p>
            You agree to defend, indemnify, and hold harmless mdhub and its Suppliers, employees, contractors,
            agents, attorneys, accountants, officers, and directors from and against any and all claims, damages,

            obligations, losses, liabilities, costs, debts, and expenses (including, but not limited to, attorneys’ fees)
            arising from: (a) your access to and use of the Service, including any data or content transmitted or
            received by you; (b) your violation of any term of these Terms, including, without limitation, your breach of
            any of the representations and warranties herein contained; (c) your violation of any third-party right,
            including, without limitation, any right of privacy or intellectual property right; (d) your violation of any
            Applicable Law; (e) Your Content or any other content that is submitted via your account, including,
            without limitation, misleading, false, or inaccurate information; (f) your willful misconduct; or (g) any other
            party’s access to and/or use of the Service with your credentials. mdhub reserves the right, in its sole
            discretion and at its expense, to assume the exclusive defense and control of any such claims, in which
            case, you agree to cooperate as reasonably requested by mdhub in the defense of such claims.
        </p>
        <h1>11. No Warranty.</h1>
        <p>
            THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. USE OF THE SERVICE IS AT YOUR OWN RISK. TO THE
            MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICE, THE MDHUB CONTENT, AND ANY OTHER INFORMATION
            AVAILABLE ON OR THROUGH THE SERVICE ARE PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
            INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
            NON-INFRINGEMENT. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM MDHUB OR
            THROUGH THE SERVICE WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN. WITHOUT LIMITING THE GENERALITY OF
            THE FOREGOING, MDHUB AND ITS SUPPLIERS DO NOT WARRANT THAT THE MDHUB CONTENT OR ANY OTHER INFORMATION
            CONTAINED IN THE SERVICE ARE ACCURATE, COMPREHENSIVE, RELIABLE, USEFUL, OR CORRECT; THAT THE SERVICE WILL
            MEET YOUR REQUIREMENTS OR SATISFY OR ENSURE COMPLIANCE WITH ANY LEGAL OBLIGATIONS OR APPLICABLE LAW; THAT
            THE SERVICE WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, UNINTERRUPTED, OR SECURE; THAT ANY DEFECTS
            OR ERRORS IN THE SERVICE WILL BE CORRECTED; OR THAT THE SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL
            COMPONENTS. ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DOWNLOADED
            AT YOUR OWN RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM(S) OR MOBILE
            DEVICE(S) OR FOR LOSS OF DATA THAT RESULTS FROM SUCH DOWNLOAD OR FROM YOUR USE OF THE SERVICE.
            MDHUB IS NOT RESPONSIBLE FOR ANY DECISIONS, NOR FOR ANY DAMAGES OR OTHER LOSSES RESULTING FROM THE RELIANCE
            ON OR USE OF INFORMATION PROVIDED BY MDHUB AND/OR THROUGH THE SERVICE. FURTHER, MDHUB DOES NOT WARRANT,
            ENDORSE, GUARANTEE, RECOMMEND, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED
            BY ANY THIRD PARTY THROUGH THE SERVICE OR ANY HYPERLINKED WEBSITE OR SERVICE, AND MDHUB WILL NOT BE A PARTY
            TO, OR IN ANY WAY MONITOR, ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS AND SERVICES.
            FEDERAL LAW AND SOME STATES, PROVINCES, AND OTHER JURISDICTIONS DO NOT ALLOW THE EXCLUSION AND LIMITATIONS OF
            CERTAIN IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU. THESE TERMS GIVE YOU SPECIFIC LEGAL
            RIGHTS, AND YOU MAY ALSO HAVE OTHER RIGHTS, WHICH VARY FROM JURISDICTION TO JURISDICTION. THE DISCLAIMERS AND
            EXCLUSIONS UNDER THESE TERMS WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
        </p>
        <h1>12. Limitation of Liability.</h1>
        <p>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL MDHUB OR ITS SUPPLIERS, EMPLOYEES,
            CONTRACTORS, AGENTS, ATTORNEYS, ACCOUNTANTS, OFFICERS, OR DIRECTORS BE LIABLE FOR ANY INDIRECT, PUNITIVE,
            INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF
            PROFITS, GOODWILL, USE, OR DATA, OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR RELATING TO THE USE OF, OR
            INABILITY TO USE, THE SERVICE, AND/OR TO THE MDHUB CONTENT OR ANY OTHER INFORMATION CONTAINED IN THE SERVICE.
            UNDER NO CIRCUMSTANCES WILL MDHUB BE RESPONSIBLE FOR ANY DAMAGE, LOSS, OR INJURY RESULTING FROM HACKING,
            TAMPERING, OR OTHER UNAUTHORIZED ACCESS TO OR USE OF THE SERVICE OR YOUR ACCOUNT OR THE INFORMATION
            CONTAINED THEREIN.
        </p>
        <p>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, MDHUB ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (A)
            ERRORS, MISTAKES, OR INACCURACIES OF CONTENT; (B) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
            WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR USE OF THE SERVICE; (C) ANY UNAUTHORIZED ACCESS TO OR USE OF
            THE SERVERS RUNNING THE SERVICE AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN; (D) ANY INTERRUPTION
            OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICE; (E) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT
            MAY BE TRANSMITTED TO OR THROUGH THE SERVICE BY ANY THIRD PARTY; (F) ANY ERRORS OR OMISSIONS IN ANY MDHUB
            CONTENT, OR ANY LOSS OR DAMAGE INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED,
            OR OTHERWISE MADE AVAILABLE THROUGH THE SERVICE; AND/OR (G) USER CONTENT OR THE DEFAMATORY, OFFENSIVE, OR
            ILLEGAL CONDUCT OF ANY THIRD PARTY. IN NO EVENT WILL MDHUB OR ITS SUPPLIERS, EMPLOYEES, CONTRACTORS,

            ATTORNEYS, ACCOUNTANTS, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE TO YOU FOR ANY CLAIMS, PROCEEDINGS,
            LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES, OR COSTS IN AN AMOUNT EXCEEDING THE AMOUNT YOU PAID TO MDHUB
            HEREUNDER DURING THE TWELVE (12) MONTHS PRECEDING THE DATE ON WHICH THE LIABILITY AROSE OR $50.00,
            WHICHEVER IS GREATER.
        </p>
        <p>
            THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT,
            NEGLIGENCE,STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF MDHUB HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGE.
        </p>
        <p>
            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE
            ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. THESE TERMS GIVE YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY
            ALSO HAVE OTHER RIGHTS, WHICH VARY FROM JURISDICTION TO JURISDICTION. THE DISCLAIMERS, EXCLUSIONS, AND
            LIMITATIONS OF LIABILITY UNDER THESE TERMS WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
        </p>
        <h1>13. Governing Law, Arbitration, and Class Action/Jury Trial Waiver.</h1>
        <h4>13.1 Governing Law.</h4>
        <p>
            These Terms will be governed by the internal substantive laws of the
            State of Delaware, without respect to its conflict of laws principles. The parties acknowledge that these
            Terms evidence a transaction involving interstate commerce. Notwithstanding the preceding sentences
            with respect to the substantive law governing these Terms, the Federal Arbitration Act (9 U.S.C. §§ 1-16)
            (“FAA”) governs the interpretation and enforcement of the Arbitration Agreement below and preempts all
            state laws (and laws of other jurisdictions) to the fullest extent permitted by Applicable Law. If the FAA is
            found to not apply to any issue that arises from or relates to the Arbitration Agreement, then that issue will
            be resolved under and governed by the law of the U.S. state where you reside or the jurisdiction mutually
            agreed upon in writing by you and us. The application of the United Nations Convention on Contracts for
            the International Sale of Goods is expressly excluded. You agree to submit to the exclusive personal
            jurisdiction of the federal and state courts located in Delaware for any actions for which we retain the right
            to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or
            threatened infringement, misappropriation, or violation of our intellectual property rights or other
            proprietary rights, as set forth in the Arbitration Agreement below, including any provisional relief required
            to prevent irreparable harm. You agree that Delaware is the proper and exclusive forum for any appeals
            of an arbitration award or for trial court proceedings in the event that the Arbitration Agreement below is
            found to be unenforceable.
        </p>
        <h4>13.2 Arbitration.</h4>
        <p>
            READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES THE
            PARTIES TO ARBITRATE THEIR DISPUTES AND LIMITS THE MANNER IN WHICH YOU CAN SEEK
            RELIEF FROM US. This Arbitration Agreement applies to and governs any dispute, controversy, or claim
            between you and mdhub that arises out of or relates to, directly or indirectly: (a) these Terms, including
            the formation, existence, breach, termination, enforcement, interpretation, validity, or enforceability
            thereof; (b) access to or use of the Service, including receipt of any advertising or marketing
            communications; (c) any transactions through, by, or using the Service; or (d) any other aspect of your
            relationship or transactions with mdhub, directly or indirectly, as a consumer (each, a “Claim,” and,
            collectively, “Claims”). This Arbitration Agreement will apply, without limitation, to all Claims that arose or
            were asserted before or after your agreement to these Terms.
        </p>
        <p>
            If you are a new user, you can reject and opt-out of this Arbitration Agreement within thirty (30) days of
            accepting these Terms by emailing mdhub at legal@mdhub.ai with your full legal name and stating your
            intent to opt-out of this Arbitration Agreement. Note that opting out of this Arbitration Agreement does not
            affect any other part of these Terms, including the provisions regarding controlling law or the courts in
            which any disputes must be brought.
        </p>
        <p>
            For any Claim, you agree to first contact us at legal@mdhub.ai and to attempt to resolve the Claim with
            us informally. In the unlikely event that we have not been able to resolve a Claim after sixty (60) days, we
            each agree to resolve such Claim exclusively through binding arbitration by the American Arbitration
            Association (“AAA”) before a single arbitrator (the “Arbitrator”), under the Expedited Procedures then in
            effect for AAA (the “Rules”), except as provided herein. In the event of any conflict between the Rules
            and this Arbitration Agreement, this Arbitration Agreement will control. AAA may be contacted at
            www.adr.org, where the Rules are also available. The arbitration will be conducted in the U.S. county
            where you reside or Sussex County, Delaware, unless you and mdhub agree otherwise. You
            acknowledge and understand that, depending on the kind of user you are, either (i) each of you and us

            will be responsible for paying any AAA filing and administrative and Arbitrator fees in accordance with the
            Rules, and the award rendered by the Arbitrator will include costs of arbitration, reasonable attorneys’
            fees, and reasonable costs for expert and other witnesses, or (ii) AAA may require you to pay a fee for the
            initiation of your case, unless you apply for and successfully obtain a fee waiver from AAA; the award
            rendered by the Arbitrator may include your costs of arbitration, your reasonable attorneys’ fees, and your
            reasonable costs for expert and other witnesses; and you may sue in a small claims court of competent
            jurisdiction without first engaging in arbitration, but this would not absolve you of your commitment to
            engage in the informal dispute resolution process. Any judgment on the award rendered by the Arbitrator
            may be entered in any court of competent jurisdiction. You and mdhub agree that the Arbitrator, and not
            any federal, state, or local court or agency, will have exclusive authority to resolve any disputes relating to
            the scope, interpretation, applicability, enforceability, or formation of this Arbitration Agreement, including
            any claim that all or any part of this Arbitration Agreement is void or voidable. The Arbitrator will also be
            responsible for determining all threshold arbitrability issues, including issues relating to whether these
            Terms are, or any provision of these Terms is, unconscionable or illusory, and any defense to arbitration,
            including waiver, delay, laches, unconscionability, and/or estoppel.
        </p>
        <p>
            Nothing in this Arbitration Agreement will be deemed as: preventing mdhub from seeking injunctive or
            other equitable relief from the courts as necessary to prevent the actual or threatened infringement,
            misappropriation, or violation of its data security, intellectual property rights, or other proprietary rights;
            or preventing you from asserting claims in small claims court, provided that your claims qualify, and so long
            as the matter remains in such court and advances on only an individual (non-class, non-representative)
            basis.
        </p>
        <p>
            If this Arbitration Agreement is found to be void, unenforceable, or unlawful, in whole or in part, the void,
            unenforceable, or unlawful provision, in whole or in part, will be severed. Severance of the void,
            unenforceable, or unlawful provision, in whole or in part, will have no impact on the remaining provisions
            of this Arbitration Agreement, which will remain in force, or on the parties’ ability to compel arbitration of
            any remaining Claims on an individual basis pursuant to this Arbitration Agreement. Notwithstanding the
            foregoing, if the Class Action/Jury Trial Waiver below is found to be void, unenforceable, or unlawful, in
            whole or in part, because it would prevent you from seeking public injunctive relief, then any dispute
            regarding the entitlement to such relief (and only that relief) must be severed from arbitration and may be
            litigated in a civil court of competent jurisdiction. All other claims for relief subject to arbitration under
            this
            Arbitration Agreement will be arbitrated under its terms, and the parties agree that litigation of any dispute
            regarding the entitlement to public injunctive relief will be stayed pending the outcome of any individual
            claims in arbitration.
        </p>

        <h4>13.3 Class Action/Jury Trial Waiver.</h4>
        <p>
            REGARDLESS OF THE TYPE OF USER YOU ARE,
            INCLUDING WHETHER YOU ARE A NATURAL PERSON OR AN ENTITY, ANY CLAIM MUST BE
            BROUGHT IN THE YOUR INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER
            IN ANY PURPORTED CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL
            ACTION, OR OTHER REPRESENTATIVE PROCEEDING. THIS CLASS ACTION/JURY TRIAL WAIVER
            APPLIES TO CLASS ARBITRATION, AND, UNLESS WE AGREE OTHERWISE, THE ARBITRATOR
            MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS. YOU AND MDHUB AGREE THAT
            THE ARBITRATOR MAY AWARD RELIEF ONLY TO AN INDIVIDUAL CLAIMANT AND ONLY TO THE
            EXTENT NECESSARY TO PROVIDE RELIEF ON YOUR INDIVIDUAL CLAIM(S). ANY RELIEF
            AWARDED MAY NOT AFFECT OTHER USERS. YOU AND MDHUB FURTHER AGREE THAT, BY
            ENTERING INTO THESE TERMS, YOU AND MDHUB ARE EACH WAIVING THE RIGHT TO A TRIAL
            BY JURY OR TO BRING, JOIN, OR PARTICIPATE IN A CLASS ACTION, COLLECTIVE ACTION,
            PRIVATE ATTORNEY GENERAL ACTION, OR OTHER REPRESENTATIVE PROCEEDING OF ANY
            KIND AS A PLAINTIFF OR CLASS MEMBER.
        </p>
        <h1>14. Additional Terms for Mobile Applications.</h1>
        <h1>15. Mobile Applications.</h1>
        <p>
            We may make available software to access the Service via a mobile
            device (“Mobile Applications”). To use any Mobile Applications you must have a mobile device that is
            compatible with the Mobile Applications. mdhub does not warrant that the Mobile Applications will be
            compatible with your mobile device. You may use mobile data in connection with the Mobile Applications
            and may incur additional charges from your wireless provider for these services. You agree that you are

            solely responsible for any such charges. mdhub hereby grants you a non-exclusive, non-transferable,
            revocable license to use a compiled code copy of the Mobile Applications for one mdhub account on one
            mobile device owned or leased solely by you, for your personal use. You may not: (a) modify,
            disassemble, decompile or reverse engineer the Mobile Applications, except to the extent that such
            restriction is expressly prohibited by law; (b) rent, lease, loan, resell, sublicense, distribute or otherwise
            transfer the Mobile Applications to any third party or use the Mobile Applications to provide time sharing
            or similar services for any third party; (c) make any copies of the Mobile Applications; (d) remove,
            circumvent, disable, damage or otherwise interfere with security-related features of the Mobile
            Applications, features that prevent or restrict use or copying of any content accessible through the Mobile
            Applications, or features that enforce limitations on use of the Mobile Applications; or (e) delete the
            copyright and other proprietary rights notices on the Mobile Applications. You acknowledge that mdhub
            may from time to time issue upgraded versions of the Mobile Applications, and may automatically
            electronically upgrade the version of the Mobile Applications that you are using on your mobile device.
            You consent to such automatic upgrading on your mobile device, and agree that the terms and conditions
            of this Agreement will apply to all such upgrades. Any third-party code that may be incorporated in the
            Mobile Applications is covered by the applicable open source or third-party license EULA, if any,
            authorizing use of such code. The foregoing license grant is not a sale of the Mobile Applications or any
            copy thereof, and mdhub or its third-party partners or suppliers retain all right, title, and interest in the
            Mobile Applications (and any copy thereof). Any attempt by you to transfer any of the rights, duties or
            obligations hereunder, except as expressly provided for in this Agreement, is void. mdhub reserves all
            rights not expressly granted under this Agreement. If the Mobile Applications are being acquired on behalf
            of the United States Government, then the following provision applies. The Mobile Applications will be
            deemed to be “commercial computer software” and “commercial computer software documentation,”
            respectively, pursuant to DFAR Section 227.7202 and FAR Section 12.212, as applicable. Any use,
            reproduction, release, performance, display or disclosure of the Service and any accompanying
            documentation by the U.S. Government will be governed solely by these Terms of Service and is
            prohibited except to the extent expressly permitted by these Terms of Service. The Mobile Applications
            originate in the United States, and are subject to United States export laws and regulations. The Mobile
            Applications may not be exported or re-exported to certain countries or those persons or entities
            prohibited from receiving exports from the United States. In addition, the Mobile Applications may be
            subject to the import and export laws of other countries. You agree to comply with all United States and
            foreign laws related to use of the Mobile Applications and the Service.
        </p>
        <h1>16. Mobile Applications from Apple App Store.</h1>
        <p>
            The following applies to any Mobile Applications
            you acquire from the Apple App Store (“Apple-Sourced Software”): You acknowledge and agree that
            this Agreement is solely between you and mdhub, not Apple, Inc. (“Apple”) and that Apple has no
            responsibility for the Apple-Sourced Software or content thereof. Your use of the Apple-Sourced Software
            must comply with the App Store Terms of Service. You acknowledge that Apple has no obligation
            whatsoever to furnish any maintenance and support services with respect to the Apple-Sourced Software.
            In the event of any failure of the Apple-Sourced Software to conform to any applicable warranty, you may
            notify Apple, and Apple will refund the purchase price for the Apple-Sourced Software to you; to the
            maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever
            with respect to the Apple-Sourced Software, and any other claims, losses, liabilities, damages, costs or
            expenses attributable to any failure to conform to any warranty will be solely governed by this Agreement
            and any law applicable to mdhub as provider of the software. You acknowledge that Apple is not
            responsible for addressing any claims of you or any third party relating to the Apple-Sourced Software or
            your possession and/or use of the Apple-Sourced Software, including, but not limited to: (a) product
            liability claims; (b) any claim that the Apple-Sourced Software fails to conform to any applicable legal or
            regulatory requirement; and (c) claims arising under consumer protection or similar legislation; and all
            such claims are governed solely by this Agreement and any law applicable to mdhub as provider of the
            software. You acknowledge that, in the event of any third-party claim that the Apple-Sourced Software or
            your possession and use of that Apple-Sourced Software infringes that third party’s intellectual property
            rights, mdhub, not Apple, will be solely responsible for the investigation, defense, settlement and
            discharge of any such intellectual property infringement claim to the extent required by this Agreement.
            You and mdhub acknowledge and agree that Apple, and Apple’s subsidiaries, are third-party beneficiaries
            of this Agreement as relates to your license of the Apple-Sourced Software, and that, upon your

            acceptance of the terms and conditions of this Agreement, Apple will have the right (and will be deemed
            to have accepted the right) to enforce this Agreement as relates to your license of the Apple-Sourced
            Software against you as a third-party beneficiary thereof.
        </p>
        <h1>17. Mobile Applications from Google Play Store.</h1>
        <p>
            The following applies to any Mobile Applications
            you acquire from the Google Play Store (“Google-Sourced Software”): (a) you acknowledge that these
            Terms are between you and mdhub only, and not with Google, Inc. (“Google”); (b) your use of
            Google-Sourced Software must comply with Google’s then-current Google Play Store Terms of Service;
            (c) Google is only a provider of the Google Play Store where you obtained the Google-Sourced Software;
            (d) mdhub, and not Google, is solely responsible for its Google-Sourced Software; (e) Google has no
            obligation or liability to you with respect to Google-Sourced Software or these Terms; and (f) you
            acknowledge and agree that Google is a third-party beneficiary to these Terms as it relates to mdhub’s
            Google-Sourced Software.
        </p>
        <h1>18. California Residents.</h1>
        <p>
            The provider of services is set forth herein. If you are a California resident,
            in accordance with Cal. Civ. Code §1789.3, you may report complaints to the Complaint Assistance Unit
            of the Division of Consumer Services of the California Department of Consumer Affairs by contacting it in
            writing at 1625 North Market Blvd., Suite N 112 Sacramento, CA 95834, or by telephone at (800)
            952-5210 or (916) 445-1254.
        </p>
        <h1>19. General.</h1>
        <h4>19.1 Assignment.</h4>
        <p>
            These Terms, and any rights and licenses granted hereunder, may not be
            transferred or assigned by you, but may be assigned by mdhub without restriction. Any attempted transfer
            or assignment in violation hereof will be null and void.
        </p>
        <h4>19.2 Notification Procedures and Changes to these Terms.</h4>
        <p>
            mdhub may provide
            notifications, whether such notifications are required by Applicable Law or are for marketing or other
            business-related purposes, to you via email notice, or written or hard copy notice, or through posting of
            such notice on the mdhub website, as determined by mdhub, in its sole discretion. mdhub reserves the
            right to determine the form and means of providing notifications to users, provided that you may opt out of
            certain means of notification as described in these Terms. mdhub is not responsible for any automatic
            filtering you or your network provider may apply to email notifications we send to the email address you
            provide us. mdhub may, in its sole discretion, modify or update these Terms from time to time, and so you
            should review this page periodically. When we change these Terms in a material manner, we will update
            the ‘last modified’ date at the top of this page and notify you that material changes have been made to
            these Terms. Your continued use of the Service after any such change constitutes your acceptance of the
            new Terms of Service. If you do not agree to any of the terms of these Terms or to any future Terms of
            Service, do not access or use (or continue to access or use) the Service.
        </p>
        <h4>19.3 Entire Agreement/Severability.</h4>
        <p>
            These Terms, together with any amendments and any
            additional agreements you may enter into with mdhub in connection with the Service, will constitute the
            entire agreement between you and mdhub concerning the Service. Except as otherwise stated in the
            Arbitration Agreement, if any provision of these Terms is deemed invalid by a court of competent
            jurisdiction, the invalidity of such provision will not affect the validity of the remaining provisions of these
            Terms, which will remain in full force and effect.
        </p>
        <h4>19.4 No Waiver.</h4>
        <p>
            No waiver of any term of these Terms will be deemed a further or continuing
            waiver of such term or of any other term, and mdhub’s failure to assert any right or provision under these
            Terms will not constitute a waiver of such right or provision.
        </p>
        <h4>19.5 Contact.</h4>
        <p>
            Please contact us at legal@mdhub.ai with any questions regarding these Terms.
        </p>
    </div>
</template>
  
<script>
export default {
    name: 'PrivacyPolicy',
    mounted() {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }
};
</script>
  
<style scoped>
.privacy-policy {
    max-width: 800px;
    margin: auto;
    width: 50%;
    padding: 10px;
    text-align: left;

}

h1 {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: left;
}

p {
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: justify;
}
</style>
  