<template>
    <div class="chat-container mt-0 pt-0 pr-2">
        <div class="messages pr-3 mb-8">
            <div v-for="(message, index) in messages" :key="index" class="message"
                :class="{ 'message-user': message.sender === 'user', 'message-other': message.sender !== 'user' }">
                <v-row class="mt-0 pt-0">
                    <v-col cols="12" class="text-left mb-0 pb-0 mt-0 pt-0">
                        <div class="message-label">{{ message.sender === 'user' ? 'You' : 'mdhub Assistant' }}</div>
                    </v-col>
                    <v-col cols="12" class="text-left mt-0 pt-0">
                        <div v-if="message.isLoading" class="custom-chip">
                            <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        </div>
                        <div v-else
                            :class="['custom-chip', message.sender === 'user' ? 'user-message' : 'system-message']">
                            {{ message.text }}
                            <div v-if="message.sender !== 'user'">
                                <LikeDislike :userId="user.uid" :userEmail="user.email" :feature="feature"
                                    :extra="message.text">
                                </LikeDislike>
                            </div>
                        </div>
                    </v-col>
                </v-row>

            </div>
        </div>

    </div>
    <v-textarea class="text-area" v-model="newMessage" @keyup.enter="checkForShift" rows="1" auto-grow hide-details
        label="Type a message..." variant="outlined" :elevation="10" :readonly="isLoading"></v-textarea>
</template>

<script>
import { ref, onMounted, nextTick, getCurrentInstance } from 'vue';
import getUser from '@/composables/getUser'
import { getFunctions, httpsCallable } from "firebase/functions";
import axios from 'axios';
import { trackEvent, QUESTION_ASKED } from '@/utilities/analyticsService';
import LikeDislike from '@/components/shared/LikeDislike.vue';


export default {
    props: {
        searchText: String,
        patientId: String
    },
    components: { LikeDislike },
    name: 'ChatInterface',
    setup(props) {
        const messages = ref([]);
        const newMessage = ref('');
        const { user } = getUser()
        const isLoading = ref(false);
        const instance = getCurrentInstance();
        const feature = ref(instance.type.name);

        onMounted(() => {
            if (props.searchText) {
                messages.value.push({ text: props.searchText, sender: 'user' });
                patientChatRequest(props.searchText, true);
            }
        });

        function sendMessage() {
            if (newMessage.value.trim()) {
                messages.value.push({ text: newMessage.value, sender: 'user' });
                patientChatRequest(newMessage.value, false)
                newMessage.value = '';
                scrollToBottom();
            }
        }

        const checkForShift = (event) => {
            if (!event.shiftKey) {
                event.preventDefault(); // Prevent the default action to avoid adding a new line
                sendMessage();
            }
        };

        async function patientChatRequest(prompt, isFirstMessage) {
            console.log("Loading... ")
            trackEvent(QUESTION_ASKED, { userId: user.value.uid, userEmail: user.value.email, patientId: props.patientId, prompt })
            const loadingMessageIndex = messages.value.push({
                text: "Loading...",
                sender: 'system',
                isLoading: true
            }) - 1;

            isLoading.value = true
            let response
            try {
                const functions = getFunctions();
                const onProcessPatientSession = httpsCallable(functions, 'on_retrieve_patient_sessions');
                response = await onProcessPatientSession({ patient_id: props.patientId, question: prompt })
                if (response && response.data) {
                    messages.value[loadingMessageIndex] = {
                        text: response.data,
                        sender: 'system',
                        isLoading: false
                    };
                    trackEvent(QUESTION_ASKED, { userId: user.value.uid, userEmail: user.value.email, patientId: props.patientId, answer: response.data })
                }
                scrollToBottom();
            } catch (error) {
                console.error("Error sending chat request:", error);
                messages.value[loadingMessageIndex] = {
                    text: "Failed to load message.",
                    sender: 'system',
                    isLoading: false
                };
            }
            isLoading.value = false
        }

        const scrollToBottom = () => {
            nextTick(() => {
                const textAreaHeight = document.querySelector('.text-area')?.offsetHeight || 0;
                const scrollPosition = document.body.scrollHeight - textAreaHeight;
                window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
            });
        };

        return { feature, messages, newMessage, sendMessage, checkForShift, user, isLoading };
    },
};
</script>

<style scoped>
.custom-chip {
    display: inline-block;
    padding: 4px 10px;
    border-radius: 25px;
    color: white;
    margin: 5px 0;
    white-space: pre-line;
    /* Allows line breaks */
}

.chat-container {
    padding-left: 20%;
    padding-right: 20%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
}


.message {
    display: flex;
    padding: 5px;
}

.message-label {
    font-size: 0.75rem;
    color: #666;
    padding-left: 5px;
    margin-bottom: 0;
    padding-bottom: 0;
}

.v-col .v-chip {
    margin-top: 0px;
    /* Adjust top margin of the chip to reduce space */
}

.text-area {
    background-color: #f9fbfd;
}

.message-user {
    justify-content: flex-start;
}

.message-other {
    justify-content: flex-start;
}

.message-user>.v-chip,
.message-other>.v-chip {
    border-radius: 25px;
}

.v-text-field {
    position: fixed;
    bottom: 0;
    width: 90%;
    padding-bottom: 2%;
    padding-top: 1%;
    padding-right: 1%;
    margin-left: 2%;
}

.user-message {
    color: #071022;
    /* Light pink for user messages */
}

.system-message {
    color: #071022;
    /* Dark blue for system (mdhub Assistant) messages */
}

.messages {
    max-width: 900px;
    align-items: center;
}
</style>
