import getUser from "./getUser";
import { ref } from "vue";
import { httpsCallable, getFunctions } from "firebase/functions";
import { firebaseConfig } from '@/firebase/config'

const apiKey = ref(firebaseConfig.functionApiKey)
const functions = getFunctions();

const createToken = async (context) => {
    const { user } = getUser();
    const userId = user.value.uid;
    const userName = user.value.displayName;
    const { expiration, redirectUrl, clientEmail, questionnaireId, patientQuestionnaireId, isReusable = false } = context;
    const currentDate = new Date();
    const expirationDate = new Date(currentDate.setDate(currentDate.getDate() + expiration));
    const tokenObject = { patientQuestionnaireId, url: redirectUrl, questionnaireId, userId, userName, expirationDate: expirationDate.toISOString(), clientEmail, isReusable }
    const getShareableUrl = httpsCallable(functions, 'getShareableUrl');
    const res = await getShareableUrl(tokenObject);
    return {
        url: res.data.url,
        token: res.data.token
    };
}

const sendEmail = async (emailPayload) => {
    const sendQuestionnaire = httpsCallable(functions, 'sendQuestionnaire');
    try {
        const emailObject = { from: 'no-reply@mdhub.ai', to: emailPayload.clientEmail, ...emailPayload };
        const { data } = await sendQuestionnaire(emailObject);
        return data === 'Email sent successfully';

    } catch (error) {
        return false;
    }

}
const decryptToken = async (url) => {
    if (!url) return;

    const urlParams = new URLSearchParams(url.search);
    const token = urlParams.get('token')
    if (!token) return;
    // decrypt token
    const rawRequest = {
        headers: {
            'x-api-key': apiKey.value,
        }
    }
    const getDecodedToken = httpsCallable(functions, 'getDecodedToken');
    const { data } = await getDecodedToken({ token, rawRequest });
    const { userId, questionnaireId, expirationDate, clientEmail, patientQuestionnaireId, userName } = JSON.parse(data);
    return { userId, questionnaireId, expirationDate, clientEmail, patientQuestionnaireId, userName }

}

export {
    createToken,
    decryptToken,
    sendEmail
}