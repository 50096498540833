import { ref, watchEffect } from 'vue'
import { projectFirestore } from '../firebase/config'
import getUser from "./getUser";

const { user } = getUser()

const getComplexCollection = (patient, docId, historic) => {

  const documents = ref(null)
  const error = ref(null)
  let collectionRef = projectFirestore.collection('patients').doc(docId).collection(historic)

  const unsub = collectionRef.onSnapshot(snap => {
    let results = []
    snap.docs.forEach(doc => {
      results.push({...doc.data(), id: doc.id})
    });
    
    // update values
    documents.value = results
    error.value = null
  }, err => {    
    documents.value = null
    error.value = 'could not fetch the data'
  })

      
  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub());
  });
  return { error, documents }
}

export default getComplexCollection
