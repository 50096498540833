import { ref } from 'vue'

import { doc, deleteDoc, updateDoc } from 'firebase/firestore'
import { db } from '../firebase/config'

const useDocument = (collection, id) => {

  let error = ref(null)
  let isPending = ref(false)
  const docRef = doc(db, collection, id)
  //let docRef = projectFirestore.collection(collection).doc(id)

  const deleteDocument = async () => {
    isPending.value = true
    error.value = null

    try {

      const res = deleteDoc(docRef)
      //const res = await docRef.delete()
      isPending.value = false
      return res
    }
    catch(err) {
      console.log(err.message)
      isPending.value = false
      error.value = 'could not delete the document'
    }
  }

  const updateDocument = async (updates) => {
    isPending.value = true
    error.value = null

    try {
      const res = updateDoc(docRef, updates)      
      //const res = await updateDoc(docRef, updates)      
      isPending.value = false
      return res
    }
    catch(err) {
      console.log(err.message)
      isPending.value = false
      error.value = 'could not update the document'
    }
  }

  return { error, isPending, deleteDocument, updateDocument }

}

export default useDocument