<template>


  <v-divider class="border-opacity-100" color="warning" :thickness="1"></v-divider>

  <p class="d-flex flex-row pl-3 mt-4 ml-0 text-subtitle-1" style="color:#9699a2;"> My templates</p>
  <div class="template-item">
    <div v-for="(template, index) in templatesWithExtra " :key="template.id" class="ml-0">
      <div :class="['template', 'pa-3', 'mb-1', 'd-flex', 'flex-row', 'rounded-lg', 'align-center',
        {
          'last-template': index === templatesWithExtra.length - 1,
          'clicked-template': index === clickedTemplateIndex,
          'selected-template': index === clickedTemplateIndex
        }]" @click="handleTemplateClick(template, index, false)">


        <p :class="{ '#FFF': index === templatesWithExtra.length - 1 }" class="d-flex me-auto align-center"
          :style="{ color: template.shareClinic && '#FF7260' }">
          {{
            template.templateHeader
          }}
        </p>

        <v-btn v-if="template.id !== 'extra'" icon class=" ml-2" @click="dialog = true, deleteTemplate = template"
          elevation="0">
          <v-icon color="#D8D8DA">mdi-trash-can-outline</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
  <v-dialog v-model="dialog" max-width="800" persistent>
    <v-card prepend-icon="mdi-trash" text="If you proceed you will no longer have access to it."
      :title="`Are you sure you want to delete the ${deleteTemplate?.templateHeader}?`">
      <template v-slot:actions>
        <v-spacer></v-spacer>

        <v-btn elevation="0" width="auto" class="mdhub-btn" @click="dialog = false">
          Cancel
        </v-btn>

        <v-btn elevation="0" width="auto" @click="removeTemplate(deleteTemplate)">
          Delete Template
        </v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>
<script>

import { ref } from 'vue'
import MdhubIcon from '@/assets/mdhub_combination.svg';

export default {
  props: ['templates'],
  methods: {
    handleTemplateClick(template, index, isCommunity) {

      if (isCommunity) {
        this.clickedTemplateIndex = index;
        this.$emit('template-clicked', { id: 'extra', templateHeader: 'Community', });
      } else {
        this.clickedTemplateIndex = index;
        this.$emit('template-clicked', template); // Emit the custom event
      }
    },

    removeTemplate(template) {
      this.dialog = false;
      this.$emit('template-removed', template); // Emit the custom event
      this.deleteTemplate = null;
    },

  },
  data() {
    return {
      myIcon: MdhubIcon,
      templatesWithExtra: [],
    };
  },
  computed: {
    templatesWithExtra() {
      const extraTemplate = {
        id: 'extra',
        templateHeader: 'ADD NEW TEMPLATE',
      };

      if (!Array.isArray(this.templates)) {
        console.warn("Invalid templates prop:", this.templates);
        return [extraTemplate];
      }

      const userTemplates = this.templates.filter(template => !template.shareClinic);
      let sortedTemplates = [...userTemplates].sort((a, b) => {
        return a.templateHeader.localeCompare(b.templateHeader);
      });

      return [...sortedTemplates, extraTemplate];
    },
  },

  setup(props) {
    var clickedTemplateIndex = ref(null)
    const dialog = ref(false)
    const deleteTemplate = ref(null);
    return { deleteTemplate, dialog, clickedTemplateIndex }
  }
}
</script>

<style scoped>
.clicked-template {
  box-shadow: #f1efef;
}

.template {
  transition: all ease 0.2s;
}

.template:hover {
  background-color: #f1efef;
  transition: all ease 0.2s;
  cursor: pointer;
}

.text-white {
  color: white;
  /* Set the text color to white */
}

.selected-template {
  background-color: #f1efef;
}

.template-border {
  border: 1px solid #f1efef;
  margin-left: 16px;
  margin-right: 6px;
}

.template-item p,
.template-item v-btn {
  text-align: left;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .template-item p {
    text-align: left !important;
  }
}
</style>
