<template>
  <v-card class="card-child rounded-border elevation-0">
    <v-card-text class="d-flex flex-column align-center" style="height:100%">
      <h3>{{ product?.name }}</h3>
      <p class="mb-12">{{ product?.description }}</p>
      <h5 class="mt-auto">{{ statusText() }}</h5>
      <h6>{{ priceText() }}</h6>

      <v-btn class="mdhub-btn mb-8" @click="checkout" elevation="0" :disabled="buttonDisabled()" :loading="isLoadingLocal">{{ buttonName() }}</v-btn>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { defineProps, getCurrentInstance, ref } from 'vue'
import { createCheckoutSession, createBillingPortalLink } from '../../composables/useProducts';

const props = defineProps({
  product: Object,
  userProduct: Object,
  isLoading: Boolean
})

const { emit } = getCurrentInstance();

const isLoadingLocal = ref(false);

const statusText = () => {
  if (props.userProduct) {
    return props.userProduct?.cancelAtPeriodEnd ? 'Canceled' : 'Subscribed';
  }
};

const priceText = () => {
  if (!!props.userProduct) {
    const periodEndString = props.userProduct.cancelAtPeriodEnd ? 'Last payment on' : 'Next payment on';
    const periodEndDate = new Date(props.userProduct.currentPeriodEnd).toLocaleDateString();
    return `${periodEndString} ${periodEndDate}`;
  }

  const price = defaultPrice();

  if (!price) {
    return `Something went wrong`;
  }

  const { interval, amount } = price;

  return `Only for ${amount}$ a ${interval}`;
}

const buttonName = () => {
  if (!props.userProduct) {
    return "Checkout";
  }

  if (props.userProduct?.cancelAtPeriodEnd) {
    return "Billing portal";
  }

  return "Subscribed";
};

const buttonDisabled = () => {
  if (props.isLoading) {
    return true;
  }

  if (!props.userProduct || props.userProduct?.cancelAtPeriodEnd) {
    return false;
  }
  
  return true;
};

const defaultPrice = () => {
  const defaultPrice = props.product?.prices.find(price => price.isDefault)
  return defaultPrice ?? props.product?.prices[0];
}

const checkout = async () => {
  isLoadingLocal.value = true;
  emit('isLoading', isLoadingLocal.value);
  if (!props.userProduct) {
    const checkoutUrl = await createCheckoutSession(defaultPrice().id);
    window.location.assign(checkoutUrl);
  } else if (props.userProduct?.cancelAtPeriodEnd) {
    const billingPortalUrl = await createBillingPortalLink();
    window.location.assign(billingPortalUrl);
  }
  isLoadingLocal.value = false;
  emit('isLoading', isLoadingLocal.value);
}

</script>

<style scoped>
.card-child {
  padding: 16px;
  margin: 16px;
  max-width: 300px;
  max-height: 500px;
}

h2 {
  margin-top: 0;
  color: #333;
}

p {
  color: #666;
}
</style>