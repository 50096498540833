<template>
    <div class="d-flex flex-column pa-4 ">
        <v-combobox class="mb-2 " :label="`Section`" variant="underlined" v-model="element.item" maxlength="100"
            color="accent" :items="sections"></v-combobox>
        <v-select class="mb-2 " label="Style" color="accent" v-model="element.style" variant="underlined"
            :items="['Flow text', 'Bullet points']" />
        <v-textarea class="mb-2 exclude-this-item" rows="4" v-model="element.dlevel" color="accent"
            label="Your Instructions" variant="outlined"
            placeholder="Give mdhub instructions on how to write this note e.g. extract direct quotes, comment on mood and anxiety levels..."
            persistent-placeholder density="compact" />
    </div>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
    element: Object,
})

const sections = [
    'Chief Complaint',
    //HPI
    'History of Present Illness',
    'History of Present Illness - detailed',
    'Subjective',
    // historty section
    'Past Psychiatric History',
    'Past Psychiatric History - Detailed',
    'Review of Psychiatric History',
    'Educational History',
    'Professional History',
    'Social and Family History',
    'Family History',
    'Social History',
    'Interim History',
    'Trauma History',
    'Past Medical and Surgical History',
    'Spiritual and Cultural Factors',
    //Review of Systems
    'Review of Systems',
    'General Review of Function',
    'Mental Status Exam',
    'Objective',
    'Vital Signs',
    'Symptoms',
    //Now
    'Substance Use',
    'Current Medication',
    //Assessment
    'Assessment',
    'Assessment (BPS)',
    'DAYC-2',
    'Assessment and Plan',
    'Risk Assessment',
    'Problem List',
    'Problem list (excl. ICD 10)',
    'Problems Discussed',
    'DSM-5',
    //Plan
    'Plan',
    'Plan (excl. ICD 10)',
    'Protective Factors',
    'Psychotherapeutic Interventions',
    // Progress and next steps
    'Treatment Progress',
    'Patient Goals',
]
</script>