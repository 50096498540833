import { ref } from 'vue'
import { projectAuth } from '../firebase/config'

// refs
const user = ref(projectAuth.currentUser)

// auth changes
projectAuth.onAuthStateChanged(_user => {
  // user is signed in
  if (_user) {
    user.value = _user
    localStorage.setItem('user', JSON.stringify({
      email: _user.email,
      uid: _user.uid
    }))
  } else {
    user.value = null
  }

});

const getUser = () => {
  if (!user.value) {
    user.value = JSON.parse(localStorage.getItem('user'));
  }

  return { user }
}

export default getUser