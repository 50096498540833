import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'

const useComplexCollection = (collection1, collection2) => {

  const error = ref(null)
  const isPending = ref(false)

  // add a new document
  const addDoc = async (patientId, doc) => {
    error.value = null
    isPending.value = true

    try {
      const res = await projectFirestore.collection(collection1).doc(patientId).collection(collection2).add(doc)
      isPending.value = false
      return res
    }
    catch(err) {
      console.log("Error in complex collection: ", err.message)
      error.value = 'could not send the message'
      isPending.value = false
    }
  }

  const updateDoc = async (patientId, doc) => {
    error.value = null
    isPending.value = true

    try {
      const ref = await projectFirestore.collection(collection1).doc(patientId).collection(collection2).doc(doc.id)
      ref.update({
        summary: doc.summary
      })
      isPending.value = false
      return ref
    }
    catch(err) {
      console.log("Error in complex collection: ", err.message)
      error.value = 'could not send the message'
      isPending.value = false
    }
  }

  return { error, addDoc, updateDoc, isPending }

}

export default useComplexCollection


