import { getFunctions, httpsCallable } from "firebase/functions";
import { onSnapshot, collection, addDoc } from "firebase/firestore";
import getUser from './getUser';
import { projectFirestore } from '../firebase/config';

const { user } = getUser();

export async function getAvailableProducts() {
    const userId = user.value.uid;
    console.log('Fetching available products for user ID', userId);

    const functions = getFunctions();
    const getAvailableProductsForUser = httpsCallable(functions, 'getAvailableProducts');
    
    try {
        const products = (await getAvailableProductsForUser({ userId }))?.data;
        console.log('Received available products for user ID', { userId, products });
        return products;
    } catch (error) {
        console.error('Failed to fetch available products for user ID', error);
    }
}

export async function createCheckoutSession(priceId) {
    return new Promise(async (resolve, reject) => {
        const userId = user.value.uid;
        console.log('About to create checkout session for user ID with price ID', { userId, priceId });
    
        const customersCollectionRef = collection(
            projectFirestore,
            'customers',
            userId,
            'checkout_sessions'
        );
    
        const checkoutRef = await addDoc(
            customersCollectionRef,
            { 
                price: priceId,
                success_url: window.location.origin + '/details',
                cancel_url: window.location.origin + '/details',
                allow_promotion_codes: true
            }
        );
    
        const subscription = onSnapshot(checkoutRef, (snapshot) => {
            console.log('Checkout reference got updated', snapshot.data());
            const { error, url } = snapshot.data();
    
            if (error) {
                console.error('Received error from the checkout reference', error);
                subscription();
                reject(error);
            }
    
            if (url) {
                console.log('Received URL for the Stripe checkout session', url);
                subscription();
                resolve(url);
            }
        });
    });
}

export async function createBillingPortalLink() {
    console.log('Creating link to the billing portal');

    const functions = getFunctions();
    const getAvailableProductsForUser = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');

    try {
        const link = (await getAvailableProductsForUser({ returnUrl: window.location.origin + '/details' }))?.data?.url;
        console.log('Created billing portal link', link);
        return link;
    } catch (error) {
        console.error('Failed to create link to billing portal', error);        
    } 
}