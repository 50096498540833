
import { trackEvent, NOTIFICATION_PERMISSION_GRANTED, NOTIFICATION_PERMISSION_NOT_GRANTED, NOTIFICATION_SHOWN } from '@/utilities/analyticsService'; import getUser from "./getUser";

const { user } = getUser();
const askNotificationPermission = async () => {

    const userId = user.value.uid;
    // Check if the browser supports notifications
    if (!("Notification" in window)) {
        console.log("This browser does not support notifications.");
        return;
    }
    const result = await Notification.requestPermission();
    trackEvent(result === "granted" ? NOTIFICATION_PERMISSION_GRANTED : NOTIFICATION_PERMISSION_NOT_GRANTED, { userId: userId, source: "Web" })
}

const sendNotification = (context) => {
    const { title, body } = context;
    const notification = new Notification(title, { body });
    notification.onshow = (e) => {
        console.log("Notification shown", e);
        const userId = user.value.uid;
        //track that the notification was shown
        trackEvent(NOTIFICATION_SHOWN, { userId: userId, source: "Web" })
    }
}


export {
    askNotificationPermission,
    sendNotification
}