<template>
  <v-sheet class="d-flex flex-column justify-center align-center ml-5 mr-5 mt-4" color="#f9fbfd">

    <v-card class="mdhub-card" width="100%" max-width="800px" height="100%">
      <v-card-title class="left-align mt-7 ml-6"> Note settings</v-card-title>
      <v-card-subtitle class="left-align mb-5 ml-6"> Those settings will apply to all new
        clinical notes.</v-card-subtitle>
      <div class="left-align mb-5 mt-7 ml-10">
        <p>I want to use: </p>
        <v-btn-toggle class="toggle-box d-flex justify-left mb-2 mb-0" v-model="usePatientName" rounded="l"
          color="grey-lighten-5" group mandatory>
          <v-btn @click="saveSettings()" :value="true">
            Patient Name
          </v-btn>
          <v-btn @click="saveSettings()" :value="false">
            Do not use Name
          </v-btn>
        </v-btn-toggle>
        <p>I want to use: </p>
        <v-btn-toggle class="toggle-box d-flex justify-left mb-2 mb-0" v-model="subject" rounded="l"
          color="grey-lighten-5" group mandatory>
          <v-btn @click="saveSettings()" value="patient">
            Patient
          </v-btn>

          <v-btn @click="saveSettings()" value="client">
            Client
          </v-btn>

        </v-btn-toggle>
      </div>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-end">
        <v-btn variant="text" @click="closeDialog">
          Save & Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-sheet>
</template>

<script>

import useLogout from '@/composables/useLogout'
import getUser from '@/composables/getUser'
import { useRouter } from 'vue-router'
import { getUserCollection } from '@/composables/userService';
import { ref } from 'vue';
import useDocument from '@/composables/useDocument'

export default {

  setup(props, { emit }) {
    const { user } = getUser()
    const { logout } = useLogout()
    const router = useRouter()
    const userObject = ref({}); // Initialize userObject as a reactive reference
    const documentHelpers = ref(null);
    const usePatientName = ref(true)
    const subject = ref('patient')
    const narration = ref('third')

    const loadUserObject = async () => {
      userObject.value = await getUserCollection(user.value.uid);
      console.log('loadUserObject')
      if (userObject.value) {
        documentHelpers.value = useDocument('user', userObject.value.id)
        console.log('we have user: ', userObject.value.settings?.usePatientName)
        narration.value = userObject.value.settings?.narration || 'first'
        usePatientName.value = !!userObject.value.settings?.usePatientName
        subject.value = userObject.value.settings?.subject || 'patient'
      }
    };

    loadUserObject();

    const saveSettings = async () => {
      const res = await documentHelpers.value.updateDocument({
        settings: { usePatientName: usePatientName.value, subject: subject.value, narration: narration.value },
      })
    }

    const closeDialog = async () => {
      const res = await documentHelpers.value.updateDocument({
        settings: { usePatientName: usePatientName.value, subject: subject.value, narration: narration.value },
      })
      emit('close-dialog');
    }

    return { user, userObject, usePatientName, subject, narration, saveSettings, closeDialog }
  }
}

</script>

<style scoped>
.mdhub-card {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.left-align {
  text-align: left;
}
</style>