import { ref } from 'vue'
import { projectAuth } from '../firebase/config'
import posthog from 'posthog-js'
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getIsZoomApp } from '@/composables/configureZoom'
import { trackEvent, LOGIN_FROM_ZOOM } from '@/utilities/analyticsService';

const error = ref(null)
const isPending = ref(false)
const functions = getFunctions();
const { isZoomApp } = getIsZoomApp();

// Function to get the current count of too-many-requests errors
const getTooManyRequestsCount = () => {
  return parseInt(localStorage.getItem('tooManyRequestsCount')) || 0
}

// Function to increment the count of too-many-requests errors
const incrementTooManyRequestsCount = () => {
  const currentCount = getTooManyRequestsCount()
  localStorage.setItem('tooManyRequestsCount', currentCount + 1)
}

// Function to reset the count of too-many-requests errors
const resetTooManyRequestsCount = () => {
  localStorage.setItem('tooManyRequestsCount', 0)
}

const login = async (email, password) => {
  const isZoom = isZoomApp.value;
  error.value = null
  isPending.value = true

  try {
    const res = await projectAuth.signInWithEmailAndPassword(email, password)
    error.value = null
    isPending.value = false
    resetTooManyRequestsCount()  // Reset counter on successful login
    if (isZoom) {
      trackEvent(LOGIN_FROM_ZOOM, { email, source: 'zoom' })
    }
    return res
  } catch (err) {
    posthog.capture("Error in user login", { email: email, message: err.message })
    isPending.value = false

    if (err.code == "auth/too-many-requests") {
      incrementTooManyRequestsCount()
      const currentCount = getTooManyRequestsCount()
      console.log("currentCount: ", currentCount)
      if (currentCount > 2) {
        // Disable account logic here
        error.value = 'Too many failed attempts. Account has been disabled. Please reach out to support@mdhub.ai and we will enable your account again.'
        // Example API call to disable the account
        try {
          const disableUser = httpsCallable(functions, 'disableUser');
          await disableUser({ email: email });
        } catch (err) {
          console.log("Error in disabling account:", err)
        }

      } else {
        error.value = 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.'
      }
    } else if (err.code == "auth/user-disabled") {
      error.value = 'Too many failed attempts. Account has been disabled. Please reach out to support@mdhub.ai and we will enable your account again.'
    } else {
      error.value = 'Incorrect credentials'
    }
  }
}

const useLogin = () => {
  return { error, login, isPending }
}

export default useLogin
