<template>
  <v-card class="mx-auto ma-4 d-flex justify-end" width="700" elevation="0">
    <v-btn v-if="!isCreate" class="mt-4 mr-2" variant="text" @click="toggleShareDialog" :loading="isPendingSave"
      :disabled="!templateHeader || (!isUserAdmin && shareClinic)">
      Share
    </v-btn>
    <v-btn v-if="isCreate" class="mdhub-btn mt-4" variant="text" @click="handleClick" :loading="isPending"
      :disabled="!templateHeader">
      Create
    </v-btn>
    <v-btn v-else class="mdhub-btn mt-4 mr-2" variant="text" @click="handleSave" :loading="isPendingSave"
      :disabled="!templateHeader || (!isUserAdmin && shareClinic)">
      Save
    </v-btn>
  </v-card>

  <v-card class="mx-auto pa-4" width="700" elevation="1" :disabled="(!isUserAdmin && shareClinic)">
    <v-card-title class="text-start">Template Details</v-card-title>
    <v-card-subtitle class="text-start">Use a descriptive name to remember your template</v-card-subtitle>

    <v-card-text>
      <v-text-field class="mb-6 mt-4" :class="{ 'invalid-input': !templateHeader }" v-model="templateHeader"
        color="accent" label="Name *" variant="underlined" density="compact" :rules="[required]" required
        placeholder="e.g. SOAP" />

      <v-select class="mb-4" label="Note language" variant="underlined" density="compact" v-model="selectedLanguage"
        :items="['English', 'Spanish', 'French', 'German', 'Arabic', 'Chinese', 'Punjabi', 'Tagalog']"></v-select>

      <v-select class="mb-4" label="Specialty" variant="underlined" density="compact" v-model="selectedSpeciality"
        :items="['Psychiatry', 'Psychology', 'Child and Adolescent Psychiatry',
          'Geriatric Psychiatry', 'Forensic Psychiatry', 'Addiction Psychiatry', 'Consultation-Liaison Psychiatry', 'Neuropsychiatry', 'Psychosomatic Medicine', 'Emergency Psychiatry', 'Sleep Medicine', 'Clinical Psychology', 'Counseling Psychology', 'School Psychology', 'Industrial-Organizational Psychology', 'Health Psychology', 'Neuropsychology', 'Forensic Psychology', 'Sports Psychology', 'Developmental Psychology', 'Social Psychology', 'Experimental Psychology',
          'Community Psychology', 'Anesthesiology', 'Cardiology', 'Dermatology', 'Emergency Medicine', 'Endocrinology', 'Family Medicine', 'Gastroenterology', 'General Surgery', 'Geriatrics', 'Hematology', 'Infectious Disease', 'Internal Medicine', 'Nephrology', 'Neurology', 'Nutritionist', 'Obstetrics and Gynecology', 'Oncology', 'Ophthalmology', 'Orthopedics', 'Otolaryngology', 'Pediatrics', 'Physical Medicine and Rehabilitation', 'Plastic Surgery', 'Pulmonology', 'Radiology', 'Rheumatology', 'Urology', 'Other',]"></v-select>
      <div class="align-center justify-content-between">
        <div class="text-start">
          <p>Share with the community</p>
          <div class="text-start">
            <v-btn-toggle v-model="shareCommunity" rounded="0" color="#FDBEB4" group mandatory>
              <v-btn :value="true">
                Yes
              </v-btn>

              <v-btn :value="false">
                No
              </v-btn>
            </v-btn-toggle>
          </div>
        </div>
        <div v-if="isUserAdmin" class="text-start">
          <p>Share with the clinic</p>
          <div class="text-start">
            <v-btn-toggle v-model="shareClinic" rounded="0" color="#FDBEB4" group mandatory>
              <v-btn :value="true">
                Yes
              </v-btn>

              <v-btn :value="false">
                No
              </v-btn>
            </v-btn-toggle>
          </div>
        </div>
      </div>

    </v-card-text>

  </v-card>


  <v-container ref="inputContainer">
    <draggable v-model="inputValues" group="sections" @start="drag = true" @end="drag = false">
      <template #item="{ element, index }">
        <v-card class="mx-auto mb-3" elevation="1" width="700" :disabled='!isUserAdmin && shareClinic'>
          <v-card-text>
            <div class="d-flex flex-column pa-4">



              <!-- Combobox for section selection 
              ToDo get those sections dinamically instead of hard coded  -->
              <v-combobox class="mb-2" :label="`Section`" variant="underlined" v-model="element.item" maxlength="100"
                color="accent" :items="[
                  'Chief Complaint',
                  //HPI
                  'History of Present Illness',
                  'History of Present Illness - detailed',
                  'Subjective',
                  // historty section
                  'Past Psychiatric History',
                  'Past Psychiatric History - Detailed',
                  'Review of Psychiatric History',
                  'Educational History',
                  'Professional History',
                  'Social and Family History',
                  'Family History',
                  'Social History',
                  'Interim History',
                  'Trauma History',
                  'Past Medical and Surgical History',
                  'Spiritual and Cultural Factors',
                  //Review of Systems
                  'Review of Systems',
                  'General Review of Function',
                  'Mental Status Exam',
                  'Objective',
                  'Vital Signs',
                  'Symptoms',
                  //Now
                  'Substance Use',
                  'Current Medication',
                  //Assessment
                  'Assessment',
                  'Assessment (BPS)',
                  'Assessment and Plan',
                  'Risk Assessment',
                  'Problem List',
                  'Problem list (excl. ICD 10)',
                  'Problems Discussed',
                  'DSM-5',
                  'DAYC-2',
                  //Plan
                  'Plan',
                  'Plan (excl. ICD 10)',
                  'Protective Factors',
                  'Psychotherapeutic Interventions',
                  // Progress and next steps
                  'Treatment Progress',
                  'Patient Goals',
                ]"></v-combobox>

              <!-- Style selection -->
              <v-select class="mb-2" label="Style" color="accent" v-model="element.style" variant="underlined"
                :items="['Flow text', 'Bullet points']" />

              <!-- Custom style input -->
              <v-textarea class="mb-2" rows="4" v-model="inputValues[index].dlevel" color="accent"
                label="Your Instructions" variant="outlined"
                placeholder="Give mdhub instructions on how to write this note e.g. extract direct quotes, comment on mood and anxiety levels..."
                persistent-placeholder density="compact" />


            </div>
          </v-card-text>

          <v-card-actions>
            <v-tooltip location="bottom" text="Move up">
              <template v-slot:activator="{ props }">
                <v-btn elevation="0" @click="moveUp(index)" v-bind="props">
                  <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
            <v-tooltip location="bottom" text="Move down">
              <template v-slot:activator="{ props }">
                <v-btn elevation="0" @click="moveDown(index)" v-bind="props">
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
            <v-spacer></v-spacer>

            <v-btn @click="removeInputField(index)" elevation="0">
              Remove
            </v-btn>
            <v-btn elevation="0" @click="handleSave" :disabled="!templateHeader || isCreate" :loading="isPendingSave">
              Save
            </v-btn>
          </v-card-actions>

        </v-card>
      </template>
    </draggable>
  </v-container>


  <v-card class="mx-auto pa-4" width="700" elevation="0">
    <v-btn class="ml-16" variant="plain" @click="addInputField" :disabled='!isUserAdmin && shareClinic'> Add new
      section</v-btn>
  </v-card>

  <v-dialog v-model="showShareDialog" persistent max-width="600px">
    <v-card class="pa-4" :disabled='!isUserAdmin && shareClinic'>
      <v-card-title class="headline">Share Template</v-card-title>
      <v-card-subtitle>Enter the email of the person you want to share this template with:</v-card-subtitle>
      <v-card-text>
        <v-text-field label="Email" type="email" v-model="emailToShare" :rules="[emailRules]" required
          variant="outlined"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="shareTemplate">Share</v-btn>
        <v-btn color="primary" text @click="showShareDialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { ref, onUpdated, watch } from 'vue'
import useCollection from '@/composables/useCollection'
import getUser from '@/composables/getUser'
import { timestamp } from '@/firebase/config'
import ListTemplates from '@/components/ListTemplates.vue'
import ListClinicalTemplates from '@/components/ListClinicalTemplates.vue'
import TemplateEditor from '@/components/TemplateEditor.vue'
import getCollection from '@/composables/getCollection'
import useDocument from '@/composables/useDocument'
import draggable from 'vuedraggable'
import { trackEvent, TEMPLATE_SHARED, CREATE_CLINICAL_TEMPLATE, UPDATE_CLINICAL_TEMPLATE } from '@/utilities/analyticsService';
import getDocument from '@/composables/getDocument'
import { getUserCollection } from '@/composables/userService';
import { onMounted } from 'vue'
import ClinicTemplates from './ClinicTemplates.vue'

export default {
  props: ['inputValues', 'isCreate', 'templateHeader', 'templateId', 'language', 'shareCommunity', 'shareClinic', 'selectedSpeciality', 'clinicId', 'isDeleting'],
  data() {
    return {
      drag: false,
      localInputValues: [...this.inputValues],
      text: 'No',
    };
  },
  components: {
    ListTemplates,
    ListClinicalTemplates,
    TemplateEditor,
    draggable
  },
  methods: {
    moveUp(index) {
      if (index > 0) {
        const item = this.inputValues.splice(index, 1)[0];
        this.inputValues.splice(index - 1, 0, item);
      }
    },
    moveDown(index) {
      if (index < this.inputValues.length - 1) {
        const item = this.inputValues.splice(index, 1)[0];
        this.inputValues.splice(index + 1, 0, item);
      }
    },

    getToolTip() {
      var words = `Section <br> Select from our dropdown or write your own section title. <br><br>
            Style<br> Choose between bullet points or flow text.<br><br>
            Use your own words <br> Specify precisely how you want this section written and provide detailed instructions. You can include examples from your previous notes.`
      return words;
    },
    removeInputField(index) {
      this.inputValues.splice(index, 1);
    },
    templateClicked(template) {
      if (template.templateHeader === "ADD NEW TEMPLATE") {
        this.templateHeader = ""
        this.isCreate = true
        this.inputValues = [
          { item: '', dlevel: '', style: 'Bullet points' },
          { item: '', dlevel: '', style: 'Bullet points' },
          { item: '', dlevel: '', style: 'Bullet points' },
          { item: '', dlevel: '', style: 'Bullet points' }]

      } else {
        this.selectedTemplate = template
        this.templateHeader = template.templateHeader
        const transformedArray = template.template.map(item => {
          const [title, dlevel, style] = item.split('/');
          return {
            item: title,
            dlevel: dlevel,
            style: style,
          };
        });

        this.inputValues = transformedArray
        this.isCreate = false
      }
    },
    required(v) {
      return !!v || 'Field is required';
    },
    closeModal() {
      this.$emit('close-modal');
    },
    addInputField() {
      this.inputValues.push({ item: '', dlevel: '', style: 'Bullet points' });
      // Scroll to the last input field after adding a new one
      this.$nextTick(() => {
        const inputContainer = this.$refs.inputContainer;
        if (inputContainer) {
          const inputFields = inputContainer.getElementsByTagName('input');
          if (inputFields && inputFields.length > 0) {
            inputFields[inputFields.length - 1].scrollIntoView({
              behavior: 'smooth',
              block: 'end',
              inline: 'nearest',
            });
          }
        }
      });
    },
  },
  data() {
    return {
      notifications: false,
      sound: true,
      widgets: false,
      localInputValues: [...this.inputValues]
    };
  },
  setup(props) {
    const localInputValues = ref([...props.inputValues]);
    const showShareDialog = ref(false);
    const emailToShare = ref('')
    const shareCommunity = ref(false)
    const shareClinic = ref(false);
    const templateHeader = ref('');
    const templateId = ref('');
    const dlevel = ref('');
    const style = ref('');
    const selectedLanguage = ref('English');
    const selectedSpeciality = ref('Psychiatry');
    const selectedTemplate = ref('')
    const { err, addDoc } = useCollection('summary-templates');
    const isPending = ref(false);
    const isPendingSave = ref(false);
    let isCreate = ref(props.isCreate)
    const inputValues = ref([
      { item: '', dlevel: '', style: 'Bullet points' },
      { item: '', dlevel: '', style: 'Bullet points' },
      { item: '', dlevel: '', style: 'Bullet points' },
      { item: '', dlevel: '', style: 'Bullet points' }
    ]);
    const { user } = getUser();
    const isUserAdmin = ref(false);

    const { error, document: clinic } = props.clinicId ? getDocument('clinics', props.clinicId) : {};


    const clinicId = ref(null);
    const clinicTemplates = ref([]);
    const { documents: templates } = getCollection(
      'summary-templates',
      ['userId', '==', user.value.uid]
    )
    const isDeleting = ref(false);
    isDeleting.value = props.isDeleting;
    clinicId.value = props.clinicId || null;
    templateHeader.value = props.templateHeader

    if (!props.isCreate) {
      templateId.value = props.templateId;
      inputValues.value = props.inputValues;
      selectedLanguage.value = props.language;
      selectedSpeciality.value = props.selectedSpeciality;
      shareCommunity.value = props.shareCommunity;
      shareClinic.value = props.shareClinic;
    }

    const loadUserObject = async () => {
      const userObject = await getUserCollection(user.value.uid);
      clinicTemplates.value = clinic?.value?.templates || [];
      isUserAdmin.value = userObject?.isAdmin || false;

    }

    const handleClick = async () => {
      isPending.value = true;
      const { error, document: clinic } = clinicId.value ? getDocument('clinics', clinicId.value) : {}
      let customTemplate = inputValues.value
        .filter(value => value.item !== '')
        .map(value => {

          var sectionTitle = value.item
          var sectionStyle = value.style
          var sectionOwnWords = value.dlevel.replaceAll('/', ',')

          return sectionTitle + "/" + sectionOwnWords + "/" + sectionStyle;
        });

      const res = await addDoc({
        template: customTemplate,
        templateHeader: templateHeader.value,
        userId: user.value.uid,
        userName: user.value.displayName,
        createdAt: timestamp(),
        language: selectedLanguage.value,
        selectedSpeciality: selectedSpeciality.value,
        shareCommunity: shareCommunity.value,
        shareClinic: shareClinic.value,
        clinicId: shareClinic.value ? clinicId.value : null
      });

      if (shareClinic.value && clinicId.value) {
        const { updateDocument: updateClinic } = await useDocument('clinics', clinicId.value);
        const newTemplate = {
          id: res.id,
          name: templateHeader.value

        };

        await updateClinic({
          templates: [...clinic?.value.templates, newTemplate]
        });

        trackEvent(CREATE_CLINICAL_TEMPLATE, { userId: user.value.uid, email: user.value.email, clinicId: clinicId.value, templateId: res.id });
      }

      resetDialog();

      isPending.value = false;
    };

    const toggleShareDialog = () => {
      showShareDialog.value = !showShareDialog.value;
    };

    const shareTemplate = () => {
      trackEvent(TEMPLATE_SHARED, { userId: user.value.uid, userEmail: user.value.email, emailToShare: emailToShare.value })
      showShareDialog.value = false;
    };

    const handleSave = async () => {


      isPendingSave.value = true
      const { error, document: clinic } = clinicId.value ? getDocument('clinics', clinicId.value) : {}

      let customTemplate = inputValues.value
        .filter(value => value.item !== '')
        .map(value => {

          var sectionTitle = value.item
          var sectionStyle = value.style
          var sectionOwnWords = value.dlevel.replaceAll('/', ',')

          return sectionTitle + "/" + sectionOwnWords + "/" + sectionStyle;
        });

      try {

        const { updateDocument } = await useDocument('summary-templates', templateId.value)

        const res = await updateDocument({
          templateHeader: templateHeader.value,
          updatedAt: timestamp(),
          template: customTemplate,
          language: selectedLanguage.value,
          selectedSpeciality: selectedSpeciality.value,
          shareCommunity: shareCommunity.value,
          shareClinic: shareClinic.value,
          clinicId: shareClinic.value ? clinicId.value : null
        });

        if (isUserAdmin.value && shareClinic.value && clinicId.value) {

          const restOfTemplates = clinic?.value.templates.filter(template => template.id !== templateId.value) || []

          const { updateDocument: updateClinic } = await useDocument('clinics', clinicId.value);

          const updatedTemplate = shareClinic.value ? {
            id: templateId.value,
            name: templateHeader.value
          } : {};

          const concatTemplates = [...restOfTemplates, updatedTemplate];
          await updateClinic({
            templates: concatTemplates
          });

          trackEvent(UPDATE_CLINICAL_TEMPLATE, { userId: user.value.uid, email: user.value.email, clinicId: clinicId.value, templateId: templateId.value });

        }

        isPendingSave.value = false
      }
      catch (error) {
        console.log('Something went wrong while trying to save the template:', error);
      }

    }

    const resetDialog = () => {
      templateHeader.value = 'My new template';
      inputValues.value = [
        { item: '', dlevel: '', style: 'Bullet points' },
        { item: '', dlevel: '', style: 'Bullet points' },
        { item: '', dlevel: '', style: 'Bullet points' },
        { item: '', dlevel: '', style: 'Bullet points' }
      ];
    };

    onUpdated(() => {
      if (props.isModalOpen) {
        resetDialog();
      }
    });



    watch(() => props.templateId, newVal => {
      templateId.value = newVal;

    });
    watch(() => props.inputValues, newVal => {
      inputValues.value = [...newVal];
    });

    watch(() => props.isCreate, newVal => {
      isCreate.value = newVal;
    });

    watch(() => props.templateHeader, newVal => {
      templateHeader.value = newVal;
    });

    watch(() => props.language, newVal => {
      selectedLanguage.value = newVal;
    });

    watch(() => props.selectedSpeciality, newVal => {
      selectedSpeciality.value = newVal;
    });

    watch(() => props.shareCommunity, newVal => {
      shareCommunity.value = newVal;
    });

    watch(() => props.shareClinic, newVal => {
      shareClinic.value = newVal;
    });

    watch(() => isDeleting, newVal => {
      console.log(props.isDeleting, newVal)
      isDeleting.value = true;
      loadUserObject();
    });
    loadUserObject();

    return { isUserAdmin, localInputValues, inputValues, templateHeader, dlevel, style, handleClick, isPending, templates, isCreate, isPendingSave, handleSave, selectedTemplate, templateId, selectedLanguage, toggleShareDialog, showShareDialog, shareTemplate, emailToShare, selectedSpeciality, shareCommunity, shareClinic };
  },
}
</script>
<style scoped>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform .2s ease-in-out;
}

.toolbar {
  background-color: #fcfafb;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 0.875rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.05);
  padding: 1px 10px;
  margin-bottom: 10px;
}

.invalid-input {
  border-color: red;
  /* Change the border color to indicate invalid input */
}

.align-center {
  display: flex;
  align-items: center;
}

.dlevel {
  width: 25%;
}

.style {
  width: 50%;
}

.fixed-width-combobox {
  width: 300px;
}

.grab-cursor:hover {
  cursor: grab;
}
</style>