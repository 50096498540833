import { ref, watch } from "vue"

const isRecording = ref(false)
const combinedRecorder = ref(null)
const micRecorder = ref(null)
const displayRecorder = ref(null)
const combinedURL = ref("")
const micStream = ref(null)
const displayStream = ref(null)

const useRecordingState = () => {
  const stop = () => {
    isRecording.value = false
  }
  const start = () => {
    isRecording.value = true
  }

  return {
    isRecording,
    combinedRecorder,
    micRecorder,
    displayRecorder,
    combinedURL,
    micStream,
    displayStream,
    stop,
    start,
  }
}

export default useRecordingState
