import { ref, watchEffect } from 'vue'
import { projectFirestore } from '../firebase/config'
import getUser from "./getUser";

const {user} = getUser()

const getCollection = (collection, query) => {

  const documents = ref(null)
  const error = ref(null)

  // register the firestore collection reference
  let collectionRef = projectFirestore.collection(collection)
    .where('userId', '==', user.value.uid) // Only retrieve documents that belong to the authenticated user
    .orderBy('createdAt')
    
  if (query) {
    collectionRef = collectionRef.where(...query)
  }
  
  const unsub = collectionRef.onSnapshot(snap => {
    let results = []
    snap.docs.forEach(doc => {    
      // must wait for the server to create the timestamp & send it back
      doc.data().createdAt && results.push({...doc.data(), id: doc.id})
    });
    
    // update values
    documents.value = results
    error.value = null
  }, err => {    
    documents.value = null
    error.value = 'could not fetch the data'
  })

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub());
  });
  return { error, documents }
}

export default getCollection