import { getFunctions, httpsCallable } from "firebase/functions";

const getAudio = async (path) => {
  const pathWithoutGS = path.replace('gs://', '');
  const pathSplitItems = pathWithoutGS.split('/');
  
  if (pathSplitItems.length < 4) {
    console.error(`Path for audio session ${sessionId} is incorrect`);
    throw new Error('Audio path is incorrect');
  }

  const userId = pathSplitItems[1];
  const sessionId = pathSplitItems[3];

  try {
    const functions = getFunctions();
    const getPublicUrl = await httpsCallable(functions, 'getAudioSignedLink');
    const url = (await getPublicUrl({ userId, sessionId })).data;

    return url;
  } catch (error) {
    console.error(`Got error while trying to get public url for the audio session ${sessionId}...`, error);
    throw new Error('Audio not found');
  }
};

export default getAudio;