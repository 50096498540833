import { ref } from 'vue'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth' // Updated import path for Firebase version 9
import { projectAuth } from '../firebase/config'
import posthog from 'posthog-js'

const errorGoogle = ref(null)
const isPendingGoogle = ref(false)

const signupGoogle = async () => {
  errorGoogle.value = null
  isPendingGoogle.value = true
  try {
    
    const res = new GoogleAuthProvider()
    const respopup = await signInWithPopup(projectAuth, res)

    if (!res) {
      throw new Error('Could not complete signup')
    }
    
    errorGoogle.value = null
    isPendingGoogle.value = false
    
    return respopup
  }
  catch(err) {
    posthog.capture("Error in Google signup", { message: err.message });
    console.log(err.message)
    errorGoogle.value = err.message
    isPendingGoogle.value = false
  }
}

const useSignupGoogle = () => {
  return { errorGoogle, signupGoogle, isPendingGoogle }
}

export default useSignupGoogle