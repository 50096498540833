import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { projectAuth } from './firebase/config'
import posthogPlugin from "../plugins/posthog"; //import the plugin. 
import * as Sentry from '@sentry/vue';
import VueGtag from "vue-gtag";

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'
import GtmPlugin from './plugins/gtm-plugin';

import 'quill/dist/quill.snow.css'; // for snow theme
import 'quill/dist/quill.bubble.css'; // for bubble theme



const vuetify = createVuetify({
    components,
    directives,
    defaults: {
        VBtn: {
            colors: 'primary',
            backgroundColor: 'primary',
        }
    },
    theme: {
        options: {
            customProperties: true
        },
        themes: {
          light: {
            dark: false,
            colors: {
              primary:  "#EC7E70",
              secondary: "#FFCDD2", 
              accent: '#74BEC1',
              bggray: '#ebebec',
              primary: "#4f515a",
              secondary: "#ebebeb",
              warning: "#da0f41",
              bittersweet: "#FF7260",
              melon: "#FDBEB4",
              timberwolf: "#D8D8D8",
              silver: "#B0B0B0",
              whitesmoke: "#F3F3F3",
              offwhite: "#FAFAFA",
              richblack: "#071022", 
              electricblue: "#7CE2E7",
              electricbluehover: "#4c8e98",
              white: "#FFFFFF",
            }
          },
        },
    },    
    cssVariables: true,
  })


// global styles
import './assets/main.css'
import { logEvent } from './logger'; // Assuming you have implemented logEvent as suggested


let app

projectAuth.onAuthStateChanged( () => {
    if(!app){
        app = createApp(App);
        Sentry.init({
          app,
          dsn: "https://9b29ee7199b4367cf3bae321a3f30310@o4507222113845248.ingest.us.sentry.io/4507222118236160",
          integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
          ],
          // Performance Monitoring
          tracesSampleRate: 1.0, //  Capture 100% of the transactions
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
          // Session Replay
          replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
          replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });        
        app.use(router);
        app.use(posthogPlugin);
        app.use(vuetify);
        app.use(GtmPlugin, { gtmId: 'GTM-5QVGHQ66' });
        app.use(VueGtag, {
            config: { id: "G-LBTFJXKHKK" }
        });
        app.mount('#app');
    }    
});

// Global error handler for the window
window.onerror = (message, source, lineno, colno, error) => {
  const formattedError = { message, source, lineno, colno, error: error?.toString() };
  logEvent('error', 'Window error', formattedError);
};