<template>
  <p class="d-flex flex-row pl-3 mt-4 ml-0 text-subtitle-1 mt-8" style="color:#9699a2;"> Clinic templates</p>
  <div class="template-item">
    <div v-for="(template, index) in templatesWithExtra" :key="template.id" class="ml-0">
      <div :class="['template', 'pa-3', 'mb-1', 'd-flex', 'flex-row', 'rounded-lg',
      {
        'clicked-template': index === clickedTemplateIndex,
        'selected-template': index === clickedTemplateIndex
      }]" @click="handleTemplateClick(template, index, false)">
        <p class="d-flex me-auto align-center clinicalTempHeader">{{
      template.templateHeader }}</p>
        <v-btn v-if="isUserAdmin" icon class=" ml-2" @click="removeTemplate(template)" elevation="0">
          <v-icon color="#D8D8DA">mdi-trash-can-outline</v-icon>
        </v-btn>
      </div>
    </div>
    <v-tooltip location="center" text="Your admin as the ability to set standardized templates for your clinic.">
      <template v-slot:activator="{ props }">
        <div :class="['pa-3', 'mb-1', 'd-flex', 'flex-row', 'rounded-lg']" v-bind="props">
          <p v-if="templatesWithExtra.length === 0" class="d-flex me-auto align-center clinicalTempHeader"> No clinic
            templates added yet </p>
        </div>
      </template>
    </v-tooltip>

  </div>
</template>
<script>

import { ref } from 'vue'
import MdhubIcon from '@/assets/mdhub_combination.svg';

export default {
  props: ['templates', 'clinicId', 'isUserAdmin'],
  methods: {
    handleTemplateClick(template, index, isCommunity) {

      if (isCommunity) {
        this.clickedTemplateIndex = index;
        this.$emit('template-clicked', { id: 'extra', templateHeader: 'Community', });
      } else {
        this.clickedTemplateIndex = index;
        this.$emit('template-clicked', template); // Emit the custom event
      }
    },

    removeTemplate(template) {
      this.$emit('template-removed', template); // Emit the custom event
    },

  },
  data() {
    return {
      myIcon: MdhubIcon,
      templatesWithExtra: [],
    };
  },
  computed: {
    templatesWithExtra() {
      const clinicTemplates = this.templates.filter(template => template.clinicId === this.clinicId)
      let sortedTemplates = [...clinicTemplates].sort((a, b) => {
        return a.templateHeader.localeCompare(b.templateHeader);
      });

      return sortedTemplates;
    },
  },

  setup(props) {
    var clickedTemplateIndex = ref(null)
    const isUserAdmin = ref(false);
    isUserAdmin.value = props.isUserAdmin;
    return { clickedTemplateIndex, isUserAdmin }
  }
}
</script>

<style scoped>
.clicked-template {
  box-shadow: #f1efef;
}

.template {
  transition: all ease 0.2s;
}

.template:hover {
  background-color: #f1efef;
  transition: all ease 0.2s;
  cursor: pointer;
}

.text-white {
  color: white;
  /* Set the text color to white */
}

.selected-template {
  background-color: #f1efef;
}

.template-border {
  border: 1px solid #f1efef;
  margin-left: 16px;
  margin-right: 6px;
}

.template-item p,
.template-item v-btn {
  text-align: left;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .template-item p {
    text-align: left !important;
  }
}
</style>
