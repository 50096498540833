<template>
    <div class="privacy-policy">
        <h2>MDHUB Business Associate Agreement Addendum</h2>
        <h6>Last Updated: May 26, 2023</h6>
        <p>
            This Business Associate Agreement Addendum (“Addendum” or “BAA”) is entered into by and between
            mdhub, Inc. (“mdhub”) and Provider and is hereby incorporated by reference into the mdhub Terms of
            Service (“Agreement”) entered into by and between the Parties. This BAA, pursuant to which mdhub is a
            Business Associate of Provider, is applicable only where Provider is a Covered Entity or a Business
            Associate and only when mdhub is acting as a Business Associate as defined in 45 CFR § 160.103. If
            there is any conflict between a provision in this BAA and a provision in the Agreement, this BAA will
            control.
        </p>
        <h1>1. Definitions.</h1>
        <p>
            Except as otherwise defined in this BAA, capitalized terms shall have the definitions
            set forth in 45 CFR Part 160 and Part 164 (“HIPAA Rules), as amended from time to time.
        </p>
        <p>
            “Protected Health Information” shall have the same meaning as the term “protected health information” in
            45 CFR § 160.103 of the HIPAA Rules, provided that it is limited to such protected health information that
            is received by mdhub from, or created, received, maintained, or transmitted by mdhub on behalf of
            Provider, through the use of the Services.
        </p>
        <p>
            “Unsuccessful Security Incidents” means, without limitation, pings and other broadcast attacks on
            mdhub’s firewall, port scans, unsuccessful log-on attempts, denial of service attacks, and any
            combination of the above, as long as no such incident results in unauthorized access, acquisition, use, or
            disclosure of PHI.
        </p>
        <h1>2. Use and Disclosure of PHI.</h1>
        <ol class="d">
            <li>mdhub will not use or disclose PHI in a manner other than as permitted or required by the
                Agreement, this BAA, or by law. Mdhub retains the right to use PHI to create de-identified information in
                accordance with 45 C.F.R. §§ 164.502(d) and 164.514(a)-(c) and mdhub owns all such deidentified data.</li>
            <li>Except as otherwise limited in this BAA, mdhub may use or disclose PHI as reasonably
                necessary to provide the Services to Provider and to undertake other activities permitted or required of
                mdhub by the Agreement or this BAA; provided that such use or disclosure would not violate the HIPAA
                Rules if done by Provider, unless expressly permitted by Section 2(c).</li>
            <li>mdhub may use and disclose PHI in its possession for the proper management and
                administration of mdhub’s business and to carry out its legal responsibilities, provided that any such
                disclosure may only occur if (i) it is required by law; or (ii) mdhub obtains, in writing, prior to making
                any
                disclosure to a third party (1) reasonable assurances from this third party that the PHI will be held
                confidential as provided under this BAA and used or further disclosed only as required by law or for the
                purpose for which it was disclosed to this third party and (2) an agreement from this third party to notify
                mdhub of any breaches of the confidentiality of the PHI.</li>
        </ol>
        <h1>3. Safeguards.</h1>
        <p>
            mdhub will use reasonable and appropriate safeguards to prevent the use or
            disclosure of PHI other than as provided by the Agreement or this BAA and mdhub agrees to implement
            administrative, physical, and technical safeguards that reasonably and appropriately protect the
            confidentiality, integrity and availability of the electronic PHI that it creates, receives, maintains or
            transmits on behalf of Provider.
        </p>
        <h1>4. Reporting.</h1>
        <p>
            mdhub shall report to Provider: (1) any use and/or disclosure of PHI that is not
            permitted or required by this BAA of which mdhub becomes aware; (2) any Security Incident affecting PHI
            of which it becomes aware, provided that notice is hereby deemed given for Unsuccessful Security
            Incidents and no further notice of such Unsuccessful Security Incidents shall be given; and/or (3) any
            breach of Provider’s unsecured PHI that mdhub may discover. Notification of a breach will be made
            without unreasonable delay. Notification(s) under this section, if any, will be delivered to Provider
            pursuant to the notice section of the Agreement.
        </p>
        <h1>5. Agreements with Subcontractors.</h1>
        <p>
            mdhub shall require its subcontractors who create, receive,
            maintain, or transmit PHI on behalf of mdhub to agree in writing to (a) the same or no less restrictive
            restrictions and conditions that apply to mdhub with respect to such PHI; (b) appropriately safeguard the
            PHI; and (c) comply with the applicable requirements of 45 CFR Part 164 Subpart C of the Security Rule.
            mdhub remains responsible for its Subcontractors’ compliance with the obligations of this BAA.
        </p>
        <h1>6. Disclosure to the Secretary.</h1>
        <p>
            mdhub shall make available its internal practices, records, and
            books relating to the use and/or disclosure of PHI received from Provider to the Secretary of the
            Department of Health and Human Services for purposes of determining Provider’s compliance with
            HIPAA, subject to attorney-client and other applicable legal privileges.
        </p>

        <h1>7. Access to and Amendment of PHI.</h1>
        <p>
            To the extent that Business Associate maintains PHI in a
            Designated Record Set, Business Associate shall provide access to such PHI to Provider in a time and
            manner that meets the requirements of 45 C.F.R. § 164.524.
        </p>
        <h1>8. Accounting of Disclosure.</h1>
        <p>
            To the extent that Business Associate maintains PHI in a
            Designated Record Set, Business Associate shall make amendment(s) to such PHI in a Designated
            Record Set that Provider directs or agrees to in a time and manner that meets the requirements of
            45 C.F.R. § 164.526.
        </p>
        <h1>9. Performance of a Provider’s Obligations.</h1>
        <p>
            To the extent mdhub is to carry out a Covered Entity
            obligation under the Privacy Rule, mdhub shall comply with the requirements of the Privacy Rule that
            apply to Provider in the performance of such obligation.
        </p>
        <h1>10. Responsibilities of Provider.</h1>
        <p>
            With regard to the use and/or disclosure of PHI by mdhub,
            Provider agrees (a) Provider shall not request mdhub to use or disclose PHI in any manner that would not
            be permissible under HIPAA if done by Provider (except to the extent permitted by HPAA for a business
            associate), and (b) Provider is responsible for implementing appropriate privacy and security safeguards
            to protect its PHI in compliance with HIPAA.
        </p>
        <h1>11. Term and Termination.</h1>
        <p>
            This BAA shall continue in effect until the earlier of (1) termination by a
            Party for breach as set forth in this BAA, or (2) expiration of the Agreement.
        </p>
        <ol class="d">
            <li>Upon written notice, either Party immediately may terminate the Agreement and this BAA
                if the other Party is in material breach or default of any obligation in this BAA. Either party may provide
                the other a thirty (30) calendar day period to cure a material breach or default within such written notice.
            </li>
            <li>Upon expiration or termination of this BAA, mdhub shall return or destroy all PHI in its
                possession, if it is feasible to do so, and as set forth in the applicable termination provisions of the
                Agreement. If it is not feasible to return or destroy any portions of the PHI upon termination of this BAA,
                then mdhub shall extend the protections of this BAA, without limitation, to such PHI and limit any further
                use or disclosure of the PHI to those purposes that make the return or destruction infeasible for the
                duration of the retention of the PHI.</li>
        </ol>
        <h1>12. No Third Party Beneficiaries.</h1>

        <p>
            This BAA is between the parties hereto. Nothing express or implied
            in this BAA is intended to confer, nor shall anything herein confer, any rights, remedies, obligations, or
            liabilities whatsoever upon any person other than mdhub and Provider and any respective successors
            and assigns.
        </p>
    </div>
</template>
  
<script>
export default {
    name: 'PrivacyPolicy',
    mounted() {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }
};
</script>
  
<style scoped>
.privacy-policy {
    max-width: 800px;
    margin: auto;
    width: 50%;
    padding: 10px;
    text-align: left;

}

h1 {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: left;
}

p {
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: justify;
}
</style>
  