import { ref, onUnmounted } from 'vue';
import { getFirestore, collection, query, where, onSnapshot } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

export function useUnreviewedQuestionnairesCount() {
    const unreviewedQuestionnairesCount = ref(null);
    let unsubscribe = null;

    function listenForUnreviewedQuestionnaires() {
        const auth = getAuth();
        const db = getFirestore();

        const user = auth.currentUser;
        if (!user) {
            unreviewedQuestionnairesCount.value = 0;
            return;
        }

        const userId = user.uid;
        const questionnairesRef = collection(db, 'patient-questionnaires');
        const q = query(
            questionnairesRef,
            where('userId', '==', userId),
            where('submitted', '==', true),
            where('reviewed', '==', false)
        );

        unsubscribe = onSnapshot(q, (snapshot) => {
            unreviewedQuestionnairesCount.value = snapshot.size;
        }, (error) => {
            unreviewedQuestionnairesCount.value = null;
        });
    }

    // Start listening immediately
    listenForUnreviewedQuestionnaires();

    // Clean up the listener when the component is unmounted
    onUnmounted(() => {
        if (unsubscribe) {
            unsubscribe();
        }
    });

    return { unreviewedQuestionnairesCount };
}