import { ref } from "vue";
import { projectFirestore } from "../firebase/config";

const getDocumentOnce = async (collection, id) => {
	let document = ref(null);
	let error = ref(null);

	try {
		// Register the Firestore collection reference
		let documentRef = projectFirestore.collection(collection).doc(id);

		// Fetch the document once
		const doc = await documentRef.get();
		if (doc.exists) {
			document.value = { ...doc.data(), id: doc.id };
			error.value = null;
		} else {
			error.value = "That document does not exist";
			console.log("That document does not exist");
		}
	} catch (err) {
		console.log("In get document: ", err.message);
		error.value = "Problem fetching the document";
	}

	return { error, document };
};

export default getDocumentOnce;
