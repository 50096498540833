<template>
    <div>
        <TemplateWizzard />
        <div class="mx-auto ma-4 d-flex justify-end" style="width:700px;" elevation="0">
            <v-btn class="mdhub-btn mt-4 mr-2" variant="text" @click="handleSave" :loading="isPending"
                :disabled="!templateHeader">Save</v-btn>
        </div>

        <v-card class="mx-auto pa-4" width="700" elevation="1">
            <v-card-title class="text-start">Template Details</v-card-title>
            <v-card-subtitle class="text-start">Use a descriptive name to remember your template</v-card-subtitle>
            <v-card-text>
                <v-text-field class="mb-6 mt-4" v-model="templateHeader" color="accent" label="Name *"
                    variant="underlined" density="compact" :rules="[required]" required placeholder="e.g. SOAP" />

                <v-select class="mb-4" label="Note language" variant="underlined" density="compact"
                    v-model="selectedLanguage"
                    :items="['English', 'Spanish', 'French', 'German', 'Arabic', 'Chinese', 'Punjabi', 'Tagalog']"></v-select>

                <v-select class="mb-4" label="Specialty" variant="underlined" density="compact"
                    v-model="selectedSpeciality"
                    :items="['Psychiatry', 'Psychology', 'Child and Adolescent Psychiatry',
                        'Geriatric Psychiatry', 'Forensic Psychiatry', 'Addiction Psychiatry', 'Consultation-Liaison Psychiatry', 'Neuropsychiatry', 'Psychosomatic Medicine', 'Emergency Psychiatry', 'Sleep Medicine', 'Clinical Psychology', 'Counseling Psychology', 'School Psychology', 'Industrial-Organizational Psychology', 'Health Psychology', 'Neuropsychology', 'Forensic Psychology', 'Sports Psychology', 'Developmental Psychology', 'Social Psychology', 'Experimental Psychology',
                        'Community Psychology', 'Anesthesiology', 'Cardiology', 'Dermatology', 'Emergency Medicine', 'Endocrinology', 'Family Medicine', 'Gastroenterology', 'General Surgery', 'Geriatrics', 'Hematology', 'Infectious Disease', 'Internal Medicine', 'Nephrology', 'Neurology', 'Nutritionist', 'Obstetrics and Gynecology', 'Oncology', 'Ophthalmology', 'Orthopedics', 'Otolaryngology', 'Pediatrics', 'Physical Medicine and Rehabilitation', 'Plastic Surgery', 'Pulmonology', 'Radiology', 'Rheumatology', 'Urology', 'Other',]"></v-select>

                <div class="d-flex align-center justify-space-between">
                    <div class="text-start">
                        <p>Share with the community</p>
                        <div class="text-start">
                            <v-btn-toggle v-model="shareCommunity" rounded="0" color="#FDBEB4" group mandatory>
                                <v-btn :value="true">
                                    Yes
                                </v-btn>

                                <v-btn :value="false">
                                    No
                                </v-btn>
                            </v-btn-toggle>
                        </div>
                    </div>
                    <div v-if="isUserAdmin" class="text-start">
                        <p>Share with the clinic</p>
                        <div class="text-start">
                            <v-btn-toggle v-model="shareClinic" rounded="0" color="#FDBEB4" group mandatory>
                                <v-btn :value="true">
                                    Yes
                                </v-btn>

                                <v-btn :value="false">
                                    No
                                </v-btn>
                            </v-btn-toggle>
                        </div>
                    </div>
                </div>
            </v-card-text>
        </v-card>

        <v-container group="sections">
            <draggable v-model="inputValues" group="sections" @start="drag = true" @end="drag = false">
                <template #item="{ element, index }">
                    <v-card class="mx-auto mb-3" elevation="1" width="700" :disabled='!isUserAdmin && shareClinic'>
                        <v-card-text>
                            <TemplateSection :element="element" :index="index" />
                        </v-card-text>
                        <v-card-actions>
                            <TemplateSectionActions @save="handleSave" @remove="remove(index)" @moveUp="moveUp(index)"
                                @moveDown="moveDown(index)" :isPendingSave="isPending"
                                :templateHeader="templateHeader" />
                        </v-card-actions>
                    </v-card>
                </template>
            </draggable>
        </v-container>

        <v-card class="mx-auto pa-4" width="700" elevation="0">
            <v-btn class="ml-16" variant="plain" @click="addInputField" :disabled='!isUserAdmin && shareClinic'> Add new
                section</v-btn>
        </v-card>

        <Feedback :show-on-left="true" />

    </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import draggable from 'vuedraggable'
import TemplateSection from '../components/TemplateSection.vue';
import TemplateSectionActions from '../components/TemplateSectionActions.vue';
import TemplateWizzard from '@/components/TemplateWizzard.vue';
import getDocument from '@/composables/getDocument'
import getCollection from '@/composables/getCollection'
import useDocument from '@/composables/useDocument'
import useCollection from '@/composables/useCollection'
import getUser from '@/composables/getUser'
import { timestamp } from '@/firebase/config'
import { trackEvent, TEMPLATE_SHARED, CREATE_CLINICAL_TEMPLATE, UPDATE_CLINICAL_TEMPLATE } from '@/utilities/analyticsService';
import { useRouter } from 'vue-router';
import Feedback from '@/components/Feedback.vue';

const { err, addDoc } = useCollection('summary-templates');
const { user } = getUser();
const router = useRouter()


const templateHeader = ref('My new template')
const selectedLanguage = ref('English')
const selectedSpeciality = ref('Psychiatry');
const shareCommunity = ref(false);
const isUserAdmin = ref(false);
const shareClinic = ref(false);
const inputValues = ref(
    [
        { item: '', dlevel: '', style: 'Bullet points' },
        { item: '', dlevel: '', style: 'Bullet points' },
        { item: '', dlevel: '', style: 'Bullet points' },
        { item: '', dlevel: '', style: 'Bullet points' }
    ])
const drag = ref(false)

const required = (v) => !!v || 'Field is required';

const clinicId = ref(null);
const { documents: userObject } = getCollection('user');

watch(userObject, () => {
    clinicId.value = userObject.value[0]?.clinicId || null
    isUserAdmin.value = userObject.value[0]?.isAdmin || false;
})

const isPending = ref(false);
const handleSave = async () => {
    isPending.value = true;
    const { error, document: clinic } = clinicId.value ? getDocument('clinics', clinicId.value) : {}
    let customTemplate = inputValues.value
        .filter(value => value.item !== '')
        .map(value => {

            var sectionTitle = value.item
            var sectionStyle = value.style
            var sectionOwnWords = value.dlevel.replaceAll('/', ',')

            return sectionTitle + "/" + sectionOwnWords + "/" + sectionStyle;
        });
    console.log('customTemplate create', customTemplate)
    const res = await addDoc({
        template: customTemplate,
        templateHeader: templateHeader.value,
        userId: user.value.uid,
        userName: user.value.displayName,
        createdAt: timestamp(),
        language: selectedLanguage.value,
        selectedSpeciality: selectedSpeciality.value,
        shareCommunity: shareCommunity.value,
        shareClinic: shareClinic.value,
        clinicId: shareClinic.value ? clinicId.value : null
    });



    if (shareClinic.value && clinicId.value) {
        const { updateDocument: updateClinic } = await useDocument('clinics', clinicId.value);
        const newTemplate = {
            id: res.id,
            name: templateHeader.value

        };

        await updateClinic({
            templates: [...clinic?.value.templates, newTemplate]
        });

        trackEvent(CREATE_CLINICAL_TEMPLATE, { userId: user.value.uid, email: user.value.email, clinicId: clinicId.value, templateId: res.id });

    }


    isPending.value = false;

    router.push({ name: 'Template', params: { id: res.id } })

}

const addInputField = () => {
    inputValues.value.push({ item: '', dlevel: '', style: 'Bullet points' });

}

const moveUp = (index) => {
    if (index > 0) {
        const item = inputValues.value.splice(index, 1)[0];
        inputValues.value.splice(index - 1, 0, item);
    }
}
const moveDown = (index) => {
    if (index < inputValues.value.length - 1) {
        const item = inputValues.value.splice(index, 1)[0];
        inputValues.value.splice(index + 1, 0, item);
    }
}

const remove = (index) => {
    inputValues.value.splice(index, 1);
}

</script>
