import { ref } from "vue";
import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import getUser from "../composables/getUser";

export const subscriptionStatus = {
  ACTIVE: "active",
  TRIALING: "trialing",
  INCOMPLETE: "incomplete",
  INCOMPLETE_EXPIRED: "incomplete_expired",
  PAST_DUE: "past_due",
  CANCELED: "canceled",
  UNPAID: "unpaid",
  PAUSED: "paused",
};
const { user } = getUser();
const db = getFirestore();

export function useSubscription(userId) {
  const isLoading = ref(false);
  const subscription = ref(null);
  async function fetchSubscription() {
    isLoading.value = true;
    const subsRef = collection(
      db,
      "customers",
      userId || user.value.uid,
      "subscriptions"
    );
    // trial should take into consideration the amount of sessions
    // active means that the payment has succeeded
    // Subscription doesnt require payment when in trialing or active
    // The subscription’s status remains active as long as automatic payments succeed.
    // If automatic payment fails, the subscription updates to past_due and Stripe attempts to recover payment based on your retry rules.
    // If payment recovery fails, you can set the subscription status to canceled, unpaid, or leave it past_due.

    // The Below means that the user has an active subscription (either paid one or in trial)
    const subsQuery = query(
      subsRef,
      where("status", "in", [
        subscriptionStatus.TRIALING,
        subscriptionStatus.ACTIVE,
        // where("status", "in", ["past_due", "unpaid"]) --> plus the trialing and active
      ])
    );

    // has subscription when in active or trialing in all other cases the banner should appear
    // and will return null
    const subSnapshot = await getDocs(subsQuery);
    subscription.value =
      subSnapshot.docs.length > 0 ? subSnapshot.docs[0].data() : null;
    isLoading.value = false;
  }

  return { fetchSubscription, subscription, isLoading, subscriptionStatus };
}

export function useBlockedUsers(userId) {
  const isUserBlocked = ref(false);
  async function fetchBlockedUsers() {
    const blockedRef = collection(db, "blocked-users");
    const blockedQuery = query(
      blockedRef,
      where("userId", "==", userId || user.value.uid)
    );
    const blockedSnapshot = await getDocs(blockedQuery);
    isUserBlocked.value = blockedSnapshot.docs.length > 0;
  }

  return { fetchBlockedUsers, isUserBlocked };
}
