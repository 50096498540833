import { projectStorage } from "@/firebase/config";
import { trackEvent, ERROR_UPLOAD_AUDIO } from '@/utilities/analyticsService';

 async function uploadAudio(patientId, sessionId, file, userId, userEmail) {
    console.log('Starting audio upload for session', { sessionId });

    const audioFilePath = `/${userId}/${patientId}/${sessionId}/${sessionId}.webm`;
    const metadata = { contentType: 'audio/webm' };

    const retries = 2;

    const fileStorageRef = projectStorage.ref(audioFilePath);

    for(let tryCount = 0; tryCount <= retries; tryCount++) {
        try {
            const result = await fileStorageRef.put(file, metadata);
            console.log('File upload result', { sessionId, result });
            console.log('Successfully uploaded file to storage', { sessionId });
            
            break;
        } catch (error) {
            console.warn(`Failed to upload file on attempt ${tryCount + 1}`, { sessionId, error });
            trackEvent(ERROR_UPLOAD_AUDIO, 
                { 
                    userId: userId, 
                    userEmail: userEmail, 
                    patientId: patientId, 
                    sessionId: sessionId, 
                    message: error.message, 
                    attempt: tryCount 
                }
            )

            if (tryCount === retries) {
                console.error(`Failed to upload file after ${retries} retries`, { sessionId, error });
                throw error;
            } else {
                console.info(`Will retry file upload after error`, { sessionId, error });
            }

            continue;        
        }
    }
}

export default uploadAudio