<template>
    <div class="privacy-policy">
        <h2>MDHUB PRIVACY NOTICE</h2>
        <h6>Last Updated: May 26, 2023</h6>
        <p>
            Welcome! This Privacy Notice explains how mdhub, Inc., and its affiliates and/or subsidiaries (“mdhub”,
            “we”, “us” or “our”) collects, uses, discloses, and otherwise processes personal information (as defined
            below) in connection with our websites (the “Sites”) and mobile applications that we own and operate that
            link to this Privacy Notice, and the related content, platform, services, products, mobile apps, and other
            functionality offered on or through our services (collectively, the “Services”). It does not address our
            privacy practices relating to mdhub employees and other personnel.
            mdhub may provide Services to health professionals (“mdhub Clients”). In the course of providing such
            Services, we may collect your personal information on behalf of the mdhub Customers. We refer to this
            type of personal information as “Customer Data.” We collect and process Customer Data on behalf of the
            mdhub Customers subject to restrictions set forth in our contracts with them, including HIPAA business
            associate agreements. This Privacy Notice does not apply to our collection and processing of Customer
            Data. We recommend referring to the mdhub Customers’ privacy notice, including their HIPAA Notice of
            Privacy Practices (as applicable) for information on how they engage service providers, like us, to collect
            and process Customer Data on their behalf.
        </p>
        <h1>I. WHAT IS PERSONAL INFORMATION?</h1>
        <p> When we use the term “personal information” in this Privacy Notice, we mean any data or information
            that identifies, relates to, describes, is capable of being associated with, or could reasonably be linked,
            directly or indirectly, with a particular natural person or household or any other data or information that
            constitutes “personal information”, “personal information,” or “personally identifiable information.”
        </p>
        <h1>II. OUR COLLECTION AND USE OF PERSONAL INFORMATION</h1>
        <p>
            We collect personal information in a variety of ways. For example, you may provide us your personal
            information when you apply to be a provider, send us messages, subscribe to our mailing lists,
            newsletters or other forms of marketing communications, participate in a survey, or use some other
            feature of our Services.
            We may link or combine your activities and information collected from you on our websites and mobile
            apps with information we receive from third parties, as well as information we collect automatically
            through tracking technologies (defined below). This allows us to provide you with a personalized
            experience regardless of how you interact with us.
        </p>
        <p>
            Personal Information Collected from You
            We may collect the following categories of personal information submitted to us by individuals through the
            Services:
        </p>
        <p>
            <li> Provider Information, including first and last name, phone number, state of licensure, medical
                license number, specialty, referral information, current practice setting, whether you are board
                certified, and information regarding how you learned about mdhub. We use this information to
                administer your account, provide you with the relevant service and information, communicate with
                you regarding your account and your use of the Services, and for customer support purposes.</li>

            <li>Inquiry and Communications Information, including information provided in custom messages
                sent through the forms, in chat messages, to one of our email addresses, or via phone. This also
                includes contact information provided on our Services. We use this information to investigate and
                respond to your inquiries, and to communicate with you, to enhance the services we offer to our
                users and to manage and grow our organization.</li>

            <li>Newsletter and Marketing Emails, including email address and applicable interests and
                communication preferences. We use this information to manage our communications with you
                and send you information about products and services we think may be of interest to you. If you
                wish to stop receiving email messages from us, simply click the “unsubscribe link” provided at the
                bottom of the email communication. Note that you cannot unsubscribe from certain
                services-related email communications (e.g., account verification, confirmations of transactions,
                technical or legal notices).</li>

            <li>Feedback Information. We may also collect feedback and ratings you provide relating to our
                services or products. We use this information to communicate with you, to conduct market
                research, inform our marketing and advertising activities and improve and grow our business.</li>

            <li>Recordings and transcription. With your permission, we collect voice recordings and request
                access to the microphone on your mobile device in order to facilitate recordings for the Service.
                These recordings will automatically transcribe text used as part of the Provider session with
                patients and will leverage generative AI models to provide enhanced functionality as part of the
                Services. Note the information collected through these recordings is subject to restrictions set
                forth in our HIPAA business associate agreements with Providers and is not subject to this
                Privacy Notice. You may withdraw permission to access your device’s microphone at any time
                from the ‘Settings’ app of your mobile device. You agree to comply with all applicable laws,
                whether federal, state, local or international, relating to the privacy of communication for all
                parties to a conversation, including without limitation, advising all participants in a recorded
                conversation that the conversation is being recorded and obtaining their consent, as required.</li>
        </p>
        <p>
        <p>Personal Information from Third Parties
            We also obtain personal information from third parties; which we often combine with personal information
            we collect either automatically or directly from an individual.
            We may receive the same categories of personal information as described above from the following third
            parties:</p>

        <li>mdhub Entities: We may receive personal information from other companies and brands owned
            or controlled by mdhub, and other companies owned by or under common ownership as mdhub.</li>
        <li>Service Providers: Our service providers that perform services solely on our behalf, such as
            machine learning and/or generative artificial intelligence services, survey and marketing
            providers and payment processors, collect personal information and often share some or all of
            this information with us. The information may include contact information, demographic
            information, payment information, information about your communications and related activities,
            and information about your use of the Services. We may use this information to administer and
            facilitate our services, your orders, and our marketing activities.</li>
        <li>Business Partners: We may receive your information from our business partners, such as
            companies that offer their products and/or services on our Services. We may use this information
            to administer and facilitate our Services, your orders, and our marketing activities.</li>

        <li>Social Media: When you interact with our Services through various social media networks, such
            as when someone “Likes” us on Facebook or follows us or shares our content on Facebook,
            LinkedIn, Twitter, or other social networks, we may receive some information about individuals
            that they permit the social network to share with third parties. The data we receive is dependent
            upon your privacy settings with the social network. You should always review and, if necessary,
            adjust their privacy settings on third-party websites and social media networks and services
            before sharing information and/or linking or connecting them to other services. We use this
            information to operate, maintain, and provide to you the features and functionality of the
            Services, as well as to communicate directly with you, such as to send you email messages
            about products and services that may be of interest to you.</li>
        <li>Other Sources: We may also collect personal information about you that we do not otherwise
            have from, for example, publicly available sources, third-party data providers, brand
            partnerships, or through transactions such as mergers and acquisitions. We use this information
            to operate, maintain, and provide to you the features and functionality of the Services, as well as
            to communicate directly with you, such as to send you email messages about products and
            services that may be of interest to you.</li>
        </p>
        <p>
            Through the provision of our Services, we may also process deidentified information that cannot
            reasonably be used to infer information about, or otherwise be linked to, a particular consumer or
            household. mdhub commits to maintain and use the information in deidentified form and will not attempt
            to reidentify the information, except in instances where necessary for determining whether the
            deidentification process used by mdhub satisfies the requirements under applicable law.
            Additional Uses of Personal Information
            We may use personal information we collect to:
        </p>
        <p>
            <li>Fulfill or meet the reason the information was provided, such as to fulfill our contractual
                obligations, to deliver the Services you have requested;</li>
            <li>Verify your identity and entitlement to Services when you contact us or access our Services;</li>
            <li>Communicate with you, including via email, social media, and phone calls;</li>
            <li>Request you to complete surveys about our company, our Providers, and our Services;</li>
            <li>For marketing and advertising purposes, including to market to you or offer you through email,
                direct mail, phone or text message information and updates on products or services we think that
                you may be interested in;</li>
            <li>Administer, improve, and personalize our Services, including by recognizing you and
                remembering your information when you return to our Services or using generative artificial
                intelligence and machine learning tools that may be integrated into our Services</li>
            <li>Process payment for our Services;</li>
            <li>Facilitate customer support services;</li>
            <li>Identify and analyze how you use our Services, and improve and customize our Services to
                address the needs and interests of our user base and other individuals we interact with;</li>
            <li>Test, enhance, update and monitor the Services, or diagnose or fix technology problems;</li>

            <li>To enforce our <a href="https://www.mdhub.ai/terms-of-service">Terms of Service</a>, to resolve disputes, to
                carry out our obligations and enforce our rights, and to protect our business interests and the interests
                and rights of third parties;</li>
            <li>Prevent, investigate, or provide notice of fraud or unlawful or criminal activity;</li>
            <li>Comply with contractual and legal obligations and requirements;</li>
            <li>For any other lawful purpose, or other purpose that you consent to.</li>
            Where you choose to contact us, we may need additional information to fulfill the request or respond to
            inquiries. We may provide you with additional privacy-related information where the scope of the
            inquiry/request and/or personal information we require fall outside the scope of this Privacy Notice. In
            that
            case, the additional privacy notice will govern how we may process the information provided at that time.


        </p>
        <h1>III. HOW WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES TO COLLECT INFORMATION</h1>
        <p>
            We, and our third-party partners, automatically collect information you provide to us and information about
            how you access and use the Services when you visit our services, read our emails, or otherwise engage
            with us. We typically collect this information through a variety of tracking technologies, including (i)
            cookies or small data files that are stored on an individual’s computer and (ii) other, related technologies,
            such as web beacons, pixels, embedded scripts, mobile SDKs, location-identifying technologies and
            logging technologies (collectively, “tracking technologies”) and we may use third-party partners or
            technologies to collect this information. Information we collect automatically about you may be combined
            with other personal information we collect directly from you or receive from other sources.
            We, and our third-party partners, use tracking technologies to automatically collect usage and device
            information, such as:
            <li>Information the computer, tablet, smartphone or other device you use, such as your IP
                address, browser type, Internet service provider, device type/model/manufacturer, operating
                system, date and time stamp, and a unique ID that allows us to uniquely identify your browser,
                mobile device, or your account (including, for example, a persistent device identifier or an AdID),
                and other such information. We may also work with third-party partners to employ technologies,
                including the application of statistical modeling tools, which permit us to recognize and contact
                you across multiple devices.</li>
            <li>Information about the way you access and use our Services, for example, the site from which
                you came and the site to which you are going when you leave our services, how frequently you
                access the Services, whether you open emails or click the links contained in emails, whether you
                access the services from multiple devices, and other browsing behavior and actions you take on
                the Sites.</li>
            <li>Information about how you use the Services, such as the pages you visit, the links you click,
                the ads you view and click on, videos you watch, and other similar actions. We may also use
                third-party tools to collect information you provide to us or information about how you use the
                Services and may record browsing, search or purchasing behavior activities. These tools may
                also record information you enter when you interact with our Services or engage in chat features
                through our Services.</li>
            <li>Information about your location, such as general geographic location that we or our third-party
                providers may derive from your IP address.</li>

            <li>Analytics information. We may collect analytics data or use third-party analytics tools such as
                Google Analytics to help us measure traffic and usage trends for the services and to understand
                more about the demographics of our users. You can learn more about Google’s practices
                at <a
                    href="http://www.google.com/policies/privacy/partners">http://www.google.com/policies/privacy/partners</a>
                and view its opt-out options
                at <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a> .</li>

            All of the information collected automatically through these tools allows us to improve your customer
            experience. For example, we may use this information to enhance and personalize your user experience,
            to monitor and improve our Sites and Services, and to improve the effectiveness of our Services, offers,
            advertising, communications and customer service. We may also use this information the data collected
            through tracking technologies to: (a) remember information so that you will not have to re-enter it during
            your visit or the next time you visit the site; (b) provide custom, personalized content and information,
            including targeted content and advertising; (c) identify you across multiple devices; (d) provide and
            monitor the effectiveness of our services; (e) monitor aggregate metrics such as total number of visitors,
            traffic, usage, and demographic patterns on our website; (f) diagnose or fix technology problems; and (g)
            otherwise to plan for and enhance our services.
            If you would prefer not to accept cookies, most browsers will allow you to: (i) change your browser
            settings to notify you when you receive a cookie, which lets you choose whether or not to accept it; (ii)
            disable existing cookies; or (iii) set your browser to automatically reject cookies; however, doing so may
            negatively impact your experience using the services, as some features and services may not work
            properly. You may also set your email options to prevent the automatic downloading of images that may
            contain technologies that would allow us to know whether you have accessed our email and performed
            certain functions with it.
            We and our third-party partners may also use cookies and tracking technologies for advertising purposes.
            For more information about tracking technologies, please see Third-Party Data Collection and Online
            Advertising below.
        <h1>IV. OUR DISCLOSURE OF PERSONAL INFORMATION</h1>

        We may also share, transmit, disclose, grant access to, make available, and provide personal information
        with and to third parties, as follows:

        <li>mdhub Entities: We may share personal information with other companies owned or controlled
            by mdhub, and other companies owned by or under common ownership as mdhub, which also
            includes our subsidiaries (i.e., any organization we own or control) or our ultimate holding
            company (i.e., any company that owns or controls us) and any subsidiaries it owns, particularly
            when we collaborate in providing the Services.</li>
        <li>Marketing Providers: We coordinate and share personal information with our marketing
            providers in order to communicate with individuals about the Services we make available.</li>
        <li>Customer Service and Communication Providers: We share personal information with third
            parties who assist us in providing our customer services and facilitating our communications with
            individuals that submit inquiries.</li>
        <li>Survey Providers: We share personal information with third parties who assist us in delivering
            our survey offerings and processing the responses.</li>
        <li>Other Service Providers: In addition to the third parties identified above, we engage other
            third-party service providers that perform business or operational services for us or on our behalf,
            such as website hosting, infrastructure provisioning, IT services, analytics services, employment
            application-related services, payment processing services, and administrative services.</li>
        <li>Ad Networks and Advertising Partners: We work with third-party ad networks and advertising
            partners to deliver advertising and personalized content on our Services, on other websites and
            services, and across other devices. These parties may collect information directly from a browser
            or device when an individual visits our Services through cookies or other data collection
            technologies. This information is used to provide and inform targeted advertising, as well as to
            provide advertising-related services such as reporting, attribution, analytics and market research.
            For more information, please see the Third-Party Data Collection and Online Advertising
            section.</li>
        <li>Business Partners: From time to time, we may share personal information with our business
            partners or we may allow our business partners to collect your personal information. Our
            business partners will use your information for their own business and commercial purposes,
            including to send you any information about their products or services that we believe will be of
            interest to you.</li>
        <li>Business Transaction or Reorganization: We may take part in or be involved with a corporate
            business transaction, such as a merger, acquisition, joint venture, or financing or sale of company
            assets. We may disclose personal information to a third-party during negotiation of, in connection
            with or as an asset in such a corporate business transaction. Personal information may also be
            disclosed in the event of insolvency, bankruptcy or receivership.</li>
        <li> Legal Obligations and Rights: We may disclose personal information to third parties, such as
            legal advisors and law enforcement:
            <ol>
                <li> in connection with the establishment, exercise, or defense of legal claims;</li>
                <li>to comply with laws or to respond to lawful requests and legal process;</li>
                <li>to protect our rights and property and the rights and property of others, including to
                    enforce our agreements and policies;</li>
                <li>to detect, suppress, or prevent fraud;</li>
                <li>to protect the health and safety of us and others; or</li>
                <li>as otherwise required by applicable law.</li>
            </ol>
        </li>
        <li>With Your Consent: We may disclose personal information about you to certain other third
            parties or publicly with their consent or direction. For example, with your consent or direction we
            may share your information with family members.</li>
        <h1>V. THIRD-PARTY DATA COLLECTION AND ONLINE ADVERTISING</h1>
        We may participate in interest-based advertising and use third party advertising companies to serve you
        targeted advertisements based on your browsing history. We may permit third party online advertising
        networks, social media companies and other third-party services, to collect information about your use of
        our online services over time so that they may play or display ads on our Services, on other websites or
        services you may use, and on other devices you may use. Typically, though not always, the information
        used for interest-based advertising is collected through tracking technologies, such as cookies, web
        beacons, embedded scripts, location-identifying technologies, and similar technology, which recognize the
        device you are using and collect information, including click stream information, browser type, time and
        date you visited the Sites, AdID, precise geolocation and other information. We may share a common
        account identifier (such as a hashed email address or user ID) with our third-party advertising partners to
        help identify you across devices. We and our third-party partners use this information to make the
        advertisements you see online more relevant to your interests, as well as to provide advertising-related
        services such as reporting, attribution, analytics and market research. We may also use services provided
        by third parties (such as social media platforms) to serve targeted ads to you and others on such
        platforms. We may do this by providing a hashed version of your email address or other information to the
        platform provider.
        We may engage in the following:
        <li>Social Media Platforms. We may display targeted advertising to you through social media
            platforms, such as Twitter, Instagram, LinkedIn, and other social media forums. These companies
            have interest-based advertising programs that allow us to direct advertisements to users who
            have shown interest in our services while those users are on the social media platform, or to
            groups of other users who share similar traits, such as likely commercial interests and
            demographics. We may share a unique identifier, such as a user ID, with these platform providers
            or they may collect information from our website visitors through a first-party pixel, in order to
            direct targeted advertising to you or to a custom audience on the social media platform. These
            advertisements are governed by the privacy policies of those social media companies that
            provide them. If you do not want to receive targeted ads on your social networks, you may be
            able to adjust your advertising preferences through your settings on those networks.</li>
        <li>Third Party Partners. We work with a variety of third-party partners to provide advertising
            services. For example, we use Google Analytics to recognize you and link the devices you use
            when you visit our Services on your browser or mobile device, log in to your account on our
            Services, or otherwise engage with us. We share a unique identifier, like a user ID, with Google
            to facilitate the service. Google Analytics allows us to better understand how our users interact
            with our Services and to tailor our advertisements and content to you. For information on how
            Google Analytics collects and processes data, as well as how you can control information sent to
            Google, review Google's website, “How Google uses data when you use our partners’ sites or
            apps” located at <a
                href="www.google.com/policies/privacy/partners/">www.google.com/policies/privacy/partners/</a> . You can
            learn about Google
            Analytics’ currently available opt-outs, including the Google Analytics Browser Ad-On
            here: <a href="https://tools.google.com/dlpage/gaoptout/">https://tools.google.com/dlpage/gaoptout/</a> .</li>
        </p>
        <p>
            We may also utilize certain forms of display advertising and other advanced features through Google
            Analytics. These features enable us to use first-party cookies (such as the Google Analytics cookie) and
            third-party cookies (such as the DoubleClick advertising cookie) or other third-party cookies together to
            inform, optimize, and display ads based on your past visits to the Services. You may control your
            advertising preferences or opt-out of certain Google advertising products by visiting the Google Ads
            Preferences Manager, currently available at <a
                href="https://google.com/ads/preferences">https://google.com/ads/preferences</a>, or by visiting NAI’s
            online resources at <a
                href="http://www.networkadvertising.org/choices">http://www.networkadvertising.org/choices</a> .
        </p>
        <p>
            To learn more about interest-based advertising and how you may be able to opt-out of some of this
            advertising, you may wish to visit the Digital Advertising Alliance’s (DAA) resources and/or the Network
            Advertising Initiative’s (NAI) online resources, at <a
                href="www.aboutads.info/choices">www.aboutads.info/choices</a> or
            <a href="http://www.networkadvertising.org/choices/">http://www.networkadvertising.org/choices/</a>. You may
            also be able to limit interest-based advertising
            through the settings menu on your mobile device by selecting “limit ad tracking” (iOS) or “opt-out of
            interest-based ads” (Android). You may also be able to opt-out of some — but not all — interest-based
            advertising served by mobile ad networks by visiting <a
                href="http://youradchoices.com/appchoices">http://youradchoices.com/appchoices</a> and
            downloading the mobile AppChoices app.
        </p>
        <p>
            Please note that when you opt out of receiving interest-based advertisements, this does not mean you will
            no longer see advertisements from us or on our online services. It means that the online ads that you do
            see from DAA program participants should not be based on your interests. We are not responsible for the
            effectiveness of, or compliance with, any third-parties’ opt-out options or programs or the accuracy of their
            statements regarding their programs. In addition, third parties may still use cookies to collect information
            about your use of our online services, including for analytics and fraud prevention as well as any other
            purpose permitted under the DAA’s Principles.
        </p>
        <p>
        <h1>VI. CONTROL OVER YOUR INFORMATION</h1>
        You may control your information in the following ways:

        <li>Access to Your Device Information. You may control the Services’ access to your device
            information through your “Settings” app on your device. For instance, you can withdraw
            permission for the Services to access your camera and microphone.</li>
        <li>Email Communications Preferences. You can stop receiving promotional email
            communications from us by clicking on the “unsubscribe” link provided in such communications.
            You may not opt-out of service-related communications (e.g., account verification, transactional
            communications, changes/updates to features of the Services, technical and security notices).</li>
        <li>Push Notifications. You can stop receiving push notifications from us by changing your
            preferences in the iOS or Android notifications settings menu.</li>
        <li>Modifying or Deleting Your Information. If you have any questions about reviewing, modifying,
            or deleting your information, you can contact us directly at support@mdhub.ai. We may not be
            able to modify or delete your information in all circumstances.</li>
        </p>
        <h1>VII. CHILDREN’S PERSONAL INFORMATION</h1>
        <p>
            Our Services are not directed to, and we do not intend to, or knowingly, collect or solicit personal
            information from children under the age of 16. If an individual is under the age of 16, they should not use
            our Services or otherwise provide us with any personal information either directly or by other means. If a
            child under the age of 16 has provided personal information to us, we encourage the child’s parent or
            guardian to contact us to request that we remove the personal information from our systems. If we learn
            that any personal information we collect has been provided by a child under the age of 16, we will
            promptly delete that personal information.
        </p>
        <h1>VIII. LINKS TO THIRD-PARTY WEBSITES OR SERVICES</h1>
        <p>
            Our Services may include links to third-party websites, plug-ins and applications. Except where we post,
            link to or expressly adopt or refer to this Privacy Notice, this Privacy Notice does not apply to, and we are
            not responsible for, any personal information practices of third-party websites and online services or the
            practices of other third parties. To learn about the personal information practices of third parties, please
            visit their respective privacy notices.
        </p>
        <h1>IX. UPDATES TO THIS PRIVACY NOTICE</h1>
        <p>
            We may update this Privacy Notice from time to time. When we make changes to this Privacy Notice, we
            will change the date at the beginning of this Privacy Notice. If we make material changes to this Privacy
            Notice, we will notify individuals by email to their registered email address, by prominent posting on our
            Services, or through other appropriate communication channels. All changes shall be effective from the
            date of publication unless otherwise provided.
        <h1>X. CONTACT US</h1>
        If you have any questions or requests in connection with this Privacy Notice or other privacy-related
        matters, please send an email to privacy@mdhub.ai.
        </p>
    </div>
</template>
  
<script>
export default {
    name: 'PrivacyPolicy',
    mounted() {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }
};
</script>
  
<style scoped>
.privacy-policy {
    max-width: 800px;
    margin: auto;
    width: 50%;
    padding: 10px;
    text-align: left;

}

h1 {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: left;
}

p {
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: justify;
}
</style>
  