<template>
    <div class="delete-account">
        <h2>DELETE ACCOUNT</h2>
        <br>
        <p>
            Please send an email to
            <a href="mailto:support@mdhub.ai">support@mdhub.ai</a> in order to delete your account.
            <br>
            Our Customer Care Team will delete the account and send a confirmation email.
            <br>
            We are sad to see you go.
        </p>

    </div>
</template>
  
<script>
export default {
    name: 'DeleteAccount',
};
</script>
  
<style scoped>
.delete-account {
    max-width: 800px;
    margin: auto;
    width: 50%;
    padding: 10px;
    text-align: left;

}

h1 {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: left;
}

p {
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: justify;
}
</style>
  