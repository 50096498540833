

<template>
    <v-container>
        <!-- Row for Text and Image -->
        <h1>Welcome to mdhub pro!</h1>
        <v-row align="center" justify="center">

            <!-- Text Column -->
            <v-col cols="12" md="6">

                <p>Enjoy the following features:</p>

                <!-- Features List -->
                <v-list dense>
                    <v-list-item v-for="feature in features" :key="feature">
                        <v-list-item-content>{{ feature }}</v-list-item-content>
                    </v-list-item>
                </v-list>

            </v-col>

            <!-- Image Column -->
            <v-col cols="12" md="6">
                <v-img class="rounded-xl rounded-be-0" :src="require('@/assets/thankyou.jpg')"
                    alt="Descriptive Image Text"></v-img>
            </v-col>

        </v-row>

        <!-- Back Button -->

        <a href="javascript:window.history.go(-1)" class="text-sm font-semibold text-gray-900 mt-9"
            style="text-decoration:none;"><span aria-hidden="true">←</span> Back to the previous page</a>

    </v-container>
</template>
  
<script>
export default {
    setup() {
        const features = ['Feature 1', 'Feature 2']; // Add more features here

        const goBack = () => {
            window.history.go(-1);
        };

        return { features, goBack };
    },
};
</script>
  