<template>
    <div v-if="audioLoading" class="d-flex flex-column align-center mt-8">
        <p class="text-caption">Loading audio... </p>
        <v-progress-circular color="primary" indeterminate size="32"></v-progress-circular>
    </div>

    <div v-if="!audioUrl && !audioLoading" class="audio-cover">
        Audio deleted or not found
    </div>
    <div v-if="audioUrl" class="d-flex flex-column mt-8">
        <div>
            <audio class="" id="my-audio" controls preload="auto" style="flex-grow: 1;">
                <source :src="audioUrl" type="audio/mpeg">
                Your browser does not support the audio tag.
            </audio>
        </div>
        <div class=" d-flex flex-column align-start ml-6">
            <v-btn class="mt-4" variant="plain" prepend-icon="mdi-trash-can-outline" @click="deleteAudio"
                :disabled="isDisabledDeleteAudio">
                <span>Delete audio</span> </v-btn>


            <v-tooltip text="Create transcript again ">
                <template v-slot:activator="{ props }">
                    <v-btn class="mt-4" v-bind="props" variant="plain" density="compact" @click="newTranscript"
                        prepend-icon="mdi-refresh" :disabled="isDisabledRegenerateTranscript"
                        :loading="isPending">Regenerate transcript</v-btn>

                </template>
            </v-tooltip>
        </div>

    </div>
</template>

<script>

import getAudio from '@/composables/getAudio'
import { ref, watch } from 'vue'
import { getFunctions, httpsCallable } from "firebase/functions";
import { trackEvent, DELETE_AUDIO } from '@/utilities/analyticsService';
import getUser from '@/composables/getUser'
import { getUserActionState, UserActions } from '../composables/getUserActionState';
import getDocument from '@/composables/getDocument'

export default {
    props: ['audioPath', 'sessionId'],
    setup(props) {
        const isPending = ref(false)
        const isDisabledRegenerateTranscript = ref(true)
        const isDisabledDeleteAudio = ref(true)

        const { document: session } = getDocument('sessions', props.sessionId);

        const isTranscriptPending = ref(false)
        const { user } = getUser();

        const pathSplitted = props.audioPath?.split("/")
        var sessionId = ""
        if (pathSplitted != null) {
            sessionId = pathSplitted[pathSplitted.length - 1];
            ['.m4a', '.webm'].map(format => {
                sessionId = sessionId.replace(format, '')
            });
        }

        const audioUrl = ref(null)
        const audioLoading = ref(true);

        getAudio(props.audioPath).then(url => {
            audioUrl.value = url
        }).finally(() => {
            audioLoading.value = false
        })

        const deleteAudio = async () => {
            isPending.value = true
            trackEvent(DELETE_AUDIO, { userId: user.value.uid, userEmail: user.value.email, sessionId: sessionId })

            const functions = getFunctions();
            const deleteAudioBySessionId = httpsCallable(functions, 'deleteAudioBySessionId');
            deleteAudioBySessionId(sessionId)
                .then((result) => {
                }).catch((error) => {
                    console.log(`Error deleting audio ${error}`)
                });
            isPending.value = false
        }

        const newTranscript = async () => {

            isTranscriptPending.value = true

            var regex = /gs:\/\/[a-z]+-\w+\.appspot\.com\//;
            var path = props.audioPath.replace(regex, '');

            //Call firebase function to transcript the audio
            const functions = getFunctions();
            const transcribeAudio = httpsCallable(functions, 'transcribeAudio');
            transcribeAudio(path)
                .then((result) => {
                    console.log('done', result)
                });

            isTranscriptPending.value = false

        }

        watch(session, (newSession) => {
            isDisabledRegenerateTranscript.value = !getUserActionState(UserActions.ReTriggerAudio, newSession.state)
            isDisabledDeleteAudio.value = !getUserActionState(UserActions.DeleteAudio, newSession.state)
        })

        return { audioUrl, audioLoading, newTranscript, deleteAudio, isPending, isTranscriptPending, isDisabledRegenerateTranscript, isDisabledDeleteAudio }
    }
}
</script>

<style scoped>
.delete-audio {
    background-color: #f1f3f4 !important;
    width: 54px !important;
    height: 54px !important;
}

.delete-audio:hover {
    background-color: #d8dadb !important;
    width: 54px !important;
    height: 54px !important;
}

.reload-audio {
    background-color: #f1f3f4 !important;
    width: 54px !important;
    height: 54px !important;
}

.reload-audio:hover {
    background-color: #d8dadb !important;
    width: 54px !important;
    height: 54px !important;
}

.delete-audio .v-icon {
    color: black !important;
}


.audio-controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.audio-wrapper {
    flex-grow: 1;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>