<template>
  <div class="fit-content mt-2">
    <v-tooltip text="Like" location="top">
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" :prepend-icon="`mdi-thumb-up${like ? '' : '-outline'}`" @click="toggleLike(true)"
          class="like-button" variant="plain"></v-btn>
      </template>
    </v-tooltip>
    <v-tooltip text="Dislike" location="bottom">
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" :prepend-icon="`mdi-thumb-down${dislike ? '' : '-outline'}`" @click="toggleLike(false)"
          class="dislike-button" variant="plain"></v-btn>
      </template>
    </v-tooltip>
  </div>
</template>

<script>
import { ref } from 'vue';
import getUser from '@/composables/getUser';
import { useRouter } from 'vue-router';
import { getUserCollection } from '@/composables/userService';
import useDocument from '@/composables/useDocument';
import getDocument from '@/composables/getDocument';
import { trackEvent, USER_FEATURE_FEEDBACK } from '@/utilities/analyticsService';

export default {
  props: ['userEmail', 'userId', 'feature', 'extra'],
  setup(props) {
    const like = ref(false);
    const dislike = ref(false);
    const toggleLike = (isLike) => {
      like.value = isLike;
      dislike.value = !isLike;
      trackEvent(USER_FEATURE_FEEDBACK, { userId: props.userId, userEmail: props.userEmail, feature: props.feature, like: isLike, extra: props.extra ?? '-', source: "Web" })
    };

    return {
      like,
      dislike,
      toggleLike
    };
  }
};
</script>


<style scoped>
.fit-content {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 5px;

  width: fit-content;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  transition: all 0.3s ease;

}

.like-button,
.dislike-button {
  transition: all 0.3s ease;
  font-size: 0.8rem;
}
</style>