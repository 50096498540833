<template>
    <div class="d-flex">
        <v-tooltip location="bottom" text="Move up">
            <template v-slot:activator="{ props }">
                <v-btn v-if="!item.cannotEdit" elevation="0" v-bind="props" @click="moveUp">
                    <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
            </template>
        </v-tooltip>
        <v-tooltip location="bottom" text="Move down">
            <template v-slot:activator="{ props }">
                <v-btn v-if="!item.cannotEdit" class="ml-2" elevation="0" v-bind="props" @click="moveDown">
                    <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
            </template>
        </v-tooltip>
        <v-spacer></v-spacer>

        <!-- <v-btn v-if="!item.cannotEdit" elevation="0" @click="cancel">
            Close
        </v-btn> -->
        <v-btn v-if="!item.cannotEdit" elevation="0" @click="remove">
            Remove
        </v-btn>
        <v-btn v-if="!item.cannotEdit" elevation="0" @click="duplicate">Duplicate</v-btn>
        <v-btn v-if="!item.cannotEdit" elevation="0" class="ml-2" @click="save">
            <span style="color: var(--bittersweet)">Save</span>
        </v-btn>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
    item: Object,
    changed: Boolean
})


const emit = defineEmits(['save', 'remove', 'moveUp', 'moveDown', 'duplicate']);


const save = () => {
    emit('save')
}

const remove = () => {
    emit('remove')
}

const moveUp = () => {
    emit('moveUp')
}

const moveDown = () => {
    emit('moveDown')
}

const duplicate = () => {
    emit('duplicate')
}
</script>