<template>
  <v-sheet v-if="isAdmin && clinicId" class="d-flex flex-column justify-center align-center ml-5 mr-5 mt-4"
    color="#f9fbfd">
    <ClinicDetails :clinicId="clinicId" />
    <ImportPatients v-if="showImportPatients || canViewImport" :clinicId="clinicId"></ImportPatients>
    <ClinicUsers :clinicId="clinicId" />
    <ClinicTemplates :clinicId="clinicId" />
  </v-sheet>
</template>

<script>
import ClinicDetails from "@/components/ClinicDetails.vue";
import ClinicUsers from "@/components/ClinicUsers.vue";
import ClinicTemplates from "@/components/ClinicTemplates.vue";
import ImportPatients from "@/components/ImportPatients.vue";
import getUser from '@/composables/getUser'
import { ref } from 'vue';
import { getUserCollection } from '@/composables/userService';
import useDocument from '@/composables/useDocument'
import { isWhiteListedAccount } from '@/composables/isWhitelistedAccount'

export default {
  components: {
    ClinicDetails,
    ClinicUsers,
    ImportPatients,
    ClinicTemplates
  },
  data() {

  },
  setup() {
    const { user } = getUser()
    const userObject = ref({});
    const documentHelpers = ref(null);
    const isAdmin = ref(false);
    const clinicId = ref(null)
    const showImportPatients = ref(false)
    const canViewImport = ref(false);
    canViewImport.value = isWhiteListedAccount();

    const loadUserObject = async () => {
      if (user.value) {
        userObject.value = await getUserCollection(user.value.uid);
        if (userObject.value) {
          documentHelpers.value = useDocument('user', userObject.value.id)
          if (userObject.value.isAdmin) {
            isAdmin.value = true
            clinicId.value = userObject.value.clinicId;
          }
        }
        if (user.value.uid == 'r6vBqrFiPPepmsPCOtLICMewArh1') {
          showImportPatients.value = true;
        } else {
          showImportPatients.value = false;
        }
      }
    };

    loadUserObject();

    return { user, userObject, isAdmin, clinicId, showImportPatients, canViewImport }
  }
}

</script>

<style scoped></style>