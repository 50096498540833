<template>
  <v-app-bar app class="mdhub-shadow">
    <v-toolbar-title>
      <a href="/">
        <img class="ml-10"
          src="https://uploads-ssl.webflow.com/65421d636a315eff20bf6d7f/654ab4cd536fae9250a779eb_mdhub-wordmark.svg"
          alt="Logo" style="border-radius: 0;" width="127" height="124">
      </a>

    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-row v-if="user" align="center" justify="end">
      <span v-if="!isUpgradeVisible" class="mr-10 nav-item">
        <router-link v-if="userObject" exact class="nav-link" :to="{ name: 'UserDetails' }" exact-active-class="active">
          Hi {{ userObject.name }}
        </router-link>
      </span>
      <span v-if="isUpgradeVisible">
        <router-link v-if="userObject" exact class="nav-link" :to="{ name: 'UserDetails' }" exact-active-class="active">
          {{ messageToolbar }}
        </router-link>
      </span>
      <PriceButton :onUpgradeVisible="handleUpgradeVisible"></PriceButton>

    </v-row>
  </v-app-bar>
</template>

<script>

import useLogout from '../composables/useLogout'
import getUser from '../composables/getUser'
import { useRouter } from 'vue-router'
import { getUserCollection } from '@/composables/userService';
import PriceButton from '@/components/PriceButton';
import { ref, computed, watch } from 'vue';

export default {
  components: { PriceButton },
  setup() {
    const { user } = getUser()
    const { logout } = useLogout()
    const router = useRouter()
    const userObject = ref({});
    const isUpgradeVisible = ref(false);

    const loadUserObject = async () => {
      if (user.value)
        userObject.value = await getUserCollection(user.value.uid);
    };

    loadUserObject();

    const handleClick = async () => {
      await logout()
      router.push({ name: 'Login' })
    }

    const handleUpgradeVisible = (visible) => {
      isUpgradeVisible.value = visible;
    }

    const messageToolbar = computed(() => {
      const count = 30 - (userObject.value.counter || 0);
      return count <= 0 ? 'No more sessions left' : `${count}/30 sessions left`;
    });

    watch(user, () => {
      if (user.value) {
        loadUserObject();
      } else {
        userObject.value = {}; // Clear user object on logout
      }
    });

    return { handleClick, user, userObject, handleUpgradeVisible, isUpgradeVisible, messageToolbar }
  }
}

</script>

<style scoped>
.router-link,
.router-link-active,
.router-link-exact-active,
.nav-link {
  text-decoration: none;
}

/* Additional styles for hover effects, if desired */
.router-link,
.router-link-active:hover,
.router-link-exact-active:hover,
.nav-link:hover {
  text-decoration: none;
}

.nav-link {
  text-decoration: none;
  margin-right: 20px;
  /* Adjust this value as needed */
}

.v-toolbar-title {
  display: flex;
  align-items: start;
  justify-content: start;
}

.mdhub-shadow {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px !important;
}
</style>
