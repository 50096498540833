<template>
  <v-card class="pa-0 mb-4 mt-2" elevation="0" width="100%" height="100%">
    <v-card-title class="headline mt-5 ml-5 left-align">Billing</v-card-title>

    <div v-if="subscription">
      <div class="alert alert-warning" v-if="subscription.cancel_at_period_end">
        This subscription will cancel at the end of the period.
      </div>
      <v-card-text class="headline mt-5 ml-5 mb-5 left-align">
        Current period start:
        {{ new Date(subscription.current_period_start.seconds * 1000).toLocaleString() }}
        <br /><br />
        Renewal Date:
        {{ new Date(subscription.current_period_end.seconds * 1000).toLocaleString() }}
      </v-card-text>
      <v-btn class="mdhub-btn ml-8 mb-8 left-align-btn" :disabled="isLoading" @click="openCustomerPortal" elevation="0">
        {{ isLoading ? "Loading..." : "Billing portal" }}
      </v-btn>
    </div>
    <div v-else>

      <v-card-text class="mb-5"> No subscription yet? Start saving time with mdhub</v-card-text>
    </div>
  </v-card>
</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";
import PriceSelection from "@/components/PriceSelection";

export default {
  props: ["subscription", "userObject"],
  components: { PriceSelection },
  data() {
    return {
      isLoading: false,
      isVisible: true,
    }
  },

  methods: {
    async openCustomerPortal() {
      this.isLoading = true;
      console.log("window.location.origin: ", window.location.origin)
      console.log("window.location: ", window.location)

      const functions = getFunctions();
      const functionRef = httpsCallable(
        functions,
        "ext-firestore-stripe-payments-createPortalLink"
      );
      const { data } = await functionRef({
        returnUrl: window.location.origin + "/details",
      });

      window.location.assign(data.url);
    },

  }
}
</script>

<style scoped>
.left-align {
  text-align: left;
}

.left-align-btn {
  display: block;
}
</style>