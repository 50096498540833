<template>
    <div class="mt-12 d-flex flex-column align-center">
        <v-img height="200" width="200" class="image-onboarding" :src="require('@/assets/rocket.png')"
            alt="Banner Image" cover></v-img>
        {{ router.query.status }}
    </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { onMounted } from 'vue';

const router = useRoute();

onMounted(() => {
    console.log(router)
});
</script>