import { ref, watchEffect } from 'vue';
import { projectFirestore } from '@/firebase/config.js'; // Adjust the path to your Firebase config
import getUser from "./getUser";

const {user} = getUser()

const countCollection = (collection, query) => {
  const count = ref(0);
  const error = ref(null);
  
  let collectionRef = projectFirestore.collection(collection).where('userId', '==', user.value.uid) 

  if (query) {
    collectionRef = collectionRef.where(...query);
  }

  const unsub = collectionRef.onSnapshot(snap => {
    count.value = snap.size;
    error.value = null;
  }, err => {
    console.log("Get collection count error: ", err.message);
    count.value = 0;
    error.value = 'could not fetch the data';
  });

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub());
  });

  return { error, count };
};

export default countCollection;
