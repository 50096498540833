<template>
  <div v-if="isLoading">Loading account information...</div>
  <v-dialog v-else-if="isShown()" v-model="isVisible" width="800" :persistent="isBannerPersistent()">
    <v-card>
      <PriceSelection :details="details" />
    </v-card>
  </v-dialog>
  <PriceSelection v-else-if="isShownDetails()" :details="details"
    :class="margins ? 'has-margins mdhub-card' : 'mdhub-card'" />
  <div v-else></div>
</template>


<script>

import PriceSelection from "@/components/PriceSelection";
import getUser from '@/composables/getUser'
import { ref, onMounted } from 'vue';
import { getUserCollection } from '@/composables/userService';
import { useSubscription, useBlockedUsers } from '@/composables/useSubscription';

export default {
  props: ['details', 'margins'],
  components: {
    PriceSelection,
  },
  methods: {
    isBannerPersistent() {
      return this.isUserBlocked
    },
    isShown() {
      if (this.userObject && this.userObject.clinicId && this.userObject.clinicId !== '') { return false; }
      if ((!this.subscription) && (this.details == false) && (this.userObject && this.userObject.counter > 30)) { return true; }
      return false;
    },
    isShownDetails() {
      if (this.userObject && this.userObject.clinicId && this.userObject.clinicId !== '') { return false; }
      if ((!this.subscription) && (this.details)) { return true; }
      return false;
    }
  },
  setup(props) {
    const { user } = getUser()
    const userObject = ref({}); // Initialize userObject as a reactive reference
    const details = ref(props.details);
    const isVisible = ref(true);
    const { isLoading, subscription, fetchSubscription } = useSubscription(user.value.uid);
    const { isUserBlocked, fetchBlockedUsers } = useBlockedUsers(user.value.uid);
    console.log('details: ', props.details);
    const loadUserObject = async () => {
      if (user.value) {
        userObject.value = await getUserCollection(user.value.uid);
      }
    };

    onMounted(() => {
      loadUserObject();
      fetchSubscription();
      fetchBlockedUsers();
    });

    return { userObject, details, isVisible, subscription, isLoading, isUserBlocked };
  },
};
</script>

<style scoped>
.mdhub-card {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

}

.has-margins {
  margin-right: 100px;
  margin-left: 100px;
}

/* Remove margins on phone screens */
@media (max-width: 600px) {
  .has-margins {
    margin-right: 0;
    margin-left: 0;
  }
}
</style>