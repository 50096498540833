<template>
    <div class="d-flex  align-center justify-center full-height form-container">
        <div class="flex-column full-width">
            <p>
            <h2>Onboarding Questionnaire</h2>
            </p>
            <p>Let's get to know you better</p>
            <p v-if="userName || error">{{ userName }}</p>
            <SavingSpinner label="" class="justify-center" v-else style="position:relative" />
        </div>
        <div class="flex-column full-width">
            <v-form @submit.prevent="handleSubmit" v-model="isValid" ref="form">
                <h4>Basic information</h4>

                <v-text-field class="form-field" v-model="name" color="accent" label="Name" variant="underlined"
                    density="compact" :rules="[rules.required]"></v-text-field>

                <v-select class="form-field" label="Gender" v-model="gender" variant="underlined" color="accent"
                    :items="['Male', 'Female', 'Non-binary', 'Prefer not to say', 'Other']"
                    :rules="[rules.required]"></v-select>

                <v-select class="form-field" label="Pronoun" v-model="pronoun" variant="underlined" color="accent"
                    :items="['he/him', 'she/her', 'they/them']" :rules="[rules.required]"></v-select>

                <v-text-field class="form-field" label="Date of birth" type="date" id="dob" color="accent" v-model="dob"
                    variant="underlined" :rules="[rules.required]"></v-text-field>

                <v-text-field class="form-field" label="Email" type="email" id="email" color="accent" v-model="email"
                    :rules="[rules.email, rules.required]" variant="underlined"></v-text-field>

                <v-btn :type="submit" :disabled="!isValid || error" elevation="0" class="mt-4 mdhub-btn">Submit</v-btn>

                <v-alert class="mt-4" icon="mdi-alert" outlined v-if="error">
                    The questionnaire is not available. Please contact your doctor.
                </v-alert>
            </v-form>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, getCurrentInstance, watchEffect, onMounted } from 'vue';
import SavingSpinner from '../SavingSpinner.vue';

const { emit, parent } = getCurrentInstance();

const props = defineProps({
    userName: String,
    error: String,
});

const userName = ref('');
const error = ref('');

const name = ref('');
const gender = ref('');
const dob = ref('');
const email = ref('');
const isValid = ref(false);
const form = ref(null);
const pronoun = ref('');

const handleSubmit = () => {
    if (!isValid.value) return;
    emit('handleSubmit',
        {
            name: name.value,
            gender: gender.value,
            age: calculateAge(),
            dob: dob.value,
            email: email.value,
            pronoun: pronoun.value,
        }
    )


    form.value.reset()
};

const calculateAge = () => {
    const ageDate = new Date(dob.value);
    const dateNow = new Date();
    const monthDiff = dateNow.getMonth() > ageDate.getMonth();
    const age = dateNow.getFullYear() - ageDate.getFullYear() - (monthDiff ? 0 : 1);
    return age;
}

const rules = ref(
    {
        email: v => !!(v || '').match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) || 'Please enter a valid email',
        required: v => !!v || 'This field is required',

    })


const removeMainClass = () => {
    const mainElement = document.querySelector('main')
    if (mainElement && mainElement.classList.contains('pb-12')) {
        mainElement.classList.remove('pb-12')
    }
}
watchEffect(() => {
    userName.value = props.userName
    error.value = props.error
})

onMounted(() => {
    removeMainClass()
})
</script>
<style scoped>
h2 {
    font-weight: 700;
    font-size: 2rem;
    color: #FFFFFF;
}

p {
    font-weight: 300;
    font-size: 1rem;
    color: #8D99AE;
}

.form-container {
    background: linear-gradient(to bottom right, #FF7260, #FFFFFF);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 2rem 0;
    /* Soft gradient */
}

.mdhub-btn {
    background-color: #2B6CB0;
    /* A nice primary blue */
    color: white;
    border-radius: 8px;
    /* Rounded corners for a modern look */
    padding: 10px 20px;
}

.mdhub-btn:hover {
    background-color: #2C5282;
    /* Darker on hover */
}

.v-form {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 24px;
}

.mdhub-btn {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.v-text-field,
.v-select {
    margin-bottom: 20px;
    padding: 10px;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%
}

.form-content {
    width: 100%;
    max-width: 600px;
    padding: 2rem;
}

.form-field {
    margin-bottom: 1rem;
}

@media (max-width: 900px) {
    .form-container {
        display: block !important;
        margin: 16px;
    }
}
</style>