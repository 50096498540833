<template>

    <div class="clinical-note-actions">
        <v-tooltip location="bottom">
            <template v-slot:activator="{ props, on }">
                <v-btn size="x-small" class="mr-2 mt-2" elevation="0" @click.capture.stop="copyNoteSection"
                    v-bind="props">
                    <v-icon style="font-size: 15px;">mdi-content-copy</v-icon>
                    <span style="margin-left: 4px;">Copy section</span>
                </v-btn>
            </template>
            <span>Copy note section</span>
        </v-tooltip>
        <v-tooltip location="bottom">
            <template v-slot:activator="{ props, on }">
                <v-btn size="x-small" class="mt-2 mr-2" icon @click.capture.stop="deleteNoteSection" v-bind="props"
                    elevation="0">
                    <v-icon style="font-size: 15px;">mdi-trash-can-outline</v-icon>
                </v-btn>
            </template>
            <span>Delete note section</span>
        </v-tooltip>
    </div>
</template>

<script setup>

import { ref, nextTick, watch, defineEmits } from 'vue';
import { trackEvent, NOTE_SECTION_COPY } from '@/utilities/analyticsService';
import getUser from '@/composables/getUser'

const props = defineProps({
    note: Object,
    disabledCopy: Boolean
});

const emit = defineEmits(['show-price-banner', 'show-snackbar', 'copy-note-section']);


const { user } = getUser();

const clinicaNoteSection = ref({
    section: props.note.section,
    content: props.note.content
});

const copyNoteSection = async () => {
    if (props.disabledCopy) {
        emit('show-price-banner');
        return;
    }

    emit('copy-note-section');
    return
    try {
        if (props.disabledCopy) {
            emit('show-price-banner');
            return;
        }
        nextTick(async () => {
            const formattedHtml =
                `<div>
                <h3>${clinicaNoteSection.value.section}</h3>
                <div>${clinicaNoteSection.value.content}</div>
            </div>`

            const blob = new Blob([formattedHtml], { type: 'text/html' });
            const data = new ClipboardItem({
                'text/html': blob,
                'text/plain': new Blob([stripHtml(formattedHtml)], { type: 'text/plain' })
            });

            await navigator.clipboard.write([data]);
            emit('show-snackbar', 'Copying note to clipboard');
            trackEvent(NOTE_SECTION_COPY, { userId: user.value.id, userEmail: user.value.email });
        });

    } catch (err) {
        console.error('Failed to copy: ', err);
        snackbar.value = true;
        snackbarText.value = 'Failed to copy note';
    }
};

const stripHtml = (html) => {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    let text = tmp.textContent || tmp.innerText || '';
    text = text.replace(/^\s+/gm, '\n');

    return text;
};

watch(() => props.note, (newVal) => {
    clinicaNoteSection.value.section = newVal.section;
    clinicaNoteSection.value.content = newVal.content;
});

const deleteNoteSection = () => {
    emit('delete-section');
}

</script>

<style scoped>
.clinical-note-card {
    cursor: pointer;
}

.v-tooltip span {
    color: white !important;
}

.clinical-note-actions {
    position: absolute;
    top: 0;
    right: 0;
}

.absolute-top-right {
    position: absolute;
    top: 0;
    right: 0;
}
</style>