<template>
  <v-sheet class="d-flex flex-column justify-center align-center ml-5 mr-5 mt-4" color="#f9fbfd">
    <v-card class="mdhub-card elevation-0" width="800px" height="100%">
      <v-card-title class="left-align mb-5 mt-7 ml-6"> Clinic Templates</v-card-title>
      <v-list class="left-align ml-6" :disabled="clinicTemplates && clinicTemplates.length == 0">
        <v-list-item v-for=" (item, i) in clinicTemplates" :key="i">
          <v-list-item-title v-text="item.title"></v-list-item-title>
        </v-list-item>

        <v-list-item v-if="clinicTemplates && clinicTemplates.length == 0">
          <v-list-item-title>No clinic templates have been added yet.</v-list-item-title>
        </v-list-item>

      </v-list>
    </v-card>
  </v-sheet>
</template>

<script>

import getUser from '@/composables/getUser'
import { useRouter } from 'vue-router'
import { ref } from 'vue';
import getDocument from '@/composables/getDocument'
import useDocument from '@/composables/useDocument'
import { getUserCollection } from '@/composables/userService';

export default {
  props: ['clinicId'],
  data() {
    return {
      headers: [

        {
          title: 'Name',
          align: 'start',
          value: 'templateName',
        }
      ]
    }
  },
  setup(props) {
    const { error, document: clinic } = getDocument('clinics', props.clinicId)
    const clinicTemplates = ref([]);
    const userObject = ref(null);
    const documentHelpers = ref(null);
    const { user } = getUser();
    const loadUserObject = async () => {
      userObject.value = await getUserCollection(user.value.uid);
      if (userObject.value) {
        documentHelpers.value = useDocument('user', userObject.value.id)
      }

      clinicTemplates.value = clinic.value.templates.map(template => ({
        title: template.name,
        value: template.id
      }));

    };

    loadUserObject();

    return { clinicTemplates }
  }
}

</script>

<style scoped>
.left-align {
  text-align: left;
}

.v-data-table {
  display: inline-flex;
  padding: 0 30px 30px 30px;
  text-align: left;
}

tr {
  height: 70px !important;
  cursor: pointer;
}

tr:hover {
  background-color: #e5e6e8;
  /* Adjust the color and opacity as needed */
}
</style>