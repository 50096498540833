<template>
	<v-btn class="white--text text--lighten-1" @click="openDialog" icon size="x-large" variant="text" :elevation="0"
		:id="patientId" @click.stop="drawer = !drawer" v-if="style === 'icon' && !isMdhubZoom" density="compact"
		rounded>
		<v-icon color="#FF7260">mdi-microphone</v-icon>
		<!-- Change 'red' to any color you want -->
	</v-btn>

	<v-btn class="white--text text--lighten-1" color="bittersweet" elevation="0" @click="openDialog"
		@click.stop="drawer = !drawer" v-else-if="style === 'full' && !isMdhubZoom"
		:recording="isRecording ? 'true' : 'false'">
		<v-icon color="white" icon="mdi-microphone"></v-icon>
		<span class="white--text" color="#FFFFF"> {{ isRecording ? 'Stop Recording' : 'Start Recording' }}
		</span>
	</v-btn>

	<v-btn variant="text" @click="openDialog" v-else-if="style === 'transparent' && !isMdhubZoom"
		:recording="isRecording ? 'true' : 'false'" @click.stop="drawer = !drawer">
		<v-icon class="recorder-new-btn">mdi-microphone</v-icon>
		<span class="white--text recorder-new-btn"> {{ isRecording ? 'Stop Recording' : 'Start Recording' }}
		</span>
	</v-btn>

	<div class="recorder-button-container" v-else="isMdhubZoom">
		<v-btn class="recorder-btn" color="bittersweet" @click="toggleCaptureZoomApp"
			:recording="isRecording ? 'true' : 'false'">
			<v-icon dense color="white">mdi-microphone</v-icon>
			<span class="white--text" color="#FFFFF"> {{ isRecording ? 'Stop Recording' : 'Start Recording' }}
			</span>
		</v-btn>
	</div>

	<v-overlay :model-value="overlay" :persistent="true" class="align-center justify-center spinner-overlay">
		<v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
	</v-overlay>


	<v-navigation-drawer v-model="drawer" v-if="drawer" persistent temporary location="right" :width="800"
		:scrim="false" class="d-flex flex-column fullscreen-drawer">
		<div class="d-flex justify-space-between align-center pl-4 pt-4 mt-4 pr-4">
			<div class="text-left">
				<div v-if="!showCanvas">
					<h5 class="section-header">Start recording {{ patient?.patientName }}</h5>
				</div>
				<div v-if="showCanvas" class="d-flex">
					<h5 class="section-header">Recording {{ patient?.patientName }}</h5>
					<span
						:class="{ 'recording-indicator': !deviceChanged && !isPaused, 'error-indicator': deviceChanged || isPaused }"></span>
				</div>
				<div class="text-h9 mt-1">Please record more than 20 sec. for the note to be created</div>
			</div>
			<v-btn v-if="!showCanvas" class="close-btn" variant="plain" icon @click="drawer = false">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</div>

		<h5 v-if="!showCanvas" class="section-header align-left pl-4 mt-4">Input</h5>
		<div v-if="!showCanvas" class="mt-1 mr-4 ml-6  followup-section">
			<div class="d-flex justify-space-between align-center w-100 button-container mb-1"
				:class="{ 'disable-events': headphonesDialog }">
				<v-btn :append-icon="sessionType === 'dictation' ? 'mdi-microphone' : 'mdi-headphones-off'" stacked
					elevation=0 class="mt-1" @click="startWithoutHeadphonesRecording">
					{{ sessionType === 'dictation' ? 'Start dictating' : 'Without headphones' }}
				</v-btn>
				<v-btn append-icon="mdi-headphones" v-if="sessionType !== 'dictation'" stacked class="mt-1" elevation=0
					@click="informUserForHeadphones" :disabled="headphonesDialog">
					With headphones
				</v-btn>
				<v-btn append-icon="mdi-file-upload" stacked elevation=0 class="mt-1" @click="triggerFileInput"
					:loading="isPending">
					Upload Audio
				</v-btn>
				<input type="file" ref="fileInput" @change="onFileDrop" accept=".mp3, .wav, .aac, .flac, .m4a"
					style="display: none;">
			</div>
		</div>

		<h5 class="section-header align-left pl-4 mt-4">Source: </h5>
		<span v-if="!showCanvas && selectedAudioInputDevice" class="mr-4 followup-section d-flex ml-4 audio-input">{{
			selectedAudioInputDevice.label || 'Unknown Device'
		}}</span>
		<h5 v-if="!showCanvas" class="section-header align-left pl-4 mt-4">Session type</h5>

		<div v-if="!showCanvas" class="d-flex flex-column ml-4 mt-4 mr-4 followup-section" hidden>
			<v-btn-toggle v-model="sessionType" color="#eee" mandatory divided>

				<v-tooltip text="Record a live patient session" location="bottom" z-index="10000">
					<template v-slot:activator="{ props }">
						<v-btn v-bind="props" value="live">Live session</v-btn>
					</template>
				</v-tooltip>

				<v-tooltip
					text="Record yourself, summarizing a past patient visit. No need to specify periods, just speak naturally"
					location="bottom" z-index="10000">
					<template v-slot:activator="{ props }">
						<v-btn v-bind="props" value="dictation">Dictation</v-btn>
					</template>
				</v-tooltip>

				<!-- <v-btn value="couples">Couples</v-btn>
                    <v-btn value="group">Group</v-btn> -->
			</v-btn-toggle>
		</div>
		<div class="canvas-container">
			<div v-if="showCanvas">
				<div v-if="showCanvas && showWaves" class="canvas-wrapper full-width margin-sides mb-2">
					<div class="formatted-time ">{{ formattedTime }}</div>
					<div class="canvas-transcript-wrapper">

						<div>
							<div v-if="!deviceChanged" id="canvas_container2" class="full-width">
								<AVMedia v-if="!isPaused" class="awc full-width" :media="stream" type="frequ"
									frequ-direction="mo" :frequ-lnum="60" :line-width="2" line-color="#808080" />
								<div v-else class="text-h6 center-vertically">Audio Paused</div>
							</div>
							<div v-else id="canvas_container2" class="full-width">
								<div class="text-h7 center-vertically">Something went wrong - Audio device changed.
									Please
									finish
									the
									recording.</div>
							</div>
							<div class="live-transcript-wrapper" hidden>
								<v-expansion-panels class="full-width">
									<v-expansion-panel elevation="0" v-if="liveStreamReady" class="pa-1"
										@click="handlePanelClick">
										<v-expansion-panel-title class="live-transcript" style="max-height: 300px; ">
											<template v-slot="{ expanded }">
												<v-row no-gutters>
													<v-col class="d-flex justify-start" cols="12" v-if="!expanded">
														<div class="text-left" v-html="liveTranscript">
														</div>
													</v-col>
													<v-col class="d-flex justify-start recorder-title py-4 text-start"
														cols="10" v-else> Live Transcript
													</v-col>
												</v-row>

											</template>
											<template v-slot:actions="{ expanded }">
												<v-icon style="font-size: 27px"
													:icon="expanded ? 'mdi-chevron-down' : 'mdi-chevron-up'"></v-icon>
											</template>
										</v-expansion-panel-title>
									</v-expansion-panel>
								</v-expansion-panels>
							</div>
						</div>
					</div>
					<div>

					</div>
				</div>
				<div v-else v-if="deviceChanged" class="canvas-wrapper full-width margin-sides">
					<div id="canvas_container2" class="full-width">
						<div class="text-h7 center-vertically">Something went wrong - Audio device changed. Please
							finish
							the
							recording.</div>
					</div>
				</div>
				<div class="mr-4 ml-4 mt-2" hidden v-if="!withoutHeadphonesRecording && liveStreamReady">
					<h5 class="section-header">Live Transcript</h5>
					<v-expansion-panels class="mb-2 followup-section" expand v-model="liveTranscriptEnabled"
						:loading="!liveStreamReady">
						<v-expansion-panel elevation="0">
							<v-expansion-panel-title class="recorder-title pt-4 pb-4">
								<template v-slot="{ expanded }">
									<v-row no-gutters>
										<v-col class="d-flex justify-start" cols="12" v-if="expanded">
											Less
										</v-col>
										<v-col class="d-flex justify-start" cols="12" v-else>
											More
										</v-col>
									</v-row>

								</template>
							</v-expansion-panel-title>
							<v-expansion-panel-text>
								<div style="max-height: 300px;" class="text-body-1 text-left live-transcript"
									v-html="liveTranscript"></div>
							</v-expansion-panel-text>
						</v-expansion-panel>
					</v-expansion-panels>
				</div>
				<div v-if="firstTimeUser" class="mr-4 ml-4">
					<v-expansion-panels class="mb-5 followup-section" expand v-model="panel">
						<v-expansion-panel class="recorder-title" elevation="0">
							<v-expansion-panel-title class="recorder-title pt-4 pb-4">
								<div class="title-container">
									Onboarding
									<v-expansion-panel-subtitle class="subtitle">
										Read out loud the next conversation to test the product, please record more than
										20
										seconds:
									</v-expansion-panel-subtitle>
								</div>
							</v-expansion-panel-title>
							<v-expansion-panel-text>
								<div class="text-left" v-html="conversationText"></div>
							</v-expansion-panel-text>
						</v-expansion-panel>
					</v-expansion-panels>
				</div>
			</div>
			<div class="canvas-container">
				<PatientQuestionnaires class='mt-2' :patient="patient" :selectable="true"
					@selected-questionnaire="setSelectedQuestionnaire" @loading="loading = $event" />
				<div class="mx-4  mb-4 mt-2">
					<h5 class="section-header">Template</h5>
					<v-expansion-panels class="followup-section" v-model="templatePanel">
						<v-expansion-panel elevation="0" :value="true">
							<v-expansion-panel-title class="recorder-title py-4 text-start">
								{{ selectedTemplate?.title ? `${selectedTemplate.title}` : `Loading...` }}
							</v-expansion-panel-title>
							<v-expansion-panel-text>
								<v-list>
									<v-list-item v-for="(template, i) in userTemplates" :key="i"
										@click="selectTemplate(template)" class="text-start">
										<v-list-item-content>
											<v-list-item-title class="d-flex align-center">
												<v-icon left class="mr-4">mdi-file-document-outline</v-icon>
												{{ template?.title }}
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-list-item v-if="userTemplates.length === 0">
										<v-list-item-content>
											<v-list-item-title class="d-flex align-center">
												<SavingSpinner :label="`Fetching templates`"
													style="position:relative;" />
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list>
							</v-expansion-panel-text>
						</v-expansion-panel>
					</v-expansion-panels>
				</div>
				<!-- Below should be a different component -->
				<div class="mr-4 ml-4 mt-2">
					<h5 class="section-header" v-if="renderedMarkdown">Follow up</h5>
					<v-expansion-panels v-if="renderedMarkdown" class="mb-2 followup-section" expand
						v-model="followUpPanel">
						<v-expansion-panel elevation="0">
							<v-expansion-panel-title class="recorder-title pt-4 pb-4">
								{{ followupDate }}
							</v-expansion-panel-title>
							<v-expansion-panel-text>
								<div class="text-left" v-html="renderedMarkdown"></div>
							</v-expansion-panel-text>
						</v-expansion-panel>
					</v-expansion-panels>
				</div>
			</div>
			<!-- Below should be a different component -->
			<div v-if="showCanvas">

				<h5 class="section-header mx-4 mt-4">Additional Notes</h5>
				<v-textarea flat auto-grow v-model="additionalNotes"
					placeholder="e.g. mood subdued, avoided eye contact, quick to smile." rows="10" variant="solo"
					class="followup-section mx-4 mb-16"></v-textarea>
			</div>
			<v-card-actions v-if="showCanvas" class="d-flex justify-end sticky-actions stack-buttons pr-4 pb-4 pt-4">
				<v-btn color="red" class="white--text text--lighten-1  mdhub-btn "
					style="background-color: var(--bittersweet); width:auto" text
					@click="startWithoutHeadphonesRecording" v-if="shouldResumeAfterDeviceChange" stacked>
					<v-icon class=" white--text mr-2" prepend-icon color="#FFFFF" dense>{{ 'mdi-play' }}</v-icon>
					<span class="white--text" color="#FFFFF">Resume Recording</span>
				</v-btn>
				<v-btn @click="togglePauseRecording" :disabled="!isRecording || deviceChanged"
					v-else-if="!shouldResumeAfterDeviceChange" stacked class="mdhub-btn " style="background-color: transparent!important; width:auto
">
					<v-icon class="recorder-new-btn mr-2" prepend-icon dense>{{ isPaused ? 'mdi-play' :
						'mdi-pause'
						}}</v-icon>
					<span class="recorder-new-btn">{{ isPaused ? 'Resume Recording' : 'Pause Recording' }}</span>
				</v-btn>
				<v-btn class="white--text text--lighten-1 mdhub-btn" style="background-color: var(--bittersweet);"
					color="bittersweet" text @click="stopWithoutHeadphonesRecording" :loading="finishButtonLoading"
					v-if="!shouldResumeAfterDeviceChange" stacked>
					<v-icon class="mr-2 white--text" prepend-icon color="#FFFFF" dense>{{ 'mdi-tray-arrow-up'
						}}</v-icon>
					<span class="white--text" color="#FFFFF">Finish Recording</span>
				</v-btn>
			</v-card-actions>
		</div>
	</v-navigation-drawer>

	<v-dialog width="500" v-model="hasNotValidToken">

		<v-card title="Zoom Recording Error">
			<v-card-text>
				Something went wrong with the recording of the zoom session. Please select again the patient and start
				over.
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>

				<v-btn text="Close" @click="hasNotValidToken = false"></v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
	<v-dialog v-model="showRecordingDialog" persistent max-width="500px">
		<v-card>
			<v-card-title class="ma-4">
				<v-icon large class="mr-4" :class="{ 'pulsing-icon': true }">mdi-microphone</v-icon>
				<span class="text-h5">Recording in progress</span>
			</v-card-title>
			<v-card-text>
				<p>- Click "Stop Recording" or end your Zoom call to initiate the audio upload.</p>
				<p>- Zoom may need time to process the audio.</p>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="red" @click="toggleStopCaptureZoomApp">
					<v-icon left>mdi-stop</v-icon>
					Stop Recording
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
	<v-dialog persistent :z-index="10001" v-model="showHeadphonesDialog" :width="1000">

		<v-card title="Conducting a remote session using headphones">
			<v-card-text>

				<v-list>
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title>Share the correct browser tab</v-list-item-title>
							<v-list-item-subtitle>
								When prompted to share your screen, specifically select the browser tab that
								contains the patient's session.
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title>Verify shared content</v-list-item-title>
							<v-list-item-subtitle>
								Double-check that you've shared the correct tab before beginning the session.
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>

			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>

				<v-btn text="Start recording" @click="showHeadphonesDialog = false, headphonesDialog = true"></v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

	<v-dialog persistent :z-index="10001" v-model="showErrorAudioUpload" :width="400">
		<v-card title="Problem uploading audio">
			<v-spacer></v-spacer>

			<v-card-text>Audio has been saved locally on your device.</v-card-text>
			<v-card-text>Please, upload audio manually.</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>

				<v-btn elevation="0" text="Continue" @click="showErrorAudioUpload = false"></v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
	<v-snackbar color="gray" elevation="24" v-model="snackbar" multi-line :timeout="-1" z-index="10000">
		{{ snackbarTextForSafari }}

		<template v-slot:actions>
			<v-btn class="white--text text--lighten-1 mdhub-btn" elevation="1"
				@click="snackbar = false, infoDialog = true">
				Continue with Safari
			</v-btn>
		</template>
	</v-snackbar>

	<div v-if="drawer" class="custom-overlay" @click="closeDrawer"></div>

</template>


<script>

import { marked } from 'marked';
import { ref, computed, watch, onMounted } from 'vue'
import useDocument from '../composables/useDocument'
import useRecordingState from '@/composables/useRecordingState'
import { timestamp } from '@/firebase/config'
import { Timestamp } from "@firebase/firestore";
import { useRouter } from 'vue-router'
import getUser from '@/composables/getUser'
import posthog from 'posthog-js'
import { trackEvent, ZERO_BLOB_AUDIO_RECEIVED_MIC, LIVE_TRANSCRIPT_OPENED, LIVE_TRANSCRIPT_CLOSED, RELOAD_ATTEMPT_WHILE_RECORDING, AUDIO_UPLOADED, ZOOM_RECORDING_STARTED, ZOOM_RECORDING_ENDED, ZOOM_RECORDING_UPDATE_MEETING, RECORDING_CONTINUED, RECORDING_PAUSED, RECORDING_STOPPED, RECORDING_STARTED, RECORDING_SESSION_CREATED, AUDIO_UPLOAD_STARTED, AUDIO_SAVED_LOCALLY, DELETE_SESSION_EVADED, START_MIC, STOPPING_MIC, STOPPED_MIC, ERROR_MIC, CAPTURING_MIC, ERROR_CAPTURING_MIC, AUDIO_SOURCE_CHANGED } from '@/utilities/analyticsService';
import fixWebmDuration from "fix-webm-duration";
import RecordRTC from '@/components/RecordRTC.js';
import { getIsZoomApp, getZoomMeetingID, getZoomMeetingUUID } from '@/composables/configureZoom'
import { getFunctions, httpsCallable } from "firebase/functions";
import getNewVersion from '@/composables/getNewVersion';
import getDocument from '@/composables/getDocument'
import { AVMedia } from 'vue-audio-visual'
import { useUserMedia } from '@vueuse/core'
import { askNotificationPermission, sendNotification } from '@/composables/useNotifications';
import { getUserCollection } from '@/composables/userService';
import useLiveTranscript from '@/composables/useLiveTranscript';
import uploadAudio from '@/composables/useStorage';
import PatientQuestionnaires from '@/components/questionnaires/PatientQuestionnaires.vue'
import SavingSpinner from '@/components/SavingSpinner.vue'

export default {
	props: ['patientId', 'style'],
	components: {
		AVMedia,
		PatientQuestionnaires,
		SavingSpinner
	},
	data() {
		return {
			panel: [0],
			followUpPanel: [0],
			drawer: false,
			isPaused: false,
			headphonesDialog: false,
			infoDialog: false,
			displayURL: '',
			micURL: '',
			permissionsChecked: false,
			startTime: null,
			withoutHeadphonesRecording: false,
			showCanvas: false,
			showWaves: true,
			recordingTime: 0,
			recordingInterval: null,
			hasNotValidToken: false,
			snackbar: false,
			showRecordingDialog: false,
			snackbarTextForSafari: "For better recording experience please use Chrome or Mozilla",
			audioInputDevices: [],
			selectedAudioInputDevice: null,
			deviceCheckInterval: null,
			deviceChanged: false,
			concatAudioblob: [],
			activeMicrophone: false,
			shouldResumeAfterDeviceChange: false,
			showHeadphonesDialog: false,
			conversationText: `
					<strong>CLINICIAN:</strong> Good morning. How have you been feeling since our last session?<br>
					<strong>PT:</strong> Good morning, Dr. I've been feeling a mix of emotions, to be honest. Some days are better than others.<br>
					<strong>CLINICIAN:</strong> That’s understandable. Can you tell me more about what’s been happening on the better days versus the more challenging ones?<br>
					<strong>PT:</strong> On the better days, I feel more energetic and motivated. I can focus on my work and even enjoy some hobbies. But on the challenging days, I feel overwhelmed and anxious, like I can't handle anything.<br>
					<strong>CLINICIAN:</strong> It sounds like you’re experiencing significant fluctuations in your mood and energy levels. Have you noticed any specific triggers or patterns that might be contributing to these changes?<br>
					<strong>PT:</strong> Well, I think stress at work is a big factor. Whenever there's a deadline or a big project, my anxiety spikes. Also, I’ve been having trouble sleeping lately, which I think makes everything worse.<br>
					<strong>CLINICIAN:</strong> Sleep is indeed very important. Lack of sleep can intensify feelings of anxiety and stress. Have you tried any strategies to improve your sleep quality?<br>
					<strong>PT:</strong> I've tried a few things, like avoiding caffeine in the evening and setting a regular bedtime, but nothing seems to work consistently. I still find myself tossing and turning for hours.<br>
					<strong>CLINICIAN:</strong> It might be helpful to explore some relaxation techniques before bed, such as deep breathing exercises or meditation. Also, keeping a sleep diary can help us identify any patterns or habits that might be affecting your sleep.<br>
					<strong>PT:</strong> That sounds like a good idea. I’m willing to give it a try. I just want to feel better and have more control over my life.<br>
					<strong>CLINICIAN:</strong> It's great that you’re open to trying new strategies. Remember, it’s a process, and it’s important to be patient with yourself. Let’s work together to find what works best for you. How about we set some small, achievable goals for the next week?<br>
					<strong>PT:</strong> That sounds good. I’ll start with the sleep diary and try some relaxation techniques before bed.<br>
					<strong>CLINICIAN:</strong> Excellent. We’ll review your progress in our next session and make any necessary adjustments. Take care, and don’t hesitate to reach out if you need support before then.<br>
					<strong>PT:</strong> Thank you, Dr. I appreciate your help.<br>
					<br>
					<br>
					<strong>Done? Press Finish Recording</strong>
      			`,
			finishButtonLoading: false,
		};
	},
	computed: {
		dialogWidth() {
			return this.showCanvas ? 'auto' : '600px'; // Adjust '600px' to your preferred default width
		},

		isSafari() {
			return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		},
		isChrome() {
			return /chrome/i.test(navigator.userAgent) && !/edge/i.test(navigator.userAgent);
		},
		isEdge() {
			return /edg/i.test(navigator.userAgent);
		},
		isFirefox() {
			return /firefox/i.test(navigator.userAgent);
		},
		formattedTime() {
			const minutes = Math.floor(this.recordingTime / 60);
			const seconds = this.recordingTime % 60;
			return `${minutes}:${seconds.toString().padStart(2, '0')}`; // Formats the time as "M:SS"
		},
	},
	created() {
		window.addEventListener('beforeunload', this.handleBeforeUnload);
		if (this.deviceCheckInterval) {
			clearInterval(this.deviceCheckInterval);
		}
	},
	beforeDestroy() {
		window.removeEventListener('beforeunload', this.handleBeforeUnload);
	},
	methods: {
		informUserForHeadphones() {
			this.showHeadphonesDialog = true;
			askNotificationPermission();
		},
		handleBeforeUnload(event) {
			// for both uploading and recording
			if (this.isRecording || this.isPending) {
				event.preventDefault();
				trackEvent(RELOAD_ATTEMPT_WHILE_RECORDING, { userId: this.userId, userEmail: this.user.email, patientId: this.patientId, sessionId: this.sessionId, source: "Web" })
			}
		},
		togglePauseRecording() {
			if (this.isPaused) {
				if (this.combinedRecorder) {
					this.combinedRecorder.resume();
				} else if (this.recorder) {
					this.recorder.resumeRecording();
					this.recordingInterval = setInterval(() => {
						if (!this.isPaused) {
							this.recordingTime++;
						}
					}, 1000);

				}

				this.isPaused = false;

				trackEvent(RECORDING_CONTINUED, { userId: this.userId, patientId: this.patientId, source: "Web" })
			} else {
				if (this.combinedRecorder) {
					this.combinedRecorder.pause();
				} else if (this.recorder) {
					this.recorder.pauseRecording();
				}
				console.log('paused')
				clearInterval(this.recordingInterval);
				this.isPaused = true;
				trackEvent(RECORDING_PAUSED, { userId: this.userId, patientId: this.patientId, source: "Web" })
			}
		},
		triggerFileInput() {
			this.$refs.fileInput.click();
		},
		async startWithoutHeadphonesRecording() {
			this.shouldResumeAfterDeviceChange = false;
			trackEvent(RECORDING_STARTED, { userId: this.userId, patientId: this.patientId, way: 'mic', source: "Web" })

			// Here, you can call methods from the second component
			// For example:			
			this.showCanvas = true;
			this.withoutHeadphonesRecording = true
			this.isPaused = false
			// this.infoDialog = false;

			this.startRecordingMic();
		},

		async stopWithoutHeadphonesRecording() {
			this.finishButtonLoading = true;

			if (this.isPaused) {
				if (this.recorder) {
					this.recorder.resumeRecording();
				} else if (this.combinedRecorder) {
					this.combinedRecorder.resume();
				}
				this.isPaused = false;
				this.finishButtonLoading = false;


			}

			//cause things might break and we don't want this loading forever, just to disable multi-clicking it
			setTimeout(() => {
				this.finishButtonLoading = false;
			}, 10000);

			trackEvent(RECORDING_STOPPED, { userId: this.userId, patientId: this.patientId, way: 'mic', source: "Web" })
			this.stopRecordingMic()
		},

		async startRecordingMic() {
			console.log("Starting microphone recording");

			if (this.selectedAudioInputDevice && this.selectedAudioInputDevice.label) {
				trackEvent(START_MIC, { userId: this.userId, patientId: this.patientId, way: 'mic', source: "Web", audioSource: this.selectedAudioInputDevice.label });
			} else {
				trackEvent(START_MIC, { userId: this.userId, patientId: this.patientId, way: 'mic', source: "Web", audioSource: "No audio input device selected" });
			}

			// Creating new session before starting the recording
			try {

				if (!this.audioContext) {
					this.audioContext = new AudioContext();
				}
				this.start();
				this.accessButtonId();
				this.headphonesDialog = false;

				// Capture the microphone and then start the recording and visualization process
				this.captureMicrophone(async (mic) => {
					this.microphone = mic;
					this.activeMicrophone = mic.active;
					if (!mic.active) {
						this.audioContext = new AudioContext();
						this.microphone = await navigator.mediaDevices.getUserMedia({ audio: true });

					} else {
						this.startTime = Date.now();
						// Adjust the recording interval to check for pause state
						if (this.recordingInterval) {
							clearInterval(this.recordingInterval); // Clear existing interval if any
						}
						// Reset and start the recording time counter
						this.recordingTime = 0;

					}

					this.micSource = this.audioContext.createMediaStreamSource(this.microphone);


					// Setup and start the recorder
					var options = {
						type: 'audio',
						numberOfAudioChannels: this.isEdge ? 1 : 2,
						checkForInactiveTracks: true,
						bufferSize: 16384,
						audioBitsPerSecond: 20000,
						timeSlice: 1000,
						ondataavailable: (blob) => {
							if (blob.size === 0) {
								trackEvent(ZERO_BLOB_AUDIO_RECEIVED_MIC, { userId: this.userId, userEmail: this.userEmail, patientId: this.patientId })
							}
							this.handleLiveStream(blob);
						}
					};

					if (this.isSafari) {
						options = {
							type: 'audio',
							numberOfAudioChannels: 1,
							checkForInactiveTracks: true,
							bufferSize: 16384,
							audioBitsPerSecond: 20000,
							mimeType: 'audio/webm; codecs=opus',
							recorderType: RecordRTC.StereoAudioRecorder,
							sampleRate: 44100,
						};
					}
					if (this.recorder) {
						console.log('destroying it ')
						this.recorder.destroy();
						this.recorder = null;
					}

					this.recorder = RecordRTC(this.microphone, options);
					this.recorder.startRecording();
					this.stream = this.microphone;
					this.recordingInterval = setInterval(() => {
						if (!this.isPaused) {
							this.recordingTime++;
						}
					}, 1000);

				});

			} catch (error) {
				console.error("Error starting microphone recording:", error);
				trackEvent(ERROR_MIC, { userId: this.userId, patientId: this.patientId, error: error })
			} finally {
				// Fetching list of templates available to user
				await this.createNewSession();

				// check if live transcript is enabled - we can do that internally too

				await this.initializeLiveTranscription({ userId: this.userId, sessionId: this.sessionId, patientId: this.patientId });

			}
		},

		captureMicrophone(callback) {

			console.log("Capturing microphone");
			if (this.selectedAudioInputDevice && this.selectedAudioInputDevice.label) {
				trackEvent(CAPTURING_MIC, { userId: this.userId, patientId: this.patientId, audioSource: this.selectedAudioInputDevice.label })
			} else {
				trackEvent(CAPTURING_MIC, { userId: this.userId, patientId: this.patientId, audioSource: "No audio input device selected" })
			}

			if (this.microphone) {
				callback(this.microphone);
				return;
			}

			if (typeof navigator.mediaDevices === 'undefined' || !navigator.mediaDevices.getUserMedia) {
				alert('This browser does not support WebRTC getUserMedia API.');
				if (!!navigator.getUserMedia) {
					alert('This browser seems to support deprecated getUserMedia API.');
				}
			}
			navigator.mediaDevices.getUserMedia({
				audio: this.isEdge ? true : { echoCancellation: false }
			}).then((mic) => {
				callback(mic);
			}).catch((error) => {
				alert('Unable to capture your microphone. Please give permission to the application to use the microphone.');
				console.error(error);
				this.stop();
				this.infoDialog = false;

				this.showCanvas = false;
				this.withoutHeadphonesRecording = false
				trackEvent(ERROR_CAPTURING_MIC, { userId: this.userId, patientId: this.patientId, error: error })

			});
		},
		stopRecordingMic() {
			this.stopAllStreams();
			if (this.recorder) {
				this.recorder.stopRecording(this.stopRecordingCallback);
			}
			if (this.recordingInterval) {
				clearInterval(this.recordingInterval);
				this.recordingInterval = null; // Reset the interval variable
			}
			// Stop the recording time counter
			clearInterval(this.recordingInterval);
		},
		async stopRecordingCallback() {
			trackEvent(STOPPING_MIC, { userId: this.userId, patientId: this.patientId })
			this.concatAudioblob.push(this.recorder.getBlob())
			if (!this.deviceChanged) {
				const newBlob = new Blob(this.concatAudioblob)
				var duration = Date.now() - this.startTime;
				var fixedBlob = await fixWebmDuration(newBlob, duration, { logger: false })
				var url = URL.createObjectURL(fixedBlob)
				await this.submitAudio(fixedBlob)
				console.log("Microphone recording stopped");
				trackEvent(STOPPED_MIC, { userId: this.userId, patientId: this.patientId })
				if (this.isSafari) {
					this.releaseMicrophone();
				}

				this.infoDialog = false;
				this.showCanvas = false;
				this.withoutHeadphonesRecording = false;
				this.drawer = false;
				this.finishLiveTranscript();
			}
			this.deviceChanged = false
			this.isPaused = true

			// Removing the animation from the patient card

			const element = document.getElementById(this.patientId);
			if (element) {
				element.getAnimations().forEach(animation => animation.cancel());
				element.style.backgroundColor = 'white';
			}
			this.stop();

		},
		releaseMicrophone() {
			this.btnReleaseMicrophoneDisabled = true;
			this.btnStartRecordingDisabled = false;

			if (this.microphone) {
				this.microphone.stop();
				this.microphone = null;
			}
		},
		async checkPermissions() {
			try {
				let micCheck = await navigator.mediaDevices.getUserMedia({ audio: true });
				// Stop the stream immediately after acquiring it
				micCheck.getTracks().forEach(track => track.stop());

				this.permissionsChecked = true;
			} catch (err) {
				console.error("Microphone permission denied:", err);
				this.permissionsChecked = false;
			}
		},
		async toggleCapture() {
			// close the info dialog 
			this.infoDialog = false;
			this.showCanvas = true;
			this.showWaves = false;

			if (!this.permissionsChecked) {
				await this.checkPermissions();
				if (!this.permissionsChecked) {
					alert("You must grant microphone permissions to proceed.");
					this.showCanvas = false;
					this.headphonesDialog = false;
					this.stop()
					this.stopRecording()
					return;  // If permissions are not granted, stop here
				}
			}

			if (this.isRecording) {
				await this.stopRecording();
			} else {
				await this.startRecording();
			}
		},
		stopAllStreams() {
			if (this.micStream) {
				this.micStream.getTracks().forEach(track => track.stop());
			}
			if (this.displayStream) {
				this.displayStream.getTracks().forEach(track => track.stop());
			}
			if (this.microphone) {
				this.microphone.stop();
			}
			if (this.recorder && this.recorder.stream) {
				this.recorder.stream.getTracks().forEach(track => track.stop());
			}
		},
		// This method is called when user click on Start recording with headphones
		async startRecording() {

			this.isPaused = false;
			trackEvent(RECORDING_STARTED, { userId: this.userId, patientId: this.patientId, way: 'headphones', source: "Web" })
			try {
				// First, ask for microphone permissions
				this.micStream = await navigator.mediaDevices.getUserMedia({
					audio: true
				});

				const audioContext = new AudioContext();

				// Create a destination for the combined audio
				const destination = audioContext.createMediaStreamDestination();

				// Create source nodes
				const micSource = audioContext.createMediaStreamSource(this.micStream);

				// Connect sources to the destination
				micSource.connect(destination);

				if (this.headphonesDialog) {
					const isWindows = navigator.userAgent.indexOf('Win') > -1;
					let displayStreamOptions =
					{
						video: { mediaSource: 'tab' },
						audio: true,
						monitorTypeSurfaces: 'exclude',

					}
					if (isWindows) {
						//screen sharing audio is supported in windows
						displayStreamOptions = {
							video: true,
							audio: true,
						}

					}

					this.displayStream = await navigator.mediaDevices.getDisplayMedia(displayStreamOptions);

					this.displayStream.oninactive = () => {
						this.stopRecording();
					};
					try {
						const displaySource = audioContext.createMediaStreamSource(this.displayStream);
						displaySource.connect(destination);
					} catch (error) {
						alert("You must share the tab of the hosted session in order to proceed.");
						this.showCanvas = false;
						this.stop()
						this.stopRecording()
						this.headphonesDialog = false;
						return;
					}


					this.headphonesDialog = false;
				}

				await this.createNewSession();
				// Use the combined audio stream for recording
				const combinedStream = destination.stream;

				// Set the desired audio bit rate (adjust as needed)
				const audioBitsPerSecond = 20000; // 128 kbps (adjust as needed)
				await this.initializeLiveTranscription({ userId: this.userId, sessionId: this.sessionId, patientId: this.patientId });

				this.combinedRecorder = new MediaRecorder(combinedStream, {
					audioBitsPerSecond: audioBitsPerSecond,
					mimeType: "audio/webm",
				});
				const combinedChunks = [];
				//device change only for headphones recording.
				this.combinedRecorder.ondataavailable = event => {
					if (event.data.size > 0) {
						combinedChunks.push(event.data);
						this.handleLiveStream(event.data)
					}
				};

				this.combinedRecorder.onstop = async () => {
					var duration = Date.now() - this.startTime;
					let blob = new Blob(combinedChunks, {
						'type': 'audio/webm'
					});
					this.finishLiveTranscript();
					var fixedBlob = await fixWebmDuration(blob, duration, { logger: false })
					this.combinedURL = window.URL.createObjectURL(fixedBlob);
					await this.submitAudio(fixedBlob)
					this.infoDialog = false;
					this.showCanvas = false;
					this.isPaused = false;
					this.drawer = false;
				};

				this.combinedRecorder.start(1000);

				this.start();
				this.startTime = Date.now();

			} catch (err) {
				// close dialog in case an error pops up -> an error message should be considered here instead of logging it only
				this.headphonesDialog = false;
				if (err.name === "NotAllowedError") {
					console.error("Permissions not granted", this.isRecording);
					alert("The session is not recording. Share a tab to proceed.");
					this.showCanvas = false;
					this.stop()
					this.stopRecording()
				} else {
					console.error("Error capturing audio:", err);
				}
			}
		},
		async stopRecording() {
			trackEvent(RECORDING_STOPPED, { userId: this.userId, patientId: this.patientId, way: 'headphones', source: "Web" })
			this.isPaused = false;
			if (this.withoutHeadphonesRecording) {
				this.stopRecordingMic()
			} else {
				if (this.combinedRecorder) {
					await this.combinedRecorder.stop();
				}
				try {
					if (this.combinedRecorder && this.combinedRecorder.stream) {
						this.combinedRecorder.stream.getTracks().forEach(track => {
							track.stop();
						});
					}

					// This will stop the display and microphone streams:
					if (this.displayStream) {
						this.displayStream.getTracks().forEach(track => {
							track.stop();
						});
					}

					if (this.micStream) {
						this.micStream.getTracks().forEach(track => {
							track.stop();
						});
					}

				} catch (error) {
					console.log("Not possible to clear references: ", error)
				}
				this.stop();
				this.combinedRecorder = null;
				this.recorder = null;
			}
		},
		async openDialog() {

			if (this.isRecording) {
				this.toggleCapture();
				return;
			}
			await getNewVersion();
			this.snackbar = this.isSafari && !this.snackbar;
			if (!this.snackbar) {
				this.infoDialog = true;
			}
		},
		// zoom functions
		async toggleCaptureZoomApp() {
			if (this.isRecording) {
				this.showRecordingDialog = false;
				this.stopRecordingWithZoom()
			} else {
				this.showRecordingDialog = true;
				this.overlay = true;
				this.hasNotValidToken = false;
				await this.startRecordingWithZoom(this.userId, this.patientId)
			}
		},
		async toggleStopCaptureZoomApp() {
			if (this.isRecording) {
				this.showRecordingDialog = false;
				this.stopRecordingWithZoom()
			}
		},

		async startRecordingWithZoom(userId, patientId) {

			const api = {
				buttonName: "cloudRecording(start)",
				name: "cloudRecording",
				options: { action: "start" },
			}
			const { name, buttonName = '', options = null } = api
			const zoomAppsSdkApi = zoomSdk[name].bind(zoomSdk)

			console.warn('recording...')
			const clientResponse = await zoomAppsSdkApi(options);
			console.log(`${buttonName || name} success with response: ${JSON.stringify(clientResponse)}`);
			trackEvent(ZOOM_RECORDING_STARTED, { userId: userId, patientId, source: "Zoom" })

			this.start();
			// Remove overlay when session is updated
			this.overlay = false;
			await this.updateZoomMeeting(userId, patientId);
		},
		stopRecordingWithZoom() {
			//on start recording? 
			//on end meeting?

			const api = {
				buttonName: "cloudRecording(stop)",
				name: "cloudRecording",
				options: { action: "stop" },
			}
			const { name, buttonName = '', options = null } = api
			const zoomAppsSdkApi = zoomSdk[name].bind(zoomSdk)

			zoomAppsSdkApi(options)
				.then(async clientResponse => {
					this.stop();
					trackEvent(ZOOM_RECORDING_ENDED, { userId: this.userId, patientId: this.patientId, source: "Zoom" })

					console.log(`${buttonName || name} success with response: ${JSON.stringify(clientResponse)}`);
				})
				.catch(clientError => {
					console.log(`${buttonName || name} error: ${JSON.stringify(clientError)}`);
				});
		},
		async updateZoomMeeting(userId, patientId) {
			console.warn('calling zoom update')
			const meetingId = await getZoomMeetingID();
			const meetingUUID = await getZoomMeetingUUID()

			console.warn('got the ids', meetingId, meetingUUID)

			const res = await this.createNewSession()

			const { updateDocument } = useDocument('sessions', res.id);
			await updateDocument({
				zoomMeetingId: meetingId,
				zoomMeetingUUID: meetingUUID
			});

			// Assigning session ID value of the newly created Zoom session
			this.sessionId = res.id

			trackEvent(ZOOM_RECORDING_UPDATE_MEETING, { userId: userId, patientId: patientId, sessionId: res.id, meetingId, source: "Zoom" })

			console.log('Zoom meeting updated in firestore');

		},
		async logAudioInputDevices() {
			const devices = await navigator.mediaDevices.enumerateDevices();
			this.audioInputDevices = devices.filter(device => device.kind === 'audioinput');
			if (this.isFirefox) {
				this.selectedAudioInputDevice = this.audioInputDevices[0];
			} else {
				this.selectedAudioInputDevice = this.audioInputDevices.find(device => device.deviceId === 'default');
			}
		},

	},
	watch: {
		headphonesDialog(val) {
			if (!val) return;
			//setTimeout(() => this.toggleCapture(), 5000);
			this.toggleCapture()
		},
		async drawer(val) {
			if (val) {
				await this.getTemplates();
				this.logAudioInputDevices();
				this.$emit('drawer-opened');
				this.deviceCheckInterval = setInterval(this.logAudioInputDevices, 5000);
			} else {
				this.$emit('drawer-closed');
				clearInterval(this.deviceCheckInterval);
			}
		},
		async selectedAudioInputDevice(newDevice, oldDevice) {
			if (newDevice && oldDevice) {
				if (newDevice.label !== oldDevice.label) {
					// we only care when this happens while recording
					this.deviceChanged = this.isRecording;
					if (this.deviceChanged) {
						trackEvent(AUDIO_SOURCE_CHANGED, { userId: this.userId, userEmail: this.user.email, patientId: this.patientId, sessionId: this.sessionId, source: newDevice.label, message: `Changed from', ${JSON.stringify(oldDevice)}, 'to', ${JSON.stringify(newDevice)}` })
						console.log('Changed from', JSON.stringify(oldDevice), 'to', JSON.stringify(newDevice));
						if (this.combinedRecorder) {
							// here send a notification
							sendNotification({ title: 'mdhub notification - Something went wrong', body: 'Audio device changed. Please finish the recording.' });
							this.combinedRecorder.pause();
						} else if (this.recorder) {
							this.shouldResumeAfterDeviceChange = true;
							sendNotification({ title: 'mdhub notification', body: 'Audio device changed - Recording paused. Please resume the recording.' });
							await this.stopWithoutHeadphonesRecording()
						}
					}
				}
			}
		},
	},
	setup(props) {
		const notes = ref('')
		const isPending = ref(false)
		const additionalNotes = ref("");
		const selectedTemplate = ref(undefined);
		const selectedQuestionnaire = ref(undefined);
		const router = useRouter();
		const { user } = getUser();
		const userId = user.value.uid;
		const patientId = props.patientId;
		const followup = ref("")
		const followupDate = ref("")
		const userTemplates = ref([])
		const templatePanel = ref(true)
		const { stream } = useUserMedia()
		const { isRecording, combinedRecorder, micRecorder, displayRecorder, combinedURL, micStream, displayStream, stop, start } = useRecordingState();
		const sessionType = ref('live')
		const firstTimeUser = ref(false)
		const showErrorAudioUpload = ref(false)
		const { isZoomApp } = getIsZoomApp();
		const overlay = ref(false)
		const sessionId = ref(null);
		const { errorPatient, document: patient } = getDocument('patients', props.patientId);

		const isMdhubZoom = isZoomApp.value;

		const liveTranscriptEnabled = ref([0]) //opened initially

		const handlePanelClick = () => {
			let liveTranscriptClosing = liveTranscriptEnabled.value[0] === 0;
			liveTranscriptEnabled.value[0] = liveTranscriptClosing ? 1 : 0;
			trackEvent(liveTranscriptClosing ? LIVE_TRANSCRIPT_CLOSED : LIVE_TRANSCRIPT_OPENED, { userId: userId, sessionId: sessionId.value, patientId: patientId, source: "Web" })
		}
		const { initializeLiveTranscription, liveTranscript, handleLiveStream, liveStreamReady, finishLiveTranscript } = useLiveTranscript();

		const loadUser = async () => {
			if (user.value) {
				try {
					const userObject = await getUserCollection(user.value.uid);
					if (userObject && userObject.counter !== undefined) {
						firstTimeUser.value = userObject.counter === 0;
					} else {
						firstTimeUser.value = true;
					}
				} catch (error) {
					console.error("Error loading user object: ", error);
					firstTimeUser.value = true; // Or handle it in another way
				}
			}
		};

		const accessButtonId = () => {
			const element = document.getElementById(patientId)
			if (element) {
				element.style.backgroundColor = '#ed7e70';
				element.animate([
					{ backgroundColor: '#ed7e70' }, // 0%
					{ backgroundColor: '#f6aba2' }, // 50%
					{ backgroundColor: '#ed7e70' }  // 100%
				], {
					// sync options
					duration: 1000,
					iterations: Infinity
				});


			}
		};

		const getFollowup = async () => {
			const functions = getFunctions();
			const getFollowupFunction = httpsCallable(functions, 'getFollowupSuggestions');
			var patientId = props.patientId;
			try {
				const res = await getFollowupFunction({ patientId });
				if (!res.data) return followup.value = ''
				followup.value = res.data.followUp;
				const followupTimestamp = res.data.date;
				if (followupTimestamp) {
					const { _seconds: seconds, _nanoseconds: nanoseconds } = followupTimestamp;
					const miliseconds = seconds * 1000 + nanoseconds / 1000;
					followupDate.value = Timestamp.fromMillis(miliseconds).toDate().toLocaleDateString();
				}
			} catch (error) {
				console.log(`Error getting follow up information ${error}`)
			}
		}

		const renderedMarkdown = computed(() => {
			if (followup.value) {
				return marked(followup.value);
			}
			else
				return ''
		});

		getFollowup()
		const getTemplates = async () => {
			const functions = getFunctions();
			const getTemplatesFunction = httpsCallable(functions, 'getTemplates');
			const result = await getTemplatesFunction({ userId: user.value.uid });
			userTemplates.value = result.data.templates;
			if (result.data.lastTemplateId) {
				selectedTemplate.value = userTemplates.value.find(template => template.id === result.data.lastTemplateId);
				templatePanel.value = false;
			}
		}

		const selectTemplate = async (template) => {
			templatePanel.value = false;
			selectedTemplate.value = template;
		}

		const setSelectedQuestionnaire = (questionnaireId) => {
			selectedQuestionnaire.value = questionnaireId;
			console.log("Selected questionnaire: ", questionnaireId);
		}

		const createNewSession = async () => {
			overlay.value = true;
			const functions = getFunctions();
			const createNewSessionFunction = httpsCallable(functions, 'createSession');
			const payload = { userId, patientId, transcript: notes.value, type: sessionType.value, additionalNotes: additionalNotes.value, source: 'web' };

			const result = await createNewSessionFunction(payload);
			trackEvent(RECORDING_SESSION_CREATED, { userId: userId, userEmail: user.value.email, patientId: patientId, sessionId: result.data.id, source: "Web" })
			sessionId.value = result.data.id;
			overlay.value = false;

			return result.data
		}

		const submitAudio = async (audioBlob) => {
			trackEvent(AUDIO_UPLOAD_STARTED, { userId: userId, userEmail: user.value.email, sessionId: sessionId.value ?? "", patientId: patientId, source: "Web" })
			const newSession = {
				picked: timestamp()
			}
			// Upload audio to storage

			overlay.value = true

			try {
				/// Updating session data before uploading the audio
				const functions = getFunctions();
				const updateSessionData = httpsCallable(functions, 'updateSessionData');
				const updateData = { additionalNotes: additionalNotes.value, templateId: selectedTemplate.value?.id, patientQuestionnaireId: selectedQuestionnaire.value };
				await updateSessionData({ userId: user.value.uid, sessionId: sessionId.value, updateData });

				/// Uploading audio to the storage
				await uploadAudio(props.patientId, sessionId.value, audioBlob, user.value.uid, user.value.email)
				posthog.identify(user.value.uid, { email: user.value.email, name: user.value.displayName });
				trackEvent(AUDIO_UPLOADED, { userId: user.value.uid, userEmail: user.value.email, source: "Web", type: sessionType.value })
				overlay.value = false

				notes.value = ''
				router.push({ name: "SessionDetails", params: { patientId: props.patientId, id: sessionId.value } });
			} catch (error) {
				console.error("Upload failed: ", error.message);
				notes.value = ''
				await deleteCurrentSession();
				showErrorAudioUpload.value = true;
				downloadAudioBlobLocally(audioBlob);
			}
		}

		function downloadAudioBlobLocally(audioBlob) {
			if (!audioBlob) {
				console.error("No audio blocb found to download.");
				sessionId.value = null;
				return;
			}

			const url = window.URL.createObjectURL(audioBlob);
			const a = document.createElement('a');
			a.style.display = 'none';
			a.href = url;
			a.download = `${sessionId.value}.webm`;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);

			/// Tracking saving audio locally
			trackEvent(AUDIO_SAVED_LOCALLY, { userId: user.value.uid, sessionId: sessionId.value, userEmail: user.value.email });
			sessionId.value = null;
		}

		async function deleteCurrentSession() {
			/// At least for a time being evading automatic session deletion on the audio upload failure
			trackEvent(DELETE_SESSION_EVADED, { userId: user.value.uid, userEmail: user.value.email, sessionId: sessionId.value, reason: "Problem auploading audio" });
			overlay.value = false
			return;

			const functions = getFunctions();
			const deleteSessionAndAudioBySessionId = httpsCallable(functions, 'deleteSessionAndAudioBySessionId');
			try {
				await deleteSessionAndAudioBySessionId(sessionId.value)
			} catch (error) {
				console.log(`Error deleting Session and Audio ${error}`)
			}
			overlay.value = false
		}

		function downloadAudioBlob(blob) {
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.style.display = 'none';
			a.href = url;
			a.download = `${sessionId.value}.webm`;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
		}


		const onFileDrop = async (event) => {
			isPending.value = true
			if (event.target.files.length === 0) {
				console.log('No file selected.');
				return;
			}
			const file = event.target.files[0];
			//const blobUrl = URL.createObjectURL(file);

			await createNewSession();
			await submitAudio(file);
			isPending.value = false
		}

		watch(user, (newValue) => {
			if (newValue) {
				loadUser();
			}
		}, { immediate: true });

		return { setSelectedQuestionnaire, handlePanelClick, finishLiveTranscript, liveStreamReady, handleLiveStream, initializeLiveTranscription, liveTranscript, liveTranscriptEnabled, stream, isPending, accessButtonId, user, userId, patientId, patient, isMdhubZoom, createNewSession, notes, submitAudio, isRecording, combinedRecorder, micRecorder, displayRecorder, combinedURL, micStream, displayStream, start, stop, onFileDrop, sessionType, additionalNotes, overlay, sessionId, followup, renderedMarkdown, selectedTemplate, getTemplates, userTemplates, followupDate, selectTemplate, templatePanel, firstTimeUser, showErrorAudioUpload }
	}
};
</script>

<style scoped>
.v-dialog .v-card .close-btn .v-icon {
	color: gray !important;
}

.underline {
	position: relative;
	display: inline-block;
}

.underline::after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: -0.15px;
	height: 2px;
	background-color: gray;
}

button {
	padding: 10px 15px;
	border: none;
	cursor: pointer;
	transition: background-color 0.3s;
}

button:hover,
button:focus-visible {
	background-color: #f9fbfd;
}

button[recording="true"] {
	background-color: #ed7e70;
	animation: recordingAnimation 1s infinite;
}

.recorder-title {
	font-size: 15px
}

.flex-container {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.align-left {
	text-align: left;
}

.canvas-container,
.text-area {
	margin-left: 0;
	margin-right: 0;
}

.canvas-container {
	height: max-content;
}

.text-area {
	width: 100%;

}

.center-vertically {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

#canvas_container {
	background: #fff;
	border: 1px solid #ebebec;
	border-radius: 4px;
	/* height: 60px; */
	/* Adjust if necessary */
	/* overflow: hidden; */
	width: 100%;
}

#canvas_container2 {
	background: #fff;
	height: 60px;
	/* Adjust if necessary */
	overflow: hidden;
}

.canvas-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.margin-sides {
	padding-left: 16px;
	padding-right: 16px;
}

.formatted-time {

	text-align: right;

}

.custom-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 1003;
}

.spinner-overlay {
	z-index: 10000 !important;
	z-index: 10000 !important;
}

@keyframes recordingAnimation {
	0% {
		background-color: #ed7e70;
	}

	50% {
		background-color: #f6aba2;
	}

	100% {
		background-color: #ed7e70;
	}
}

.canvas-container {
	display: flex;
	flex-direction: column;
}

.awc {
	height: 70px;
	width: 100%;
}

.full-width {
	width: 100%;
	/* height: 100%; */
}

.white--text {
	color: white;
	text-transform: none;
}

.stack-buttons {
	flex-direction: row;
	/* Default layout for larger screens */
}

@media (max-width: 600px) {
	.stack-buttons {
		flex-direction: column;
		/* Stack buttons vertically on smaller screens */
		width: 100%;
		/* Ensure full width */
		align-items: center;
		/* Center buttons if desired */
	}
}

@media (max-width: 600px) {
	.button-container {
		flex-direction: column !important;
		/* Stacks the buttons vertically */
		align-items: center !important;
		/* Centers the buttons */
	}

	.button-container>* {
		width: 100% !important;
		/* Optional: Makes the buttons full width */
		margin-bottom: 8px !important;
		/* Adds some space between the buttons */
	}

	.button-container>*:last-child {
		margin-bottom: 0 !important;
		/* Removes the margin from the last button */
	}
}

@keyframes pulse {
	0% {
		transform: scale(1);
		opacity: 0.75;
	}

	50% {
		transform: scale(1.2);
		opacity: 1;
	}

	100% {
		transform: scale(1);
		opacity: 0.75;
	}
}

.pulsing-icon {
	animation: pulse 1.5s infinite;
}

.recorder-button-container {
	position: fixed;
	right: 20px;
	bottom: 20px;
	display: flex;
	margin-right: 50px;
	z-index: 999;
}

.recorder-btn {
	background-color: var(--bittersweet) !important;
	border-radius: 30px !important;
	color: var(--white) !important;
	flex-grow: 0;
	height: 50px !important;
	width: 200px;
}

.recorder-new-btn {
	background-color: transparent !important;
	color: var(--bittersweet);
	text-transform: none !important;
	width: auto;
}

.disable-events {
	pointer-events: none
}

.sticky-actions {
	position: sticky;
	bottom: 0;
	background-color: white;
	z-index: 10;
	width: 100%;
	box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.followup-section {
	background-color: white;
	border-radius: 4px;
	padding: 4px;
	font-family: inherit;
	font-size: inherit;
	line-height: 1.5;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

@keyframes pulse {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.2;
	}

	100% {
		opacity: 1;
	}
}

.recording-indicator {
	display: inline-block;
	width: 20px;
	height: 20px;
	background-color: var(--bittersweet);
	border-radius: 50%;
	animation: pulse 1s infinite;
	margin-left: 8px;
}

.error-indicator {
	display: inline-block;
	width: 20px;
	height: 20px;
	background-color: grey;
	border-radius: 50%;
	margin-left: 8px;
}

.title-container {
	display: flex;
	flex-direction: column;
}

.subtitle {
	margin-top: 8px;
}

.live-transcript {

	overflow-y: auto;
}

.live-transcript-wrapper {
	box-shadow: none;

	/* overflow: scroll; */
}

.canvas-transcript-wrapper {
	border: 1px solid #ebebec;
	border-radius: 4px;
}

::v-deep .live-transcript-wrapper .v-expansion-panel-title--active>.v-expansion-panel-title__overlay {
	opacity: 0 !important;
}

::v-deep .v-expansion-panel-title--active,
::v-deep .v-expansion-panel-title__overlay {
	background-color: #f9fbfd;
}

.section-header {
	font-weight: 500;
	text-align: left;
}

.mdhub-btn {
	height: 50px !important;
}

.audio-input {
	padding: 10px 15px;
}
</style>