// userService.js
import { ref } from 'vue';
import { projectFirestore } from '../firebase/config'

const checkUserExists = async (userId) => {
  const collectionRef = projectFirestore.collection('user');

  try {
    const snapshot = await collectionRef.where('userId', '==', userId).get();
    return !snapshot.empty;
  } catch (error) {
    console.error('Error checking user existence:', error);
    throw new Error('Failed to check user existence.');
  }
};

const getUserCollection = async (userId) => {  
  const collectionRef = projectFirestore.collection('user');
  try {
    const snapshot = await collectionRef.where('userId', '==', userId).get();
    if (!snapshot.empty) {
      // Assuming there's only one user with the given userId
      const userDoc = snapshot.docs[0];
      return {
        id: userDoc.id, 
        ...userDoc.data()
      } 
    } else {
      return null; // Return null if no user found
    }
  } catch (error) {
    console.error('Error checking user existence:', error);
    throw new Error('Failed to check user existence.');
  }
};

export { checkUserExists, getUserCollection };
