<template>
    <v-tooltip location="bottom" text="Move up">
        <template v-slot:activator="{ props }">
            <v-btn elevation="0" @click="moveUp" v-bind="props">
                <v-icon>mdi-arrow-up</v-icon>
            </v-btn>
        </template>
    </v-tooltip>
    <v-tooltip location="bottom" text="Move down">
        <template v-slot:activator="{ props }">
            <v-btn elevation="0" @click="moveDown" v-bind="props">
                <v-icon>mdi-arrow-down</v-icon>
            </v-btn>
        </template>
    </v-tooltip>
    <v-spacer></v-spacer>

    <v-btn @click="remove" elevation="0">
        Remove
    </v-btn>
    <v-btn elevation="0" @click="handleSave" :disabled="!templateHeader" :loading="isPendingSave">
        Save
    </v-btn>
</template>

<script setup>
import { defineProps, getCurrentInstance } from 'vue';

const props = defineProps({
    templateHeader: String,
    isPendingSave: Boolean
})

const { emit } = getCurrentInstance();

const handleSave = () => {
    emit('save')
}

const remove = () => {
    emit('remove')
}

const moveUp = () => {
    emit('moveUp')
}

const moveDown = () => {
    emit('moveDown')
}
</script>