<template>
    <div ref="el" class="wizzard" :style="style">
        <v-card elevation="5" class="wizzard__card" v-if="wizzardOpen">
            <v-card-text class="d-flex flex-column">
                <h5 class="text-left"> {{ title }} ?</h5>
                <p class="text-body-2 text-left">{{ description }}
                    <br>{{ page == "templates" ? 'Request a quote and let us do the work for you.' : '' }}
                </p>
                <v-textarea v-model="text" variant="outlined" label="Describe your problem"></v-textarea>
                <div class="d-flex justify-end">
                    <v-btn elevation="0" text @click="closeWizzard">close</v-btn>
                    <v-btn class="ml-2" elevation="0" color="var(--bittersweet)" @click="sendSupport">
                        <span style="color:white">SEND</span>
                    </v-btn>

                </div>
            </v-card-text>
        </v-card>


        <v-tooltip v-else text="Click for help">
            <template v-slot:activator="{ props }">
                <v-btn @click.close="wizzardOpen = true" icon="mdi-help-circle" v-bind="props"></v-btn>
            </template>
        </v-tooltip>

        <v-snackbar v-model="snackbar" :timeout="2000" color="gray">
            We have received your request and will get back to you as soon as possible.
        </v-snackbar>
    </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { trackEvent, TEMPLATE_SUPPORT, QUESTIONNAIRE_SUPPORT } from '@/utilities/analyticsService';
import getUser from '@/composables/getUser';

const props = defineProps({
    title: String,
    description: String,
    page: String
})


const { user } = getUser();

const wizzardOpen = ref(true);
const text = ref('');
const loading = ref(false);
const snackbar = ref(false);
const page = ref(props.page || 'templates');
const title = ref(props.title || 'Need Help Creating a Template');
const description = ref(props.description || 'Let our team of clinical and AI experts create your template!');

onMounted(() => {
    const remember = localStorage.getItem(`templateWizzardClosed${page.value}`);

    if (remember === 'true') {
        wizzardOpen.value = false;
    }
})

watch(wizzardOpen, (val) => {
    if (!val) {
        text.value = '';
    }
})

const closeWizzard = () => {
    wizzardOpen.value = false;
    localStorage.setItem(`templateWizzardClosed${page.value}`, 'true');
}

const sendSupport = async () => {
    if (!text.value) return;
    loading.value = true;
    const supportNeeded = page.value == 'Questionnaire' ? QUESTIONNAIRE_SUPPORT : TEMPLATE_SUPPORT;
    trackEvent(supportNeeded, { userId: user.value.uid, userEmail: user.value.email, text: text.value })
        .then(() => {
            snackbar.value = true;
        })
        .catch((error) => {
            console.log(error)
        })
        .finally(() => {
            loading.value = false;
            wizzardOpen.value = false;
        })
}

</script>

<style scoped>
.wizzard {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9998;

    .wizzard__card {
        width: 20vw;

    }
}
</style>