export default {
  install(app, { dataLayerName = 'dataLayer', gtmId }) {
    const dataLayer = window[dataLayerName] = window[dataLayerName] || [];
    const gtm = {
      push(event) {
        dataLayer.push(event);
      }
    };
    app.config.globalProperties.$gtm = gtm;
    window.$gtm = gtm; // Attach to window for global access
  }
};
