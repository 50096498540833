<template>
    <v-container fluid class="search-feedback-container">
        <v-row no-gutters align="center" justify="center">
            <v-col cols="12">
                <div @click="expandSearch" ref="searchContainer">
                    <v-text-field ref="searchField" v-model="search" prepend-icon="mdi-magnify"
                        placeholder="Ask questions about the patient" variant="plain"
                        :class="['mb-0 pt-0 pb-0 mt-0', { 'search-active': showSuggestions }, 'custom-icon-spacing']"
                        :style="textFieldStyle" @click:append="onSearch" @keyup.enter="onSearch"
                        @click.stop="expandSearch">
                        <template v-slot:append>
                            <v-tooltip location="top">
                                <span class="tooltip-text">Instead of manually reading through past clinical notes,<br>
                                    ask your mdhub assistant anything about your patient's history</span>
                                <template v-slot:activator="{ props }">
                                    <span v-bind="props" v-on="on" class="mr-8 beta-text"
                                        style="cursor: pointer;">(Beta)</span>
                                </template>
                            </v-tooltip>
                        </template>
                    </v-text-field>

                    <transition name="fade">
                        <div v-if="showSuggestions" class="suggestions-container">
                            <v-chip class="mx-2 my-1" v-for="suggestion in suggestions" :key="suggestion"
                                @click="setSearch(suggestion)">
                                {{ suggestion }}
                            </v-chip>
                        </div>
                    </transition>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    props: {
        patientId: String
    },
    data() {
        return {
            search: '',
            showSuggestions: false,
            suggestions: ['Summarize the last session', 'Patient complaint', 'Next appointment', 'Symptoms'],
        };
    },
    computed: {
        textFieldStyle() {
            if (this.showSuggestions) {
                // When suggestions are shown, remove the bottom border-radius to merge visually with the suggestions container
                return {
                    'border-bottom-left-radius': '0px',
                    'border-bottom-right-radius': '0px',

                    // Ensure the top border-radius is always applied
                    'border-top-left-radius': '30px',
                    'border-top-right-radius': '30px',
                    'background-color': 'white',
                };
            } else {
                // When suggestions are not shown, apply the full border-radius for a rounded pill look
                return {
                    'border-radius': '30px',
                    'box-shadow': '0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                    'background-color': 'white',
                };
            }
        }
    },

    methods: {
        expandSearch() {
            this.showSuggestions = true;
        },
        setSearch(suggestion) {
            this.search = suggestion;
            //this.onSearch(); // Optionally trigger search immediately upon selecting a suggestion
            this.$nextTick(() => {
                this.$refs.searchField.focus();
            });
        },
        onSearch() {
            this.$router.push({
                name: 'PatientChat',
                params: { patientId: this.patientId },
                query: { searchText: this.search }

            });
            this.search = ''; // Reset search field
        },
        handleClickOutside(event) {
            const searchContainer = this.$refs.searchContainer; // Reference to the search component's container
            if (searchContainer && !searchContainer.contains(event.target)) {
                this.showSuggestions = false;
            }
        }
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
    },

};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
    transform: translateY(10px);
    /* Adjust as needed */
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
    transform: translateY(-10px);
}

.v-input {
    padding-left: 20px;
}

.no-bottom-radius {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.search-active {
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.2), 0 12px 24px 0 rgba(0, 0, 0, 0.24);
}

.suggestions-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 0;
    background-color: rgb(255, 255, 255) !important;
    box-shadow: 0 8px 16px -4px rgba(0, 0, 0, 0.2), 0 8px 16px -4px rgba(0, 0, 0, 0.24);
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.search-feedback-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    border-radius: 30px;
    max-width: 700px;
}

.beta-text {
    color: var(--bittersweet);
}

.tooltip-text {
    color: white;
}

.v-btn {
    min-height: 56px;
}

.rounded-pill {
    background-color: rgb(255, 255, 255) !important;
    height: 56px;
    padding-left: 30px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    margin-bottom: -1px;
}

.feedback-button-text {
    color: var(--rich-black);
    margin-bottom: 0px !important;
    font-weight: 600;
}

.transition-slide {
    position: relative;
}

.transition-slide-enter-active,
.transition-slide-leave-active {
    transition: opacity 0.5s, transform 0.5s;
}

.transition-slide-enter,
.transition-slide-leave-to

/* Starting state for enter/leave transitions */
    {
    opacity: 0;
    transform: translateX(100%);
}
</style>