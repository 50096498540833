<template>
    <v-row justify="center">

        <v-dialog v-model="dialog" fullscreen :scrim="false" transition="dialog-bottom-transition">
            <v-card ml-10 mr-10 style="background-color: #fafafa">
                <v-toolbar class="toolbar" color="white">
                    <v-btn icon @click="closeModal">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Templates</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>

                <v-sheet class="d-flex flex-wrap" style="background-color: #fafafa">
                    <v-sheet class="ma-2 pa-2 rounded-lg">
                        <ListTemplates :templates="templates" @template-clicked="templateClicked"
                            @template-removed="templateRemoved" />
                    </v-sheet>

                    <v-sheet class="flex-1-0 ma-2 pa-2 rounded-lg">
                        <TemplateEditor :inputValues="inputValues" :isCreate="isCreate" :templateHeader="templateHeader"
                            :templateId="templateId" @input-field-added="addInputField" :language="language"
                            @input-field-removed="removeInputField" />
                    </v-sheet>

                </v-sheet>


            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { ref, onUpdated } from 'vue'
import useCollection from '@/composables/useCollection'
import getUser from '@/composables/getUser'
import { timestamp } from '@/firebase/config'
import ListTemplates from './ListTemplates.vue'
import getCollection from '@/composables/getCollection'
import useDocument from '@/composables/useDocument'
import TemplateEditor from '@/components/TemplateEditor.vue'


export default {
    props: ['isModalOpen'],
    components: { ListTemplates, TemplateEditor },
    methods: {

        getToolTip() {
            var words = `Section <br> Select from our dropdown or write your own section title. <br><br>
            Style<br> Choose between bullet points or flow text.<br><br>
            Use your own words <br> Specify precisely how you want this section written and provide detailed instructions. You can include examples from your previous notes.`
            return words;
        },
        removeInputField(index) {
            this.inputValues.splice(index, 1);
        },
        templateClicked(template) {
            this.templateId = template.id
            if (template.templateHeader === "ADD NEW TEMPLATE") {
                this.templateHeader = ""
                this.language = "English"
                this.isCreate = true
                this.inputValues = [
                    { item: '', dlevel: '', style: 'Bullet points' },
                    { item: '', dlevel: '', style: 'Bullet points' },
                    { item: '', dlevel: '', style: 'Bullet points' },
                    { item: '', dlevel: '', style: 'Bullet points' }]

            } else {
                this.selectedTemplate = template
                this.templateHeader = template.templateHeader
                this.language = template.language
                const transformedArray = template.template.map(item => {
                    const [title, dlevel, style] = item.split('/');
                    return {
                        item: title,
                        dlevel: dlevel,
                        style: style,
                    };
                });

                this.inputValues = transformedArray
                this.isCreate = false
            }
        },
        templateRemoved(template) {
            const { deleteDocument } = useDocument('summary-templates', template.id)
            deleteDocument()
        },
        required(v) {
            return !!v || 'Field is required';
        },
        closeModal() {
            this.$emit('close-modal');
        },
        addInputField() {
            this.inputValues.push({ item: '', dlevel: '', style: 'Bullet points' });
            // Scroll to the last input field after adding a new one
            this.$nextTick(() => {
                const inputContainer = this.$refs.inputContainer;
                if (inputContainer) {
                    const inputFields = inputContainer.getElementsByTagName('input');
                    if (inputFields && inputFields.length > 0) {
                        inputFields[inputFields.length - 1].scrollIntoView({
                            behavior: 'smooth',
                            block: 'end',
                            inline: 'nearest',
                        });
                    }
                }
            });
        },
    },
    data() {
        return {
            dialog: this.isModalOpen,
            notifications: false,
            sound: true,
            widgets: false,
        };
    },
    watch: {
        isModalOpen(newValue) {
            this.dialog = newValue; // Update dialog based on prop change
        },
        dialog(newValue) {
            this.$emit('update:isModalOpen', newValue); // Emit the updated value back to the parent
        }
    },
    setup(props, context) {

        const templateId = ref('');
        const templateHeader = ref('');
        const language = ref('English');
        const dlevel = ref('');
        const style = ref('');
        const selectedTemplate = ref('')
        const { err, addDoc } = useCollection('summary-templates');
        const isPending = ref(false);
        const isPendingSave = ref(false);
        const inputValues = ref([
            { item: '', dlevel: '', style: 'Bullet points' },
            { item: '', dlevel: '', style: 'Bullet points' },
            { item: '', dlevel: '', style: 'Bullet points' },
            { item: '', dlevel: '', style: 'Bullet points' }
        ]);
        const { user } = getUser();
        const { documents: templates } = getCollection(
            'summary-templates',
            ['userId', '==', user.value.uid]
        )
        const isCreate = true

        const handleClick = async () => {
            isPending.value = true;

            let customTemplate = inputValues.value
                .filter(value => value.item !== '')
                .map(value => {

                    var sectionTitle = value.item
                    var sectionStyle = value.style
                    var sectionOwnWords = value.dlevel.replaceAll('/', ',')

                    return sectionTitle + "/" + sectionOwnWords + "/" + sectionStyle;
                });

            const res = await addDoc({
                template: customTemplate,
                templateHeader: templateHeader.value,
                userId: user.value.uid,
                userName: user.value.displayName,
                createdAt: timestamp(),
                language: language.value
            });
            isPending.value = false;
        };

        const handleSave = async () => {
            isPendingSave.value = true

            let customTemplate = inputValues.value
                .filter(value => value.item !== '')
                .map(value => {

                    var sectionTitle = value.item
                    var sectionStyle = value.style
                    var sectionOwnWords = value.dlevel.replaceAll('/', ',')

                    return sectionTitle + "/" + sectionOwnWords + "/" + sectionStyle;
                });

            const { updateDocument } = await useDocument('summary-templates', selectedTemplate.value.id)
            const res = await updateDocument({
                templateHeader: templateHeader.value,
                language: language.value,
                updatedAt: timestamp(),
                template: customTemplate
            })

            isPendingSave.value = false
        }

        const resetDialog = () => {
            templateHeader.value = '';
            language.value = 'English';
            inputValues.value = [
                { item: '', dlevel: '', style: 'Bullet points' },
                { item: '', dlevel: '', style: 'Bullet points' },
                { item: '', dlevel: '', style: 'Bullet points' },
                { item: '', dlevel: '', style: 'Bullet points' }
            ];
        };

        onUpdated(() => {
            if (props.isModalOpen) {
                resetDialog();
            }
        });

        return { inputValues, templateHeader, dlevel, style, handleClick, isPending, templates, isCreate, isPendingSave, handleSave, selectedTemplate, templateId, language };
    },
}
</script>
<style scoped>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
    transition: transform .2s ease-in-out;
}

.toolbar {
    background-color: #fcfafb;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 0.875rem;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.05);
    padding: 1px 10px;
    margin-bottom: 10px;
}

.invalid-input {
    border-color: red;
    /* Change the border color to indicate invalid input */
}

.align-center {
    display: flex;
    align-items: center;
}

.dlevel {
    width: 25%;
}

.style {
    width: 50%;
}

.fixed-width-combobox {
    width: 300px;
}
</style>