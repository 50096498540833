<template>
    <div>
        <form @submit.prevent="handleSubmit">
            <h4>Create patient </h4>

            <v-text-field class="ml-4 mr-4 mt-8" v-model="name" color="accent" label="Name" variant="underlined"
                density="compact" required></v-text-field>

            <v-select class="ml-4 mr-4" label="Gender" v-model="gender" variant="underlined" color="accent"
                :items="['Male', 'Female', 'Non-binary', 'Prefer not to say', 'Other']"></v-select>

            <v-text-field v-if="!showPromoField" class="ml-4 mr-4" label="Date of birth" type="date" prepend-icon=""
                required id="dob" color="accent" v-model="dob" variant="underlined"></v-text-field>


            <button v-if="!showPromoField" class="ml-4 mr-4" @click="showPromoField = true" v-bind="props"
                style="color: grey;">
                Do not know the dob? Add age
            </button>


            <v-text-field v-if="showPromoField" placeholder="Age" v-model="age" class="ml-4 mr-4 mt-4" color="accent"
                variant="underlined" density="compact" append-inner-icon="mdi-close"
                @click:append-inner="showPromoField = false" type="number" />


            <v-text-field class="ml-4 mr-4 mb-5 left-aligned-hint" label="Patient Notes" id="dob" color="accent"
                v-model="diagnosis" variant="underlined" hint="Optional" persistent-hint></v-text-field>

            <v-tooltip location="bottom"
                text="Patient information is used to create more accurate clinical note using patient's name, gender and age.">
                <template v-slot:activator="{ props }">
                    <p class="mb-10 font-weight-light" v-bind="props">Why to add some basic patient information?</p>
                </template>
            </v-tooltip>


            <div class="error">{{ fileError }}</div>

            <v-btn class="mdhub-btn" elevation="0" :loading="isPending" type="submit">Create New Patient</v-btn>
        </form>
    </div>
</template>


<script>
import { ref, computed } from 'vue'
import useCollection from '@/composables/useCollection'
import getUser from '@/composables/getUser'
import { timestamp } from '@/firebase/config'
import { useRouter } from 'vue-router'
import defaultImage from '../../assets/gender-neutral.png';
import { trackEvent, NEW_PATIENT_CREATED } from '@/utilities/analyticsService';
import { Timestamp } from "@firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

export default {

    data: () => ({
        showPromoField: true,
    }),
    setup() {

        const { error, addDoc } = useCollection('patients')
        const { user } = getUser()
        const router = useRouter()
        const gender = ref('')
        const dob = ref('')
        const age = ref('')
        const diagnosis = ref('')

        const name = ref('')
        const address = ref('')
        const description = ref('')
        const file = ref(null)
        const fileError = ref(null)
        const isPending = ref(false)
        const patientImageUrl = computed(() => {
            return file.value ? url.value : defaultImage;
        });

        const handleSubmit = async () => {
            isPending.value = true

            let dobTimestamp;
            if (dob.value) {
                let dobDate = new Date(dob.value);
                const dateNow = Timestamp.now().toDate();

                dobDate.setUTCHours(dateNow.getUTCHours());
                dobDate.setUTCMinutes(dateNow.getUTCMinutes());
                dobDate.setUTCSeconds(dateNow.getUTCSeconds());

                dobTimestamp = Timestamp.fromDate(dobDate);
            } else if (age.value) {
                const currentYear = new Date().getFullYear();
                const yearOfBirth = currentYear - age.value;
                const dob = new Date(yearOfBirth, 6, 2); // July 2 of yearOfBirth
                dobTimestamp = Timestamp.fromDate(dob);
            }


            const functions = getFunctions();
            const createPatient = httpsCallable(functions, 'createPatient');

            try {
                const res = await createPatient({
                    patientName: name.value,
                    patientDescription: description.value,
                    address: address.value,
                    diagnosis: diagnosis.value,
                    userId: user.value.uid,
                    userName: user.value.displayName,
                    patientUrl: patientImageUrl.value,
                    sessions: [],
                    gender: gender.value,
                    dob: dobTimestamp,
                    createdAt: timestamp()
                })
            } catch (err) {
                console.log(err)
                error.value = err.message
            }


            trackEvent(NEW_PATIENT_CREATED, { userId: user.value.uid, userEmail: user.value.email })

            isPending.value = false
            if (!error.value) {
                router.push({ name: 'PatientOverview', params: { id: res.id } })
            }

        }

        const types = ['image/png', 'image/jpeg']

        return { name, description, handleSubmit, fileError, isPending, gender, dob, age, address, diagnosis }
    }
}
</script>

<style scoped>
.input-field {
    margin-top: 5%;
    width: 100%;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom-color: #ebebeb;
}

.input-group {
    display: flex;
    align-items: center;

}

.patient-image {
    max-width: 100px;
    margin-top: 10px;
}


form {
    background: white;
}

input[type="file"] {
    border: 0;
    padding: 0;
}

.custom-v-file-input .v-input__prepend {
    display: none !important;
    /* Hide the icon */
}

.custom-v-file-input .v-input__control {
    padding-left: 0 !important;
    /* Remove the left padding to prevent misalignment */
}

.custom-v-file-input .v-file-input__text-field {
    padding-left: 0 !important;
    /* Remove left padding for the text field */
}

.left-aligned-hint {
    text-align: start !important;
}
</style>